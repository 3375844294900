import { Col, Row } from 'antd';
import { FREEMAN_DARK_BLUE, MODAL_BG_GRAY } from '@/styles/theme';

import styled from 'styled-components';

export const CTARow = styled(Row)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 20px 75px 20px 20px;
  align-items: flex-end;
  min-height: 170px;
`;

export const CTAButtonContainer = styled(Col)`
  display: flex;
  justify-content: flex-end;
`;

export const IntegrationsRow = styled(Row)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 20px 75px 20px 20px;
  min-height: 250px;
  gap: 20px;
  overflow-x: auto;
  overflow-y: hidden;
  flex-wrap: nowrap;
  margin: 0 0 30px 0;

  img {
    max-height: 150px;
    width: 100%;
  }
`;

export const IntegrationPartner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  gap: 10px;
  background-color: ${FREEMAN_DARK_BLUE};
  color: white;
  min-width: 30%;

  & h4 a {
    color: white;
  }
`;

export const IntegrationPartnerLogoContainer = styled.div`
  background-color: white;
`;

export const PartnersRow = styled(Row)`
  width: 100%;
  min-height: min(500px, 50vh);
  padding: 20px 0;
`;

export const PartnerRowContent = styled.div`
  border-radius: 10px;
  background: ${MODAL_BG_GRAY};
  margin: 0 50px 0 20px;
  width: 100%;
`;

export const DisclaimerRow = styled(Row)`
  width: 100%;
`;

export const DisclaimerColumn = styled(Col)`
  padding: 20px;
  width: 100%;
`;
