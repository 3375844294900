import { Layout, Menu } from 'antd';

import { FREEMAN_DARK_BLUE } from '@/styles/theme';
import { deviceSmallerThan } from '@/styles/mediaqueries';
import styled from 'styled-components';

const { Header, Content } = Layout;

export const HEADER_HEIGHT = 105;
export const HEADER_HEIGHT_MOBILE = 190;

export const PublicHeader = styled(Header)`
  overflow: auto;
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  padding: 15px 50px 0 0;
  z-index: 100;
  background-color: white;
  height: ${HEADER_HEIGHT}px;

  ${deviceSmallerThan.tabletS} {
    display: flex;
    height: ${HEADER_HEIGHT_MOBILE}px;
    flex-direction: column;
    gap: 30px;
    padding: 15px 15px 0 15px;
  }

  #freeman-public-section-logo {
    background-color: white;
    border-radius: 0;
  }
`;

export const HeaderLinkContainer = styled.div`
  min-width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const LoginButtonContainer = styled.div`
  display: flex;
  width: 175px;
  place-self: flex-end;

  ${deviceSmallerThan.tabletS} {
    place-self: center;
  }
`;

export const StyledMenu = styled(Menu)`
  width: 100%;
  justify-content: flex-end;
  color: ${FREEMAN_DARK_BLUE};
`;

export const PublicContentContainer = styled(Content)`
  margin: ${HEADER_HEIGHT}px 0 0;

  ${deviceSmallerThan.tabletS} {
    margin: ${HEADER_HEIGHT_MOBILE}px 0 0;
  }
`;
