import {
  PUBLIC_PAGE_ELEMENT_DARK_BLUE,
  PUBLIC_PAGE_ELEMENT_ORANGE,
  Theme,
  WHITE,
} from '@/styles/theme';
import styled, { css } from 'styled-components';

import { BaseButtonStyles } from '@/components/Button/Button.style';
import { Link } from 'react-router-dom';

type StyledLinkProps = {
  type: 'primary' | 'secondary' | 'danger' | 'public-pages-light' | 'public-pages-dark';
  theme: Theme;
};

export const StyledLink = styled(Link)<StyledLinkProps>`
  ${BaseButtonStyles}
  border-radius: 8px;

  ${({ type, theme }: StyledLinkProps) =>
    (type === 'primary' || type === 'secondary' || type === 'danger') &&
    css`
      background-color: ${theme.colors[type]}!important;
      background: ${theme.colors[type]}!important;
    `}

  ${({ type, theme }: StyledLinkProps) =>
    (type === 'primary' || type === 'danger') &&
    css`
      &:hover {
        color: ${theme.colors[type]};
        background-color: ${WHITE}!important;
        background: ${WHITE} !important;
        outline: 1px solid ${theme.colors[type]};
      }
    `}

    ${(props: StyledLinkProps) =>
    props.type === 'public-pages-light' &&
    css`
      background-color: white !important;
      background: white !important;
      font-weight: 400;
      text-transform: initial;

      color: ${PUBLIC_PAGE_ELEMENT_DARK_BLUE};

      &:hover {
        color: ${PUBLIC_PAGE_ELEMENT_ORANGE};
        background-color: ${WHITE}!important;
        background: ${WHITE} !important;
        outline: 1px solid ${PUBLIC_PAGE_ELEMENT_ORANGE};
      }
    `}

    ${(props: StyledLinkProps) =>
    props.type === 'public-pages-dark' &&
    css`
      background-color: ${PUBLIC_PAGE_ELEMENT_DARK_BLUE} !important;
      background: ${PUBLIC_PAGE_ELEMENT_DARK_BLUE} !important;
      font-weight: 400;
      color: white;
      text-transform: initial;

      &:hover {
        color: ${PUBLIC_PAGE_ELEMENT_DARK_BLUE};
        background-color: ${WHITE}!important;
        background: ${WHITE} !important;
        outline: 1px solid ${PUBLIC_PAGE_ELEMENT_DARK_BLUE};
      }
    `}
`;

export const StyledExternalLink = styled.a<StyledLinkProps>`
  ${BaseButtonStyles}
  border-radius: 8px;

  ${({ type, theme }: StyledLinkProps) =>
    (type === 'primary' || type === 'secondary' || type === 'danger') &&
    css`
      background-color: ${theme.colors[type]}!important;
      background: ${theme.colors[type]}!important;
    `}

  ${({ type, theme }: StyledLinkProps) =>
    (type === 'primary' || type === 'danger') &&
    css`
      &:hover {
        color: ${theme.colors[type]};
        background-color: ${WHITE}!important;
        background: ${WHITE} !important;
        outline: 1px solid ${theme.colors[type]};
      }
    `}

    ${(props: StyledLinkProps) =>
    props.type === 'public-pages-light' &&
    css`
      background-color: white !important;
      background: white !important;
      font-weight: 400;
      text-transform: initial;

      color: ${PUBLIC_PAGE_ELEMENT_DARK_BLUE};

      &:hover {
        color: ${PUBLIC_PAGE_ELEMENT_ORANGE};
        background-color: ${WHITE}!important;
        background: ${WHITE} !important;
        outline: 1px solid ${PUBLIC_PAGE_ELEMENT_ORANGE};
      }
    `}

    ${(props: StyledLinkProps) =>
    props.type === 'public-pages-dark' &&
    css`
      background-color: ${PUBLIC_PAGE_ELEMENT_DARK_BLUE} !important;
      background: ${PUBLIC_PAGE_ELEMENT_DARK_BLUE} !important;
      font-weight: 400;
      color: white;
      text-transform: initial;

      &:hover {
        color: ${PUBLIC_PAGE_ELEMENT_DARK_BLUE};
        background-color: ${WHITE}!important;
        background: ${WHITE} !important;
        outline: 1px solid ${PUBLIC_PAGE_ELEMENT_DARK_BLUE};
      }
    `}
`;
