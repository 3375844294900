import { Contenttopic, ContenttopicsWithMeta } from '@/api/contenttopics/types';
import { FilterModelType, filterParamsToQueryParams } from '@/api/helpers';
import { useInfiniteQuery, useQuery } from 'react-query';

import { ApiDataResponseAnatomy } from '@/api/api.types';
import { ExtractFnReturnType } from '@/lib/react-query';
import { axios } from '@/lib/axios';

export type FilterContenttopicsQueryParams = Partial<
  {
    [property in keyof Contenttopic]: string | string[];
  } & { page: number; filterModel: FilterModelType }
>;

export const allContenttopics = async (searchParams: FilterContenttopicsQueryParams = {}) => {
  const params = filterParamsToQueryParams(searchParams);
  const response = await axios.get<ContenttopicsWithMeta, ApiDataResponseAnatomy<Contenttopic>>(
    `/contenttopics/v2`,
    {
      params,
    }
  );
  const { collection, _meta } = response;
  return { collection, meta: _meta };
};

export type QueryGetContenttopicsFnType = typeof allContenttopics;

export const contenttopicById = async (id: string) =>
  axios.get<Contenttopic, Contenttopic>(`/contenttopics/v2/${id}`);

type QueryGetContenttopicFnType = typeof contenttopicById;

export const useAllContenttopics = (config = {}) => {
  return useQuery<ExtractFnReturnType<QueryGetContenttopicsFnType>>({
    queryKey: ['contenttopics'],
    queryFn: () => allContenttopics(),
    ...config,
  });
};

export const useSearchInfiniteContenttopics = (
  filterParams: FilterContenttopicsQueryParams = {},
  config = {}
) => {
  return useInfiniteQuery<ExtractFnReturnType<QueryGetContenttopicsFnType>>({
    queryKey: ['contenttopics', filterParams],
    queryFn: ({ pageParam = 1 }) => allContenttopics({ ...filterParams, page: pageParam }),
    getPreviousPageParam: (firstPageData, allPagesData) => {
      const lastPageData = allPagesData.at(-1);
      if (!lastPageData?.meta) {
        // this should never happen unless the API returns an error
        return undefined;
      }
      const previousPage = lastPageData.meta.currentPageNumber - 1;

      if (previousPage <= 1) {
        // no previous page
        return undefined;
      }
      return previousPage;
    },
    getNextPageParam: (lastPageData) => {
      if (!lastPageData?.meta) {
        // this should never happen unless the API returns an error
        return undefined;
      }
      const nextPage = lastPageData.meta.currentPageNumber + 1;
      if (nextPage > lastPageData.meta.totalNumberOfPages) {
        // no more pages
        return undefined;
      }
      return nextPage;
    },
    ...config,
  });
};

export const useSearchContenttopics = (
  filterParams: FilterContenttopicsQueryParams,
  config = {}
) => {
  return useQuery<ExtractFnReturnType<QueryGetContenttopicsFnType>>({
    queryKey: ['contenttopics', filterParams],
    queryFn: () => allContenttopics(filterParams),
    ...config,
  });
};

export const useContenttopicById = (id: string, config = {}) => {
  return useQuery<ExtractFnReturnType<QueryGetContenttopicFnType>>({
    queryKey: ['contenttopics', id],
    queryFn: () => contenttopicById(id),
    staleTime: 5 * (60 * 1000), // 5 minutes
    ...config,
  });
};
