import { MutationConfig, queryClient } from '@/lib/react-query';

import { User } from '@/api/users/types';
import { addNotification } from '@/lib/notifications';
import { axios } from '@/lib/axios';
import { useMutation } from 'react-query';

export const deleteUser = async (id: string): Promise<User> => {
  return axios.delete<any, User>(`/users/v2/${id}`);
};

export type UseDeleteUserOptions = {
  config?: MutationConfig<typeof deleteUser>;
};

export const useDeleteUser = ({ config }: UseDeleteUserOptions = {}) => {
  return useMutation({
    onMutate: async (deletedUserId: string) => {
      await queryClient.cancelQueries(['users']);
      const previousUsers = queryClient.getQueryData<User[]>(['users']);
      queryClient.setQueryData(
        ['users'],
        previousUsers?.filter((user) => user.id !== deletedUserId)
      );

      return { previousUsers };
    },
    onError: (error, __, context: any) => {
      if (context?.previousUsers) {
        queryClient.setQueryData(['users'], context.previousUsers);
      }
      addNotification({
        type: 'error',
        message: error.message,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['users']);
      addNotification({
        type: 'success',
        message: `You have successfully deleted the user!`,
      });
    },
    ...config,
    mutationFn: deleteUser,
  });
};
