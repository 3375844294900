/*eslint import/namespace: ['error', { allowComputed: true }]*/
import * as AntdIcons from '@ant-design/icons';
import * as Icons from '@ant-design/icons/lib/icons';

import React, { ReactElement } from 'react';

import { Records } from 'airtable';
import { TDocItem } from '@/components/Layout/DocumentationLayout';
import isEmpty from 'lodash/isEmpty';

type IconNames = keyof typeof Icons;

type MenuItems = {
  [x: string]: {
    key: string;
    label: string;
    icon: ReactElement;
    children: { key: string; label: string }[];
  };
};

type TComposeMenuItemsProps = {
  docs: Records<TDocItem>;
};

export const composeMenuItems = ({ docs }: TComposeMenuItemsProps) => {
  const docUniqueTitles = new Set<string>();
  return docs.reduce<MenuItems>((acc, doc) => {
    if (isEmpty(doc.fields)) {
      // skip empty records if any
      return acc;
    }
    const currentDocCategory = doc.get('Category');
    const currentDocTitle = doc.get('Title');
    const currentDocAntdIconName = doc.get('AntdIconName') as IconNames;

    if (!acc[currentDocCategory]) {
      docUniqueTitles.add(currentDocTitle);
      return {
        ...acc,
        [currentDocCategory]: {
          key: currentDocCategory,
          label: currentDocCategory,
          icon: React.createElement(AntdIcons[currentDocAntdIconName] || AntdIcons.ProfileOutlined),
          children: [
            {
              key: currentDocTitle,
              label: currentDocTitle,
            },
          ],
        },
      };
    }
    // if the current doc title is not unique, skip it
    // this should never happen, but just in case
    if (docUniqueTitles.has(currentDocTitle)) {
      return acc;
    }
    // add current doc title to the children array of the category if it's unique
    docUniqueTitles.add(currentDocTitle);
    return {
      ...acc,
      [currentDocCategory]: {
        ...acc[currentDocCategory],
        children: [
          ...(acc[currentDocCategory]?.children || []),
          {
            key: currentDocTitle,
            label: currentDocTitle,
          },
        ],
      },
    };
  }, {});
};
