import { ActionConditions, Actions, ScopeLevel } from '@/components/Permissions/constants';
import { TEventLevelScopes, TOrganizationLevelScopes } from '@/features/auth0/helpers';
import {
  actionToScopeRequirementsMap,
  routeToRequiredScopeMap,
} from '@/components/Permissions/permissions.config';

import { IMenuItem } from '@/components/Navigation/SideNavigation/menu-items';
import { User } from '@/api/users/types';

type HasScopeToAllowAccessToRouteProps = {
  isSingleEntityRoute: boolean;
  pathname: string;
  allUsedScopesNameMap: Record<string, true>;
};

export const hasScopeToAllowAccessToRoute = ({
  isSingleEntityRoute,
  pathname,
  allUsedScopesNameMap,
}: HasScopeToAllowAccessToRouteProps): boolean => {
  // if the route is a single entity route, then we need to remove the last part of the path
  const scopeKey = isSingleEntityRoute ? pathname.split('/').slice(0, -1).join('/') : pathname;
  const requiredScope = routeToRequiredScopeMap[scopeKey];
  if (!requiredScope) {
    // No required scope for this route
    return true;
  }

  // if the user has the required scope anywhere in user's permissions, then they are allowed to access the route
  // it does not matter if the user has the required scope at system level, organization level, or event level
  return !!allUsedScopesNameMap[requiredScope];
};

type ApplyPermissionsToMenuItemsProps = {
  menuItems: IMenuItem[];
  allUsedScopesNameMap: Record<string, true>;
};

export const applyPermissionsToMenuItems = ({
  menuItems,
  allUsedScopesNameMap,
}: ApplyPermissionsToMenuItemsProps): IMenuItem[] => {
  return menuItems.reduce<IMenuItem[]>((acc, menuItem) => {
    const isCurrentItemAllowed = hasScopeToAllowAccessToRoute({
      isSingleEntityRoute: false,
      pathname: menuItem.key,
      allUsedScopesNameMap,
    });

    if (isCurrentItemAllowed && menuItem.children) {
      return [
        ...acc,
        {
          ...menuItem,
          children: applyPermissionsToMenuItems({
            menuItems: menuItem.children,
            allUsedScopesNameMap,
          }),
        },
      ];
    }
    return isCurrentItemAllowed ? [...acc, menuItem] : acc;
  }, []);
};

type TPermissionValidatorFunctionProps = {
  requiredScope: string;
  topLevelScopes: string[];
  organizationLevelScopes: TOrganizationLevelScopes;
  eventLevelScopes: TEventLevelScopes;
  organizationId?: string;
  eventId?: string;
};

const hasRequiredScopeAtTopLevel = ({
  requiredScope,
  topLevelScopes,
}: TPermissionValidatorFunctionProps) => {
  return topLevelScopes.includes(requiredScope);
};

const hasRequiredScopeForSingleOrganization = ({
  requiredScope,
  organizationId = '',
  organizationLevelScopes,
}: TPermissionValidatorFunctionProps) => {
  const organizationScopes = organizationLevelScopes[organizationId] || [];
  return organizationScopes.includes(requiredScope);
};

const hasRequiredScopeForSomeOrganization = ({
  requiredScope,
  organizationLevelScopes,
}: TPermissionValidatorFunctionProps) => {
  return Object.values(organizationLevelScopes).flat().includes(requiredScope);
};

const hasRequiredScopeForSingleEvent = ({
  requiredScope,
  organizationId = '',
  eventId = '',
  eventLevelScopes,
}: TPermissionValidatorFunctionProps) => {
  const eventScopes = eventLevelScopes[organizationId]?.[eventId] || [];
  return eventScopes.includes(requiredScope);
};

type TConditionValidatorFunctionProps = {
  currentUser: User;
  userRecord?: User;
  topLevelScopes?: string[];
  organizationLevelScopes?: TOrganizationLevelScopes;
};
const isNotCurrentUser = ({ currentUser, userRecord }: TConditionValidatorFunctionProps) => {
  return currentUser.id !== userRecord?.id;
};

const isAllowedToReadAtLeastOneOrganization = ({
  topLevelScopes = [],
  organizationLevelScopes = {},
}: TConditionValidatorFunctionProps) => {
  const hasOrganizationReadAtTopLevel = topLevelScopes.includes('organizations:read');

  // This checks for organizations:read at a single organization level.
  // Currently in the UI it is not allowed to assign "organizations:read" at organization level because we consider it as a system level scope.
  // However, it is no harm to keep this check here in case we deside to allow having "organizations:read" for a single organization
  // A case certainly can be made for a user having "organizations:write" just for a single organization
  const hasOrganizationReadAtOrganizationLevel = Object.values(organizationLevelScopes)
    .flat()
    .includes('organizations:read');
  return hasOrganizationReadAtTopLevel || hasOrganizationReadAtOrganizationLevel;
};

const scopeLevelToScopeCheckFunctionMap = {
  [ScopeLevel.Top]: hasRequiredScopeAtTopLevel,
  [ScopeLevel.SingleOrganization]: hasRequiredScopeForSingleOrganization,
  [ScopeLevel.AnyOrganization]: hasRequiredScopeForSomeOrganization,
  [ScopeLevel.SingleEvent]: hasRequiredScopeForSingleEvent,
};

const additionalConditionToCheckFunctionMap = {
  [ActionConditions.CannotBePerformedOnCurrentUser]: isNotCurrentUser,
  [ActionConditions.AllowedToReadAtLeastOneOrganization]: isAllowedToReadAtLeastOneOrganization,
};

type TVerifyPermissionsForAnActionProps = {
  action: Actions;
  topLevelScopes: string[];
  organizationLevelScopes: TOrganizationLevelScopes;
  eventLevelScopes: TEventLevelScopes;
  organizationId?: string;
  eventId?: string;
};
/*
  scopeRequirements look like this: 
  {
    requiredScope: 'events:write',
    scopeLevel: [ScopeLevel.Top, ScopeLevel.Organization, ScopeLevel.SingleEvent],
  }
  there is a function to check each scope level
  is at least one of these functions return true, then the user has the required scope
*/
export const verifyPermissionsForAnAction = ({
  action,
  topLevelScopes,
  organizationLevelScopes,
  eventLevelScopes,
  organizationId,
  eventId,
}: TVerifyPermissionsForAnActionProps): boolean => {
  const { requiredScope, scopeLevel = [] } = actionToScopeRequirementsMap[action] || {};
  const hasAccess = scopeLevel.some((scopeLevel: ScopeLevel) => {
    const hasRequiredScope = scopeLevelToScopeCheckFunctionMap[scopeLevel]({
      requiredScope,
      topLevelScopes,
      organizationLevelScopes,
      eventLevelScopes,
      organizationId,
      eventId,
    });

    return hasRequiredScope;
  });

  return hasAccess;
};

type TVerifyPermissionsForMultipleActionProps = {
  actions: Actions[];
  topLevelScopes: string[];
  organizationLevelScopes: TOrganizationLevelScopes;
  eventLevelScopes: TEventLevelScopes;
  organizationId?: string;
  eventId?: string;
};

export const verifyPermissionsForMultipleActions = ({
  actions,
  topLevelScopes,
  organizationLevelScopes,
  eventLevelScopes,
  organizationId,
  eventId,
}: TVerifyPermissionsForMultipleActionProps): boolean => {
  return actions.some((action) =>
    verifyPermissionsForAnAction({
      action,
      topLevelScopes,
      organizationLevelScopes,
      eventLevelScopes,
      organizationId,
      eventId,
    })
  );
};

type TVerifyConditionsForAnActionProps = {
  action: Actions;
  currentUser: User;
  userRecord?: User;
  topLevelScopes?: string[];
  organizationLevelScopes?: TOrganizationLevelScopes;
};

export const verifyConditionsForAnAction = ({
  action,
  currentUser,
  userRecord,
  topLevelScopes,
  organizationLevelScopes,
}: TVerifyConditionsForAnActionProps): boolean => {
  const { additionalConditions = [] } = actionToScopeRequirementsMap[action] || {};

  const passAdditionalConditions = additionalConditions.every((condition: ActionConditions) => {
    const isCheckPassed = additionalConditionToCheckFunctionMap[condition]({
      currentUser,
      userRecord,
      topLevelScopes,
      organizationLevelScopes,
    });

    return isCheckPassed;
  });
  return passAdditionalConditions;
};

type TVerifyConditionsForMultipleActionsProps = {
  actions: Actions[];
  currentUser: User;
  userRecord?: User;
  topLevelScopes: string[];
  organizationLevelScopes: TOrganizationLevelScopes;
};

export const verifyConditionsForMultipleActions = ({
  actions,
  currentUser,
  userRecord,
  topLevelScopes,
  organizationLevelScopes,
}: TVerifyConditionsForMultipleActionsProps): boolean => {
  return actions.every((action) =>
    verifyConditionsForAnAction({
      action,
      currentUser,
      userRecord,
      topLevelScopes,
      organizationLevelScopes,
    })
  );
};
