import { CreateScopeUserPayload, ScopeUser } from '@/api/scopeUser/types';
import { MutationConfig, queryClient } from '@/lib/react-query';

import { addNotification } from '@/lib/notifications';
import { axios } from '@/lib/axios';
import { useMutation } from 'react-query';

export const createScopeUser = async (data: CreateScopeUserPayload): Promise<ScopeUser> => {
  return axios.post<any, ScopeUser>('/scope-user/v2', data);
};

export type UseCreateScopeUserOptions = {
  config?: MutationConfig<typeof createScopeUser>;
  data?: CreateScopeUserPayload;
};

export const useCreateScopeUser = ({ config }: UseCreateScopeUserOptions = {}) => {
  return useMutation({
    onMutate: async (newScopeUser) => {
      await queryClient.cancelQueries(['scope-user']);
      const previousScopeUser = queryClient.getQueryData<ScopeUser[]>(['scope-user']);
      queryClient.setQueryData(['scope-user'], [...(previousScopeUser || []), newScopeUser]);
      return { previousScopeUser };
    },
    onError: (error, __, context: any) => {
      if (context?.previousScopeUser) {
        queryClient.setQueryData(['scope-user'], context.previousScopeUser);
      }
      addNotification({
        type: 'error',
        message: error.message,
      });
    },
    onSuccess: async (data) => {
      queryClient.invalidateQueries(['scope-user']);

      return data;
    },
    ...config,
    mutationFn: createScopeUser,
  });
};
