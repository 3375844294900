import { DARK_GRAY, LIGHT_GRAY } from '@/styles/theme';
import styled, { css } from 'styled-components';

import { Menu as AntMenu } from 'antd';

const BaseMenuStyles = css`
  background: ${LIGHT_GRAY};
  li {
    color: ${DARK_GRAY};
  }
`;

export const Menu = styled(AntMenu)`
  ${BaseMenuStyles}
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${LIGHT_GRAY};
  border-right: unset;
`;

export const SidebarSection = styled.div`
  padding: 13px 24px 15px 24px;
  display: flex;
  align-items: center;
  align-content: center;
  flex-direction: column;
  > button {
    box-shadow: none;
  }

  img {
    max-width: 100%;
  }
`;
