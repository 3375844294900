import {
  HeaderContainer,
  HeaderLinkContainer,
  PublicContentContainer,
  PublicHeaderv2,
  StyledMenu,
} from '@/components/Layout/PublicLayoutv2.style';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';

import { Button } from '@/components/Button';
import { FreemanLogoImgv2 } from '@/components/Layout/FreemanLogoImgv2';
import { Layout } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { useCurrentUser } from '@/providers/CurrentUserProvider';
import { useEffect } from 'react';

const API_REFERENCE = 'api-reference';
const UPDATES = 'updates';

const topNavigationItems: MenuProps['items'] = [
  {
    key: UPDATES,
    label: `Updates`,
  },
  {
    key: API_REFERENCE,
    label: `API Reference`,
  },
];

const topNavigationExternalLinksMap = {
  [API_REFERENCE]: 'https://docs.fuzion.freeman.com/',
};

export const PublicLayoutv2 = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { isAuthenticated, loginWithRedirect } = useCurrentUser();

  const activeRoute = pathname.split('/')[1];

  const handleTopNavClick = ({ key }: { key: string }) => {
    if (Object.hasOwn(topNavigationExternalLinksMap, key)) {
      const url = topNavigationExternalLinksMap[key as keyof typeof topNavigationExternalLinksMap];

      const windowReference = window.open();
      if (windowReference) {
        // this is a fix for safari opening a new window instead of a new tab or blocking a popup window altogether
        windowReference.location = url;
      } else {
        // in case the windowReference is null, we fallback to the default behavior
        window.open(url, '_blank', 'noopener noreferrer');
      }
      return;
    }

    navigate(`/${key}`);
  };

  const handleLoginClick = () => {
    return isAuthenticated ? navigate('/auth/profile') : loginWithRedirect();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Layout>
      <PublicHeaderv2>
        <HeaderContainer>
          <Link id="freeman-public-section-logo" to="/">
            <FreemanLogoImgv2 />
          </Link>
          <HeaderLinkContainer>
            <StyledMenu
              overflowedIndicator={<MenuOutlined />}
              mode="horizontal"
              selectedKeys={activeRoute ? [activeRoute] : ['home']}
              items={topNavigationItems}
              onClick={handleTopNavClick}
            />
            <Button id="login-button" type="public-pages-orange" onClick={handleLoginClick}>
              {isAuthenticated ? 'Go to Fuzion' : 'Login to Fuzion'}
            </Button>
          </HeaderLinkContainer>
        </HeaderContainer>
      </PublicHeaderv2>
      <Layout>
        <PublicContentContainer>
          <Outlet />
        </PublicContentContainer>
      </Layout>
    </Layout>
  );
};
