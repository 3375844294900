import { MutationConfig, queryClient } from '@/lib/react-query';
import { UpdateUserPayload, User } from '@/api/users/types';

import { addNotification } from '@/lib/notifications';
import { axios } from '@/lib/axios';
import { useMutation } from 'react-query';

export const updateUser = async ({ id, data }: UpdateUserPayload): Promise<User> => {
  return axios.patch(`/users/v2/${id}`, data);
};

export type UseUpdateUserOptions = {
  config?: MutationConfig<typeof updateUser>;
  data?: UpdateUserPayload;
};

export const useUpdateUser = ({ config }: UseUpdateUserOptions = {}) => {
  return useMutation({
    onMutate: async (updatedUser) => {
      await queryClient.cancelQueries(['user', updatedUser?.id]);
      const previousUser = queryClient.getQueryData<User>(['user', updatedUser?.id]);
      queryClient.setQueryData(['user', updatedUser?.id], {
        ...previousUser,
        ...updatedUser.data,
        id: updatedUser.id,
      });

      return { previousUser };
    },
    onError: (error, __, context: any) => {
      if (context?.previousUser) {
        queryClient.setQueryData(['users', context.previousUser.id], context.previousUser);
      }
      addNotification({
        type: 'error',
        message: error.message,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['users']);
    },
    ...config,
    mutationFn: updateUser,
  });
};
