/**
 * Detects the operating system currently in use and returns an OS slug.
 */
export function detectOperatingSystem() {
  let os = navigator.userAgent;
  let finalOs = 'other';
  if (os.search('Windows') !== -1) {
    finalOs = 'microsoft';
  } else if (os.search('Mac') !== -1) {
    finalOs = 'mac';
  } else if (os.search('Linux') !== -1 && os.search('X11') !== -1) {
    finalOs = 'linux';
  }

  return finalOs;
}

export function detectDevice() {
  let deviceDetected = 'desktop';

  if (
    /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
      navigator.userAgent.toLowerCase()
    )
  ) {
    deviceDetected = 'mobile';
  }

  return deviceDetected;
}
