import { Event } from '@/api/events/types';
import { EventScopeUser } from '@/api/eventScopeUser/types';
import { Organization } from '@/api/organizations/types';
import { OrganizationScopeUser } from '@/api/organizationScopeUser/types';
import { Scope } from '@/api/scopes/types';
import { ScopeUser } from '@/api/scopeUser/types';

type TComposeScopeMapProps = {
  allUsedScopes?: Scope[];
  allUsedOrganizations?: Organization[];
  allUsedEvents?: Event[];
  scopeUsers?: ScopeUser[];
  organizationScopeUsers?: OrganizationScopeUser[];
  eventScopeUsers?: EventScopeUser[];
};

type TScopeMapObjects = {
  allUsedScopesIdToNameMap: Record<string, string>;
  allUsedScopesNameMap: Record<string, true>;
  allUsedOrganizationsMap: Record<string, string>;
  allUsedEventsMap: Record<string, Event>;
  scopeIdToScopeUsersMap: Record<string, ScopeUser>;
  scopeIdToOrganizationScopeUsersArrayMap: Record<string, OrganizationScopeUser[]>;
  scopeIdToEventScopeUsersArrayMap: Record<string, EventScopeUser[]>;
};

export const composeUserScopeMapObjects = ({
  allUsedScopes = [],
  allUsedOrganizations = [],
  allUsedEvents = [],
  scopeUsers = [],
  organizationScopeUsers = [],
  eventScopeUsers = [],
}: TComposeScopeMapProps): TScopeMapObjects => {
  const allUsedScopesIdToNameMap = allUsedScopes.reduce<Record<string, string>>(
    (acc, scope) => ({ ...acc, [scope.id]: scope.name }),
    {}
  );
  const allUsedScopesNameMap = allUsedScopes.reduce<Record<string, true>>(
    (acc, scope) => ({ ...acc, [scope.name]: true }),
    {}
  );

  const allUsedOrganizationsMap = allUsedOrganizations.reduce<Record<string, string>>(
    (acc, organization) => ({ ...acc, [organization.id]: organization.fullName }),
    {}
  );

  const allUsedEventsMap = allUsedEvents.reduce<Record<string, Event>>(
    (acc, event) => ({ ...acc, [event.id]: event }),
    {}
  );

  const scopeIdToScopeUsersMap = scopeUsers.reduce<Record<string, ScopeUser>>(
    (acc, appScope) => ({ ...acc, [appScope.scopeId]: appScope }),
    {}
  );

  const scopeIdToOrganizationScopeUsersArrayMap = organizationScopeUsers.reduce<
    Record<string, OrganizationScopeUser[]>
  >(
    (acc, appOrgScope) => ({
      ...acc,
      [appOrgScope.scopeId]: [...(acc[appOrgScope.scopeId] || []), appOrgScope],
    }),
    {}
  );

  const scopeIdToEventScopeUsersArrayMap = eventScopeUsers.reduce<Record<string, EventScopeUser[]>>(
    (acc, appEventScope) => ({
      ...acc,
      [appEventScope.scopeId]: [...(acc[appEventScope.scopeId] || []), appEventScope],
    }),
    {}
  );

  return {
    allUsedScopesIdToNameMap,
    allUsedScopesNameMap,
    allUsedOrganizationsMap,
    allUsedEventsMap,
    scopeIdToScopeUsersMap,
    scopeIdToOrganizationScopeUsersArrayMap,
    scopeIdToEventScopeUsersArrayMap,
  };
};
