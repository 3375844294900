import { PublicHeroSection, PublicHeroText } from '@/features/documentation/Documentation.style';
import {
  PublicPageContainer,
  PublicPageHeroRow,
  PublicPageTitle,
} from '@/features/publicPages/PublicPage.style';
import { UseInfiniteQueryResult } from 'react-query';
import { ContenttopicApiServiceHooks } from '@/api/contenttopics';
import { Contenttopic, ContenttopicsWithMeta, tagData, topicTag } from '@/api/contenttopics/types';
import { ContentcategoryApiServiceHooks } from '@/api/contentcategories';
import { ContentcategoriesWithMeta } from '@/api/contentcategories/types';

import _ from 'lodash';
import { Col } from 'antd';
import { PublicPageFooter } from '@/components/Footer/PublicPageFooter';
import { UpdatesContent } from '@/features/updates/UpdatesContent';
import moment from 'moment';
import { Contenttag, ContenttagsWithMeta } from '@/api/contenttags/types';
import { ContenttagContenttopicWithMeta } from '@/api/contenttagContenttopic/types';
import { useSearchInfiniteContenttags } from '@/api/contenttags/crud/get-contenttag.api';
import { useSearchInfiniteContenttagContenttopics } from '@/api/contenttagContenttopic/crud/get-contenttag-contenttopic.api';

type tagName = {
  [key: string]: string;
};

const { useSearchInfiniteContenttopics } = ContenttopicApiServiceHooks;
const { useSearchInfiniteContentcategories } = ContentcategoryApiServiceHooks;

export const UpdatesPage = () => {
  const contentcategoryInfiniteQuery = useSearchInfiniteContentcategories({ name: 'Updates' });
  const contenttagInfiniteQuery = useSearchInfiniteContenttags({});
  const contenttagcontenttopicInfiniteQuery = useSearchInfiniteContenttagContenttopics({});

  const getUpdatesCategory = (categoryData: UseInfiniteQueryResult<ContentcategoriesWithMeta>) => {
    const categoryList = categoryData?.data?.pages?.flatMap((categories) => categories?.collection);

    return categoryList?.length ? categoryList[0]?.id : '';
  };

  const updatesId = getUpdatesCategory(contentcategoryInfiniteQuery);

  const contenttopicInfiniteQuery = useSearchInfiniteContenttopics(
    {
      contentcategoryId: updatesId,
    },
    {
      enabled: !!updatesId,
    }
  );

  const getContenttags = (tagData: UseInfiniteQueryResult<ContenttagsWithMeta>) => {
    if (tagData?.hasNextPage) {
      tagData.fetchNextPage();
    }

    const tagList = tagData.data?.pages?.flatMap((tags) => {
      return tags?.collection;
    });

    return _.uniqBy(tagList, 'id');
  };

  const getContenttopics = (
    topicData: UseInfiniteQueryResult<ContenttopicsWithMeta>,
    allTopicTags: topicTag
  ) => {
    if (topicData?.hasNextPage) {
      topicData.fetchNextPage();
    }
    const relevantTags: tagData[][] = [];
    const topicList = topicData.data?.pages?.flatMap((topics) =>
      topics?.collection
        ?.filter((topic: Contenttopic) => {
          const topicDate = moment(topic.publishedAt)
            .subtract(topic.timezoneOffsetInMinutes, 'minutes')
            .toISOString();
          const currentDate = moment().toISOString();

          return topic.publishedAt && currentDate >= topicDate;
        })
        ?.map((topic) => {
          if (allTopicTags[topic.id]) {
            relevantTags.push(allTopicTags[topic.id]);
          }
          topic.tagData = allTopicTags[topic.id];
          topic.tags = allTopicTags[topic.id]?.map((tag) => {
            return tag.tagId;
          });
          return topic;
        })
    );

    return {
      topicData: _.uniqBy(topicList, 'id'),
      tagData: _.uniqBy(relevantTags.flat(), 'tagId'),
      isTopicDataLoading: topicData.status === 'idle' || topicData.isLoading,
    };
  };

  const getTagNames = (tags: Contenttag[]) => {
    const tagNameObj: tagName = {};
    tags.forEach((tag) => {
      if (!tagNameObj[tag.id]) {
        tagNameObj[tag.id] = tag.name;
      }
    });

    return tagNameObj;
  };

  const getRelevantTagList = (
    tagTopicData: UseInfiniteQueryResult<ContenttagContenttopicWithMeta>,
    allTags: Contenttag[]
  ) => {
    if (tagTopicData?.hasNextPage) {
      tagTopicData.fetchNextPage();
    }

    const tagList = tagTopicData.data?.pages?.flatMap((tagTopics) => tagTopics?.collection);

    const tagNames = getTagNames(allTags);

    const topicTags: topicTag = {};
    tagList?.forEach((tagtopic) => {
      if (topicTags[tagtopic.contenttopicId]) {
        topicTags[tagtopic.contenttopicId] = [
          ...topicTags[tagtopic.contenttopicId],
          { name: tagNames[tagtopic.contenttagId] ?? '', tagId: tagtopic.contenttagId },
        ];
      } else {
        topicTags[tagtopic.contenttopicId] = [
          { name: tagNames[tagtopic.contenttagId] ?? '', tagId: tagtopic.contenttagId },
        ];
      }
    });

    return topicTags;
  };

  const getTopicWithTags = (
    tagData: UseInfiniteQueryResult<ContenttagsWithMeta>,
    topicData: UseInfiniteQueryResult<ContenttopicsWithMeta>,
    tagTopicData: UseInfiniteQueryResult<ContenttagContenttopicWithMeta>
  ) => {
    const allTags = getContenttags(tagData);
    const allTopicTags = getRelevantTagList(tagTopicData, allTags);
    const allTopics = getContenttopics(topicData, allTopicTags);
    return allTopics;
  };

  return (
    <>
      <PublicPageContainer>
        <PublicPageHeroRow>
          <Col span={24}>
            <PublicHeroSection>
              <PublicHeroText>
                <PublicPageTitle>Updates</PublicPageTitle>
              </PublicHeroText>
            </PublicHeroSection>
          </Col>
        </PublicPageHeroRow>
        <UpdatesContent
          data={getTopicWithTags(
            contenttagInfiniteQuery,
            contenttopicInfiniteQuery,
            contenttagcontenttopicInfiniteQuery
          )}
        />
      </PublicPageContainer>
      <PublicPageFooter />
    </>
  );
};
