import {
  PUBLIC_PAGE_ELEMENT_DARK_BLUE,
  PUBLIC_PAGE_ELEMENT_FADED_BLUE,
  PUBLIC_PAGE_TEXT_ORANGE,
} from '@/styles/theme';
import { Row, Typography } from 'antd';
import styled, { keyframes } from 'styled-components';

import { deviceSmallerThan } from '@/styles/mediaqueries';

const { Title } = Typography;

const tickerAnimation = keyframes`
  0% { transform: translate3d(0, 0, 0); }
  100% { transform: translate3d(-2000px, 0, 0); }
`;

export const HeroImageRow = styled(Row)`
  width: 100%;
  min-height: 550px;
  background: ${PUBLIC_PAGE_ELEMENT_DARK_BLUE} no-repeat url('/images/banner-image-lines-chips.png');
  background-size: contain;
  background-position: right;
`;

export const HomeHeroText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  min-width: 240px;
`;

export const BrandsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 115px;
  background-color: ${PUBLIC_PAGE_ELEMENT_FADED_BLUE};
`;

export const TickerContent = styled.div`
  width: calc(100vw - 100px);
  overflow: hidden;
`;

export const Ticker = styled.div`
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
  gap: 95px;
  align-items: center;
  animation-name: ${tickerAnimation};
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-duration: 20s;

  &:hover {
    animation-play-state: paused;
  }

  img {
    filter: grayscale(100);
    height: 50px;
  }
`;

export const TickerItemContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 190.7px;
`;

export const HomepageTitle = styled(Title)`
  &&& {
    color: white;
    font-size: 60px;
    letter-spacing: 2px;
    font-weight: 500;
    text-align: start;

    ${deviceSmallerThan.tabletS} {
      font-size: 46px;
    }
  }
`;

export const HomepageSubTitle = styled(Title)`
  &&& {
    color: ${PUBLIC_PAGE_TEXT_ORANGE};
    font-size: 42px;
    letter-spacing: 2px;
    width: 100%;
    text-align: start;
    font-weight: 500;
    margin-top: 30px;

    ${deviceSmallerThan.tabletS} {
      font-size: 32px;
    }
  }
`;

export const InfoSection = styled(Row)`
  width: 100%;
  padding: 120px 0;
  background: no-repeat url('/images/share-data-graphic.png');
  background-size: clamp(200px, 30%, 370px);
  background-position-x: left;
  background-position-y: top;

  h2.ant-typography {
    color ${PUBLIC_PAGE_ELEMENT_DARK_BLUE};
    font-size: 42px;
    font-weight: 500;
    text-align: center;  
  }

  span.ant-typography {
    text-align: center;
    font-weight: 400;
    font-size: 18px;
  }
`;

export const TextRow = styled(Row)`
  margin: 0 0 80px 0;
`;

export const CardsContainer = styled.div`
  max-width: 1400px;
  margin: 0 auto;
`;

export const ServicesSection = styled(Row)`
  width: 100%;
  margin: 120px 0 0 0;
  gap: 100px;

  h2.ant-typography {
    color ${PUBLIC_PAGE_ELEMENT_DARK_BLUE};
    font-size: 42px;
    font-weight: 500;
    text-align: center;  
  }

  span.ant-typography {
    text-align: center;
    font-weight: 400;
    font-size: 18px;
  }
`;

export const HighlightedText = styled.span`
  color: ${PUBLIC_PAGE_TEXT_ORANGE};
`;

export const ServicesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 80px;
  row-gap: 36px;
  margin: 0 20px 0 0;

  ${deviceSmallerThan.antdXL} {
    justify-content: center;
  }
`;

export const CTASection = styled(Row)`
  width: 100%;
  background: no-repeat url('/images/homepage-orange-section-bg.png');
  background-size: cover;
  background-position-x: right;
  min-height: 600px;
  margin: 50px 0 88px 0;
  

  :after {
    content: '';
    height: 100px;
    width: 100%;
    background-color: #FFAB00;
    position: relative;
    top: 145px;
  }

  h2.ant-typography {
    color ${PUBLIC_PAGE_ELEMENT_DARK_BLUE};
    font-size: 42px;
    font-weight: 500;
    text-align: center;  
  }

  span.ant-typography {
    text-align: center;
    font-weight: 400;
    font-size: 18px;
  }
`;

export const CTATextRow = styled(Row)`
  margin: 0 0 40px 0;
`;

export const CTAContainer = styled.div`
  background-color: white;
  border-radius: 12px;
  padding: 48px;
  position: relative;
  top: 230px;
`;
