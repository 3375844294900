import { IMenuItem, menuItems } from '@/components/Navigation/SideNavigation/menu-items';
import { Menu, SidebarSection } from './SideNavigation.style';
import React, { useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Button } from '@/components/Button';
import { LogoImg } from '@/components/Navigation/SideNavigation/LogoImg';
import { MenuInfo } from 'rc-menu/lib/interface';
import { MenuItem } from '@/components/Navigation/SideNavigation/MenuItem';
import { Sider } from '@/components/Layout/MainLayout.style';
import { SubMenu } from '@/components/Navigation/SideNavigation/SubMenu';
import { applyPermissionsToMenuItems } from '@/components/Permissions/permissionHelpers';
import kebabCase from 'lodash/kebabCase';
import { useCurrentUser } from '@/providers/CurrentUserProvider';

interface WrapperTitleEventEntity {
  key: string;
  domEvent: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>;
}

export const SideNavigation = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { allUsedScopesNameMap, logout } = useCurrentUser();
  const [openKeys, setOpenKeys] = useState(['']);

  const allowedMenuItems = useMemo(
    () => applyPermissionsToMenuItems({ menuItems, allUsedScopesNameMap }),
    [allUsedScopesNameMap]
  );

  const groupKeys = allowedMenuItems?.map((item) => {
    if (item.type === 'group') {
      return item.key;
    }
  });
  const externalKeys = allowedMenuItems?.map((item) => {
    if (item.external === true) {
      return item.key;
    }
  });

  const onClick = (props: MenuInfo | WrapperTitleEventEntity) => {
    const isCollapsible = groupKeys.includes(props.key);
    const isExternal = externalKeys.includes(props.key);
    if (!isCollapsible) {
      if (isExternal) {
        window.open(props.key, '_blank', 'noopener noreferrer');
      } else {
        navigate(props.key);
      }
    }

    if (isCollapsible) {
      if (openKeys.includes(props.key)) {
        setOpenKeys(openKeys.filter((key) => key !== props.key));
      } else {
        setOpenKeys([...openKeys, props.key]);
      }
    }
  };

  const composeElementId = (label: string) => {
    const id = kebabCase(label);
    return `layout-sidenav-menu-item-${id}`;
  };

  return (
    <Sider id="layout-sidenav-container" width="260px">
      <SidebarSection>
        <Button
          id="layout-sidenav-logo"
          type="unstyled"
          onClick={() => {
            navigate('/');
          }}
        >
          <LogoImg />
        </Button>
      </SidebarSection>
      <Menu id="layout-sidenav-menu" mode="inline" onClick={onClick} openKeys={openKeys}>
        {allowedMenuItems.map(({ key, iconName, label, children }: IMenuItem) => {
          const isSelected = pathname.startsWith(key);
          return children ? (
            <SubMenu
              id={composeElementId(label)}
              title={label}
              selected={isSelected}
              key={key}
              iconName={iconName}
              onTitleClick={onClick}
            >
              {children.map(
                ({ label: childLabel, key: childKey, iconName: childIconName }: IMenuItem) => {
                  const isSubMenuItemSelected = pathname.startsWith(childKey);
                  return (
                    <MenuItem
                      id={composeElementId(`sub-${childLabel}`)}
                      selected={isSubMenuItemSelected}
                      key={childKey}
                      iconName={childIconName}
                    >
                      {childLabel}
                    </MenuItem>
                  );
                }
              )}
            </SubMenu>
          ) : (
            <MenuItem
              id={composeElementId(label)}
              selected={isSelected}
              key={key}
              iconName={iconName}
            >
              {label}
            </MenuItem>
          );
        })}
        <MenuItem
          id={composeElementId('logout')}
          onClick={logout}
          key="app-logout"
          selected={false}
          iconName="logout"
        >
          Logout
        </MenuItem>
      </Menu>
    </Sider>
  );
};
