export const IconsDict = {
  briefcase: (fillColor = '#00bdf6') => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke={fillColor}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="feather feather-briefcase"
    >
      <rect x="2" y="7" width="20" height="14" rx="2" ry="2"></rect>
      <path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16"></path>
    </svg>
  ),

  key: (fillColor = '#00bdf6') => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke={fillColor}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="feather feather-key"
    >
      <path d="M21 2l-2 2m-7.61 7.61a5.5 5.5 0 1 1-7.778 7.778 5.5 5.5 0 0 1 7.777-7.777zm0 0L15.5 7.5m0 0l3 3L22 7l-3-3m-3.5 3.5L19 4"></path>
    </svg>
  ),
  list: () => (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 458.18 458.18"
      xmlSpace="preserve"
    >
      <path
        d="M36.09,5.948c-18.803,0-34.052,15.248-34.052,34.051c0,18.803,15.249,34.052,34.052,34.052
		c18.803,0,34.052-15.25,34.052-34.052C70.142,21.196,54.893,5.948,36.09,5.948z"
      />
      <path
        d="M147.537,80h268.604c22.092,0,40-17.908,40-40s-17.908-40-40-40H147.537c-22.092,0-40,17.908-40,40S125.445,80,147.537,80z
		"
      />
      <path
        d="M36.09,132.008c-18.803,0-34.052,15.248-34.052,34.051s15.249,34.052,34.052,34.052c18.803,0,34.052-15.249,34.052-34.052
		S54.893,132.008,36.09,132.008z"
      />
      <path
        d="M416.142,126.06H147.537c-22.092,0-40,17.908-40,40s17.908,40,40,40h268.604c22.092,0,40-17.908,40-40
		S438.233,126.06,416.142,126.06z"
      />
      <path
        d="M36.09,258.068c-18.803,0-34.052,15.248-34.052,34.051c0,18.803,15.249,34.052,34.052,34.052
		c18.803,0,34.052-15.249,34.052-34.052C70.142,273.316,54.893,258.068,36.09,258.068z"
      />
      <path
        d="M416.142,252.119H147.537c-22.092,0-40,17.908-40,40s17.908,40,40,40h268.604c22.092,0,40-17.908,40-40
		S438.233,252.119,416.142,252.119z"
      />
      <path
        d="M36.09,384.128c-18.803,0-34.052,15.248-34.052,34.051s15.249,34.053,34.052,34.053c18.803,0,34.052-15.25,34.052-34.053
		S54.893,384.128,36.09,384.128z"
      />
      <path
        d="M416.142,378.18H147.537c-22.092,0-40,17.908-40,40s17.908,40,40,40h268.604c22.092,0,40-17.908,40-40
		S438.233,378.18,416.142,378.18z"
      />
    </svg>
  ),
  calendar: (fillColor: string) => (
    <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.4 1.636h-.8V0H12v1.636H4V0H2.4v1.636h-.8A1.623 1.623 0 0 0 0 3.273v13.091A1.623 1.623 0 0 0 1.6 18h12.8a1.623 1.623 0 0 0 1.6-1.636V3.273a1.623 1.623 0 0 0-1.6-1.637zm0 14.727H1.6V5.727h12.8v10.636z"
        fill={fillColor}
      />
    </svg>
  ),
  cancel: (fillColor: string) => {
    return (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10 0C4.47 0 0 4.47 0 10s4.47 10 10 10 10-4.47 10-10S15.53 0 10 0zm5 13.59L13.59 15 10 11.41 6.41 15 5 13.59 8.59 10 5 6.41 6.41 5 10 8.59 13.59 5 15 6.41 11.41 10 15 13.59z"
          fill={fillColor}
        />
      </svg>
    );
  },
  data_explorer: (fillColor: string) => (
    <svg width="14" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#pfjfpirnfa)">
        <path
          d="M13.928.535A.536.536 0 0 0 13.393 0H11.25a.536.536 0 0 0-.536.536v1.607h3.215V.536zM2.14 4.285C2.056 7.428.116 8.11 0 12.456v1.473A1.072 1.072 0 0 0 1.071 15h3.215a1.072 1.072 0 0 0 1.071-1.072V8.572h1.071V3.216H3.21a1.081 1.081 0 0 0-1.069 1.07zm12.863 0a1.081 1.081 0 0 0-1.069-1.07h-3.22v5.357h1.072v5.357A1.071 1.071 0 0 0 12.857 15h3.215a1.072 1.072 0 0 0 1.071-1.072v-1.473c-.116-4.345-2.056-5.027-2.14-8.17zM5.893 0H3.75a.536.536 0 0 0-.535.535v1.608h3.214V.536A.536.536 0 0 0 5.893 0zM7.5 8.572h2.143V3.215H7.5v5.357z"
          fill={fillColor}
        />
      </g>
      <defs>
        <clipPath id="pfjfpirnfa">
          <path fill="#fff" d="M0 0h17.143v15H0z" />
        </clipPath>
      </defs>
    </svg>
  ),
  users: (fillColor: string) => (
    <svg width="14" height="15" viewBox="0 0 22 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#prlwp0u1ea)">
        <path
          d="M3.215 6.428a2.143 2.143 0 1 0 0-4.285 2.143 2.143 0 0 0 0 4.285zm15 0a2.143 2.143 0 1 0 0-4.286 2.143 2.143 0 0 0 0 4.287zm1.07 1.072h-2.142c-.566 0-1.11.224-1.51.623a4.9 4.9 0 0 1 2.515 3.663h2.21a1.071 1.071 0 0 0 1.071-1.072V9.643A2.145 2.145 0 0 0 19.286 7.5zm-8.57 0a3.75 3.75 0 1 0 0-7.5 3.75 3.75 0 0 0 0 7.5zm2.571 1.072h-.278a5.177 5.177 0 0 1-4.587 0h-.278a3.858 3.858 0 0 0-3.857 3.857v.964A1.608 1.608 0 0 0 5.893 15h9.643a1.608 1.608 0 0 0 1.606-1.607v-.965a3.858 3.858 0 0 0-3.856-3.857zm-7.49-.45a2.137 2.137 0 0 0-1.51-.622H2.143A2.145 2.145 0 0 0 0 9.643v1.071a1.07 1.07 0 0 0 1.071 1.072h2.207a4.91 4.91 0 0 1 2.518-3.663v-.002z"
          fill={fillColor}
        />
      </g>
      <defs>
        <clipPath id="prlwp0u1ea">
          <path fill="#fff" d="M0 0h21.428v15H0z" />
        </clipPath>
      </defs>
    </svg>
  ),
  users_selected: (fillColor: string) => (
    <svg width="22" height="15" viewBox="0 0 22 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#fikwt03p1a)">
        <path
          d="M3.215 6.428a2.143 2.143 0 1 0 0-4.285 2.143 2.143 0 0 0 0 4.285zm15 0a2.143 2.143 0 1 0 0-4.286 2.143 2.143 0 0 0 0 4.287zm1.07 1.072h-2.142c-.566 0-1.11.224-1.51.623a4.9 4.9 0 0 1 2.515 3.663h2.21a1.071 1.071 0 0 0 1.071-1.072V9.643A2.145 2.145 0 0 0 19.286 7.5zm-8.57 0a3.75 3.75 0 1 0 0-7.5 3.75 3.75 0 0 0 0 7.5zm2.571 1.072h-.278a5.177 5.177 0 0 1-4.587 0h-.278a3.858 3.858 0 0 0-3.857 3.857v.964A1.608 1.608 0 0 0 5.893 15h9.643a1.608 1.608 0 0 0 1.606-1.607v-.965a3.858 3.858 0 0 0-3.856-3.857zm-7.49-.45a2.137 2.137 0 0 0-1.51-.622H2.143A2.145 2.145 0 0 0 0 9.643v1.071a1.07 1.07 0 0 0 1.071 1.072h2.207a4.91 4.91 0 0 1 2.518-3.663v-.002z"
          fill={fillColor}
        />
      </g>
      <defs>
        <clipPath id="fikwt03p1a">
          <path fill="#fff" d="M0 0h21.428v15H0z" />
        </clipPath>
      </defs>
    </svg>
  ),
  organizations: (fillColor: string) => (
    <svg width="14" height="12" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#or4vf3ne6a)">
        <path
          d="M12.774 14.063h-.586V.703A.703.703 0 0 0 11.483 0H1.64a.703.703 0 0 0-.702.703v13.36H.35a.35.35 0 0 0-.35.35V15h13.125v-.586a.35.35 0 0 0-.351-.351zM3.75 2.226a.35.35 0 0 1 .35-.35h1.172a.35.35 0 0 1 .35.35V3.4a.35.35 0 0 1-.35.35H4.1a.35.35 0 0 1-.35-.35V2.227zm0 2.813a.35.35 0 0 1 .35-.35h1.172a.35.35 0 0 1 .35.35v1.172a.35.35 0 0 1-.35.35H4.1a.35.35 0 0 1-.35-.35V5.039zm1.524 4.336H4.1a.35.35 0 0 1-.35-.35V7.85a.35.35 0 0 1 .35-.35h1.172a.35.35 0 0 1 .35.35v1.172a.35.35 0 0 1-.348.353zM7.5 14.063H5.625V11.6a.35.35 0 0 1 .35-.35H7.15a.35.35 0 0 1 .35.35v2.463zm1.875-5.04a.35.35 0 0 1-.35.35H7.85a.35.35 0 0 1-.35-.35V7.85a.35.35 0 0 1 .35-.35h1.172a.35.35 0 0 1 .35.35l.003 1.174zm0-2.812a.35.35 0 0 1-.35.35H7.85a.35.35 0 0 1-.35-.35V5.039a.35.35 0 0 1 .35-.35h1.172a.35.35 0 0 1 .35.35l.003 1.172zm0-2.813a.35.35 0 0 1-.35.35H7.85a.35.35 0 0 1-.35-.35V2.228a.35.35 0 0 1 .35-.35h1.172a.35.35 0 0 1 .35.35l.003 1.171z"
          fill={fillColor}
        />
      </g>
      <defs>
        <clipPath id="or4vf3ne6a">
          <path fill="#fff" d="M0 0h13.125v15H0z" />
        </clipPath>
      </defs>
    </svg>
  ),
  organizations_selected: (fillColor: string) => (
    <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#m3udi34rra)">
        <path
          d="M12.774 14.063h-.586V.703A.703.703 0 0 0 11.483 0H1.64a.703.703 0 0 0-.702.703v13.36H.35a.35.35 0 0 0-.35.35V15h13.125v-.586a.35.35 0 0 0-.351-.351zM3.75 2.226a.35.35 0 0 1 .35-.35h1.172a.35.35 0 0 1 .35.35V3.4a.35.35 0 0 1-.35.35H4.1a.35.35 0 0 1-.35-.35V2.227zm0 2.813a.35.35 0 0 1 .35-.35h1.172a.35.35 0 0 1 .35.35v1.172a.35.35 0 0 1-.35.35H4.1a.35.35 0 0 1-.35-.35V5.039zm1.524 4.336H4.1a.35.35 0 0 1-.35-.35V7.85a.35.35 0 0 1 .35-.35h1.172a.35.35 0 0 1 .35.35v1.172a.35.35 0 0 1-.348.353zM7.5 14.063H5.625V11.6a.35.35 0 0 1 .35-.35H7.15a.35.35 0 0 1 .35.35v2.463zm1.875-5.04a.35.35 0 0 1-.35.35H7.85a.35.35 0 0 1-.35-.35V7.85a.35.35 0 0 1 .35-.35h1.172a.35.35 0 0 1 .35.35l.003 1.174zm0-2.812a.35.35 0 0 1-.35.35H7.85a.35.35 0 0 1-.35-.35V5.039a.35.35 0 0 1 .35-.35h1.172a.35.35 0 0 1 .35.35l.003 1.172zm0-2.813a.35.35 0 0 1-.35.35H7.85a.35.35 0 0 1-.35-.35V2.228a.35.35 0 0 1 .35-.35h1.172a.35.35 0 0 1 .35.35l.003 1.171z"
          fill={fillColor}
        />
      </g>
      <defs>
        <clipPath id="m3udi34rra">
          <path fill="#fff" d="M0 0h13.125v15H0z" />
        </clipPath>
      </defs>
    </svg>
  ),
  locations: (fillColor: string) => (
    <svg width="16" height="15" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.047 14.697C.79 8.527 0 7.893 0 5.626a5.625 5.625 0 0 1 11.25 0c0 2.268-.79 2.9-5.047 9.072a.703.703 0 0 1-1.156 0zm.578-6.728a2.344 2.344 0 1 0-.001-4.688 2.344 2.344 0 0 0 .001 4.688z"
        fill={fillColor}
      />
    </svg>
  ),
  integrations: (fillColor: string) => (
    <svg
      viewBox="64 64 896 896"
      focusable="false"
      data-icon="api"
      width="1em"
      height="1em"
      fill={fillColor}
      aria-hidden="true"
    >
      <path d="M917.7 148.8l-42.4-42.4c-1.6-1.6-3.6-2.3-5.7-2.3s-4.1.8-5.7 2.3l-76.1 76.1a199.27 199.27 0 00-112.1-34.3c-51.2 0-102.4 19.5-141.5 58.6L432.3 308.7a8.03 8.03 0 000 11.3L704 591.7c1.6 1.6 3.6 2.3 5.7 2.3 2 0 4.1-.8 5.7-2.3l101.9-101.9c68.9-69 77-175.7 24.3-253.5l76.1-76.1c3.1-3.2 3.1-8.3 0-11.4zM769.1 441.7l-59.4 59.4-186.8-186.8 59.4-59.4c24.9-24.9 58.1-38.7 93.4-38.7 35.3 0 68.4 13.7 93.4 38.7 24.9 24.9 38.7 58.1 38.7 93.4 0 35.3-13.8 68.4-38.7 93.4zm-190.2 105a8.03 8.03 0 00-11.3 0L501 613.3 410.7 523l66.7-66.7c3.1-3.1 3.1-8.2 0-11.3L441 408.6a8.03 8.03 0 00-11.3 0L363 475.3l-43-43a7.85 7.85 0 00-5.7-2.3c-2 0-4.1.8-5.7 2.3L206.8 534.2c-68.9 69-77 175.7-24.3 253.5l-76.1 76.1a8.03 8.03 0 000 11.3l42.4 42.4c1.6 1.6 3.6 2.3 5.7 2.3s4.1-.8 5.7-2.3l76.1-76.1c33.7 22.9 72.9 34.3 112.1 34.3 51.2 0 102.4-19.5 141.5-58.6l101.9-101.9c3.1-3.1 3.1-8.2 0-11.3l-43-43 66.7-66.7c3.1-3.1 3.1-8.2 0-11.3l-36.6-36.2zM441.7 769.1a131.32 131.32 0 01-93.4 38.7c-35.3 0-68.4-13.7-93.4-38.7a131.32 131.32 0 01-38.7-93.4c0-35.3 13.7-68.4 38.7-93.4l59.4-59.4 186.8 186.8-59.4 59.4z"></path>
    </svg>
  ),
  location_selected: (fillColor: string) => (
    <svg width="12" height="15" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.047 14.697C.79 8.527 0 7.893 0 5.626a5.625 5.625 0 0 1 11.25 0c0 2.268-.79 2.9-5.047 9.072a.703.703 0 0 1-1.156 0zm.578-6.728a2.344 2.344 0 1 0-.001-4.688 2.344 2.344 0 0 0 .001 4.688z"
        fill={fillColor}
      />
    </svg>
  ),
  dropdown: (fillColor: string) => (
    <svg
      width="11"
      height="7"
      viewBox="0 0 11 7"
      fill={fillColor}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m0 0 5.416 6.667L10.833 0H0z" fill="#000" />
    </svg>
  ),
  chevron_up: (fillColor: string) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="11" height="7" viewBox="0 -3 11 7" fill="none">
      <path d="M10.833 6.667 5.417 0 0 6.667h10.833z" fill={fillColor} />
    </svg>
  ),
  chevron_down: (fillColor: string) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="11" height="7" viewBox="0 -3 11 7" fill="none">
      <path d="m0 0 5.416 6.667L10.833 0H0z" fill={fillColor} />
    </svg>
  ),
  checkbox: (fillColor: string) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.75 2.25H5.25a3.003 3.003 0 0 0-3 3v13.5a3.003 3.003 0 0 0 3 3h13.5a3.003 3.003 0 0 0 3-3V5.25a3.003 3.003 0 0 0-3-3zm-1.676 6.482-6.3 7.5a.75.75 0 0 1-.562.268h-.013a.751.751 0 0 1-.557-.249l-2.7-3a.75.75 0 1 1 1.115-1.003l2.123 2.359 5.746-6.84a.75.75 0 0 1 1.148.965z"
        fill={fillColor}
      />
    </svg>
  ),
  checkbox_default: (fillColor: string) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.75 2.25H5.25a3.003 3.003 0 0 0-3 3v13.5a3.003 3.003 0 0 0 3 3h13.5a3.003 3.003 0 0 0 3-3V5.25a3.003 3.003 0 0 0-3-3zm-1.676 6.482-6.3 7.5a.75.75 0 0 1-.562.268h-.013a.751.751 0 0 1-.557-.249l-2.7-3a.75.75 0 1 1 1.115-1.003l2.123 2.359 5.746-6.84a.75.75 0 0 1 1.148.965z"
        fill={fillColor}
      />
    </svg>
  ),
  support: (fillColor: string) => (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#ebz1wzmvia)">
        <path
          d="M15 7.5a7.5 7.5 0 1 1-15 0 7.5 7.5 0 0 1 15 0zM7.7 2.48a3.917 3.917 0 0 0-3.524 1.928.363.363 0 0 0 .082.491l1.05.796a.363.363 0 0 0 .503-.064c.54-.685.911-1.083 1.734-1.083.617 0 1.382.4 1.382.997 0 .453-.374.686-.984 1.027-.712.4-1.653.896-1.653 2.138v.12a.363.363 0 0 0 .362.364h1.694a.363.363 0 0 0 .363-.364v-.04c0-.86 2.516-.896 2.516-3.226 0-1.753-1.819-3.084-3.525-3.084zm-.2 7.5a1.391 1.391 0 1 0 0 2.783 1.391 1.391 0 0 0 0-2.783z"
          fill={fillColor}
        />
      </g>
      <defs>
        <clipPath id="ebz1wzmvia">
          <path fill="#fff" d="M0 0h15v15H0z" />
        </clipPath>
      </defs>
    </svg>
  ),
  support_selected: (fillColor: string) => (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#492nju5x9a)">
        <path
          d="M15 7.5a7.5 7.5 0 1 1-15 0 7.5 7.5 0 0 1 15 0zM7.7 2.48a3.917 3.917 0 0 0-3.524 1.928.363.363 0 0 0 .082.491l1.05.796a.363.363 0 0 0 .503-.064c.54-.685.911-1.083 1.734-1.083.617 0 1.382.4 1.382.997 0 .453-.374.686-.984 1.027-.712.4-1.653.896-1.653 2.138v.12a.363.363 0 0 0 .362.364h1.694a.363.363 0 0 0 .363-.364v-.04c0-.86 2.516-.896 2.516-3.226 0-1.753-1.819-3.084-3.525-3.084zm-.2 7.5a1.391 1.391 0 1 0 0 2.783 1.391 1.391 0 0 0 0-2.783z"
          fill={fillColor}
        />
      </g>
      <defs>
        <clipPath id="492nju5x9a">
          <path fill={fillColor} d="M0 0h15v15H0z" />
        </clipPath>
      </defs>
    </svg>
  ),
  dataExplorer_selected: (fillColor: string) => (
    <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#6tmpee10xa)">
        <path
          d="M13.928.535A.536.536 0 0 0 13.393 0H11.25a.536.536 0 0 0-.536.536v1.607h3.215V.536zM2.14 4.285C2.056 7.428.116 8.11 0 12.456v1.473A1.072 1.072 0 0 0 1.071 15h3.215a1.072 1.072 0 0 0 1.071-1.072V8.572h1.071V3.216H3.21a1.081 1.081 0 0 0-1.069 1.07zm12.863 0a1.081 1.081 0 0 0-1.069-1.07h-3.22v5.357h1.072v5.357A1.071 1.071 0 0 0 12.857 15h3.215a1.072 1.072 0 0 0 1.071-1.072v-1.473c-.116-4.345-2.056-5.027-2.14-8.17zM5.893 0H3.75a.536.536 0 0 0-.535.535v1.608h3.214V.536A.536.536 0 0 0 5.893 0zM7.5 8.572h2.143V3.215H7.5v5.357z"
          fill={fillColor}
        />
      </g>
      <defs>
        <clipPath id="6tmpee10xa">
          <path fill={fillColor} d="M0 0h17.143v15H0z" />
        </clipPath>
      </defs>
    </svg>
  ),
  pin: (fillColor: string) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.737 10.36 1.82 7.445s.02-.625.419-1.168c.853-1.163 2.532-1.225 3.785-.983l1.973-1.942C7.56 1.887 10.092.197 10.092.197l5.73 5.73-.42.559c-.67.89-1.586 1.643-2.67 1.46l-2.074 2.012c.028.215.026.019.073.72.09 1.331-.4 2.703-1.645 3.338l-.466.227-2.999-2.999-4.557 4.56-.885-.886 4.558-4.557zm5.15-8.544c-.43.355-.885.906-.64 1.295l.307.45L6.437 6.68c-1.242-.316-2.716-.397-3.222.384l5.637 5.645a2.46 2.46 0 0 0 .17-.131c.767-.675.478-1.9.312-3.063l2.994-2.993s.918.586 1.835-.485L9.918 1.792c-.012.007-.022.016-.032.024z"
        fill={fillColor}
      />
    </svg>
  ),
  filter: (fillColor: string) => (
    <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 1.5A1.5 1.5 0 0 1 1.5 0h15a1.5 1.5 0 0 1 0 3h-15A1.5 1.5 0 0 1 0 1.5zM3 7.5A1.5 1.5 0 0 1 4.5 6h9a1.5 1.5 0 0 1 0 3h-9A1.5 1.5 0 0 1 3 7.5z"
        fill={fillColor}
      />
      <path stroke="#ADB1B1" strokeWidth="3" strokeLinecap="round" d="M7.5 13.5h3" />
    </svg>
  ),
  menu_hamburger: (fillColor: string) => (
    <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 1.5A1.5 1.5 0 0 1 1.5 0h15a1.5 1.5 0 0 1 0 3h-15A1.5 1.5 0 0 1 0 1.5zM0 7.5A1.5 1.5 0 0 1 1.5 6h15a1.5 1.5 0 0 1 0 3h-15A1.5 1.5 0 0 1 0 7.5zM0 13.5A1.5 1.5 0 0 1 1.5 12h15a1.5 1.5 0 0 1 0 3h-15A1.5 1.5 0 0 1 0 13.5z"
        fill={fillColor}
      />
    </svg>
  ),
  search: (fillColor: string) => (
    <svg width="15" height="15" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.38 12.347 10.624 9.59a5.092 5.092 0 0 0 1.02-3.064 5.125 5.125 0 0 0-5.118-5.119 5.125 5.125 0 0 0-5.12 5.12 5.125 5.125 0 0 0 5.12 5.118 5.092 5.092 0 0 0 3.063-1.02l2.757 2.756a.732.732 0 0 0 1.034-1.034zM2.87 6.527a3.656 3.656 0 1 1 7.312 0 3.656 3.656 0 0 1-7.313 0z"
        fill={fillColor}
      />
    </svg>
  ),
  icon_home: (fillColor: string) => (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill="#00ffff00" d="M0 0h15v15H0z" />
      <path
        d="M7.663 2.967a.234.234 0 0 0-.324 0L1.945 8.12a.234.234 0 0 0-.072.17v4.835a.937.937 0 0 0 .937.938h2.815a.469.469 0 0 0 .469-.469V9.61a.234.234 0 0 1 .234-.235h2.344a.234.234 0 0 1 .234.235v3.984a.469.469 0 0 0 .469.469h2.814a.938.938 0 0 0 .938-.938V8.29a.236.236 0 0 0-.073-.17L7.663 2.968z"
        fill={fillColor}
      />
      <path
        d="M14.383 7.154 12.19 5.057V1.876a.469.469 0 0 0-.469-.469h-1.406a.469.469 0 0 0-.469.469v.937L8.151 1.191A.912.912 0 0 0 7.5.938a.91.91 0 0 0-.65.253L.622 7.153a.478.478 0 0 0-.04.655.469.469 0 0 0 .68.032l6.078-5.81a.234.234 0 0 1 .324 0l6.08 5.81a.469.469 0 0 0 .662-.013.48.48 0 0 0-.022-.673z"
        fill={fillColor}
      />
    </svg>
  ),
  pencil: (fillColor: string) => (
    <svg width="15" height="23" viewBox="5 5 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9 18A9 9 0 1 1 9 0a9 9 0 0 1 0 18z" fill="#fff" transform="scale(1.7)" />
      <path
        d="m12.733 5.819-.44-.44a1.464 1.464 0 0 0-2.068 0L6.209 9.394l-.021.02a1.54 1.54 0 0 0-.392.482l-.817 2.496a.586.586 0 0 0 .738.74l2.497-.817c.185-.096.35-.23.481-.391l3.766-3.766.271-.27c.048-.048.092-.1.133-.155l-2.477-2.476 2.477 2.476a1.464 1.464 0 0 0-.133-1.913v-.001zM5.84 9.852l2.417 2.42-2.417-2.42z"
        fill="#fff"
        stroke={fillColor}
        strokeWidth="1"
        transform="scale(1.7)"
      />
    </svg>
  ),
  eye: () => (
    <svg
      viewBox="100 100 1000 750"
      focusable="false"
      data-icon="eye"
      width="2em"
      height="2em"
      fill="currentColor"
      aria-hidden="true"
    >
      <path d="M396 512a112 112 0 10224 0 112 112 0 10-224 0zm546.2-25.8C847.4 286.5 704.1 186 512 186c-192.2 0-335.4 100.5-430.2 300.3a60.3 60.3 0 000 51.5C176.6 737.5 319.9 838 512 838c192.2 0 335.4-100.5 430.2-300.3 7.7-16.2 7.7-35 0-51.5zM508 688c-97.2 0-176-78.8-176-176s78.8-176 176-176 176 78.8 176 176-78.8 176-176 176z"></path>
    </svg>
  ),
  caret_down: () => (
    <svg
      enableBackground="new 0 0 29 14"
      height="14px"
      id="Layer_1"
      version="1.1"
      viewBox="0 0 29 14"
      width="14px"
      xmlSpace="preserve"
      xmlns="http://www.w3.org/2000/svg"
    >
      <polygon fill="#5B5F5FFF" points="0.15,0 14.5,14.35 28.85,0 " />
    </svg>
  ),
  caret_up: () => (
    <svg
      enableBackground="new 0 0 29 14"
      height="14px"
      id="Layer_1"
      version="1.1"
      viewBox="0 0 29 14"
      width="14px"
      xmlSpace="preserve"
      xmlns="http://www.w3.org/2000/svg"
    >
      <polygon fill="#5B5F5FFF" points="0.15,14 14.5,-0.35 28.85,14 " />
    </svg>
  ),
  applications: (fillColor: string) => (
    <svg
      viewBox="64 64 896 896"
      focusable="false"
      data-icon="appstore"
      width="1em"
      height="1em"
      fill={fillColor}
      aria-hidden="true"
    >
      <path d="M464 144H160c-8.8 0-16 7.2-16 16v304c0 8.8 7.2 16 16 16h304c8.8 0 16-7.2 16-16V160c0-8.8-7.2-16-16-16zm-52 268H212V212h200v200zm452-268H560c-8.8 0-16 7.2-16 16v304c0 8.8 7.2 16 16 16h304c8.8 0 16-7.2 16-16V160c0-8.8-7.2-16-16-16zm-52 268H612V212h200v200zM464 544H160c-8.8 0-16 7.2-16 16v304c0 8.8 7.2 16 16 16h304c8.8 0 16-7.2 16-16V560c0-8.8-7.2-16-16-16zm-52 268H212V612h200v200zm452-268H560c-8.8 0-16 7.2-16 16v304c0 8.8 7.2 16 16 16h304c8.8 0 16-7.2 16-16V560c0-8.8-7.2-16-16-16zm-52 268H612V612h200v200z"></path>
    </svg>
  ),
  logout: (fillColor: string) => (
    <svg width="15" height="15" viewBox="14 9 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#0y69g5ckta)">
        <rect x="6" y="2" width="30" height="30" rx="12" fill="#fff" />
      </g>
      <path
        d="M28.874 17.399c.004-.009.012-.017.016-.025a.102.102 0 0 0 .017-.028.18.18 0 0 0 .016-.029c.004-.01.01-.02.017-.029l.012-.028a.28.28 0 0 0 .012-.033.074.074 0 0 0 .009-.032l.008-.033c.004-.012.004-.02.008-.029a.114.114 0 0 0 .004-.036c0-.008.004-.016.004-.029 0-.012.004-.044.004-.064 0-.022 0-.044-.004-.065 0-.008-.004-.016-.004-.029 0-.012-.004-.024-.004-.036 0-.01-.003-.02-.008-.028-.004-.012-.004-.02-.009-.033a.097.097 0 0 1-.008-.033l-.012-.032a.203.203 0 0 0-.012-.029.066.066 0 0 0-.017-.029.101.101 0 0 0-.016-.028l-.017-.029c-.004-.008-.012-.016-.016-.024a.17.17 0 0 1-.02-.029c-.009-.008-.013-.016-.021-.024-.008-.008-.004-.008-.008-.012l-2.092-2.308a.691.691 0 0 0-.751-.2.692.692 0 0 0-.465.62.684.684 0 0 0 .186.5l1.056 1.167h-8.836a.694.694 0 0 0-.692.688.686.686 0 0 0 .692.688h8.834l-1.055 1.167a.69.69 0 0 0 .516 1.15.685.685 0 0 0 .514-.227l2.092-2.312c.004-.004.008-.008.008-.012 0-.005.013-.017.02-.025a.09.09 0 0 1 .022-.041z"
        fill={fillColor}
      />
      <path
        d="M24.241 19.984a.658.658 0 0 0-.692.614v1.367a.873.873 0 0 1-.3.6.883.883 0 0 1-.638.212h-5.287a.883.883 0 0 1-.638-.213.873.873 0 0 1-.3-.599v-9.922a.873.873 0 0 1 .3-.6.883.883 0 0 1 .638-.212h5.284a.883.883 0 0 1 .638.212.873.873 0 0 1 .3.6v1.367a.692.692 0 0 0 .693.613.7.7 0 0 0 .692-.613v-1.367a2.18 2.18 0 0 0-.738-1.496 2.204 2.204 0 0 0-1.584-.543h-5.285a2.207 2.207 0 0 0-1.584.543c-.436.382-.702.92-.74 1.496v9.922c.038.576.304 1.114.74 1.496a2.206 2.206 0 0 0 1.584.543h5.284c.58.034 1.15-.161 1.585-.543.435-.382.701-.92.74-1.496v-1.367a.652.652 0 0 0-.692-.614z"
        fill={fillColor}
      />
      <defs>
        <filter
          id="0y69g5ckta"
          x=".5"
          y="0"
          width="41"
          height="41"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3.5" />
          <feGaussianBlur stdDeviation="2.75" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.02 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_2199_18742" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_2199_18742" result="shape" />
        </filter>
      </defs>
    </svg>
  ),
} as const;

export type IconNames = keyof typeof IconsDict;
