import { Col, Layout } from 'antd';
import { DocsPageHeroRow, PublicPageTitle } from '@/features/publicPages/PublicPage.style';
import {
  DocsSideMenu,
  DocsSider,
  DocsSiderContentWrapper,
  PublicHeroSection,
  PublicHeroText,
  StyledSkeleton,
} from '@/features/documentation/Documentation.style';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

import type { MenuProps } from 'antd';
import { PublicPageFooter } from '@/components/Footer/PublicPageFooter';
import airtable from '@/api/airtable/airtable';
import { composeMenuItems } from '@/components/Layout/helpers';
import { composePathToDocsRecord } from '@/features/documentation/helpers';
import isEmpty from 'lodash/isEmpty';

export type TDocItem = { Title: string; Category: string; AntdIconName: string };

const { Content } = Layout;

export const DocumentationLayout = () => {
  const navigate = useNavigate();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const { category = '', record = '' } = useParams();
  const [openKeys, setOpenKeys] = useState<string[]>([category]);
  const [sideNavigation, setSideNavigation] = useState<MenuProps['items']>([]);

  const handleSideMenuClick = ({ keyPath }: { keyPath: string[] }) => {
    const path = composePathToDocsRecord({ category: keyPath[1], record: keyPath[0] });
    navigate(path);
  };

  useEffect(() => {
    (async () => {
      try {
        const docs = await airtable<TDocItem>('documents')
          .select({
            view: 'Grid',
            fields: ['Title', 'Category', 'AntdIconName'],
          })
          .all();

        const categoriesWithDocs = composeMenuItems({ docs });
        setSideNavigation(Object.values(categoriesWithDocs));
      } catch (error) {
        console.log(error);
        setSideNavigation([]);
      }
    })();
  }, []);

  useEffect(() => {
    setOpenKeys((current) => [...current, category]);
  }, [category]);

  return (
    <>
      <DocsPageHeroRow>
        <Col span={24}>
          <PublicHeroSection>
            <PublicHeroText>
              <PublicPageTitle>Documentation</PublicPageTitle>
            </PublicHeroText>
          </PublicHeroSection>
        </Col>
      </DocsPageHeroRow>
      <Layout>
        <DocsSider width={266} collapsible collapsed={isCollapsed} onCollapse={setIsCollapsed}>
          <DocsSiderContentWrapper>
            {isEmpty(sideNavigation) ? (
              <StyledSkeleton active />
            ) : (
              <DocsSideMenu
                mode="inline"
                selectedKeys={[record, category]}
                openKeys={openKeys}
                defaultOpenKeys={[category]}
                items={sideNavigation}
                onClick={handleSideMenuClick}
                onOpenChange={setOpenKeys}
              />
            )}
          </DocsSiderContentWrapper>
        </DocsSider>
        <Layout>
          <Content>
            <Outlet />
          </Content>
          <PublicPageFooter />
        </Layout>
      </Layout>
    </>
  );
};
