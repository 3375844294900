import { HEADER_HEIGHT, HEADER_HEIGHT_MOBILE } from '@/components/Layout/PublicLayout.style';
import { Layout, Menu, Row, Skeleton } from 'antd';

import { FOOTER_SECTION_HEIGHT } from '@/features/publicPages/PublicPage.style';
import { FREEMAN_DARK_BLUE } from '@/styles/theme';
import { deviceSmallerThan } from '@/styles/mediaqueries';
import styled from 'styled-components';

const { Sider, Content } = Layout;

const DOCUMENT_MIN_HEIGHT = `calc(100vh - ${HEADER_HEIGHT}px - ${FOOTER_SECTION_HEIGHT}px)`;

export const ContentWrapper = styled.div`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    scroll-margin-top: ${HEADER_HEIGHT}px;

    ${deviceSmallerThan.tabletS} {
      scroll-margin-top: ${HEADER_HEIGHT_MOBILE}px;
    }
  }
`;

export const LoadingContentContainer = styled(Content)`
  margin: 0 16px;
  padding: 24px;
  min-height: ${DOCUMENT_MIN_HEIGHT};
`;

export const DocumentContainer = styled.div`
  margin: 0 16px;
  padding: 24px;
  min-height: ${DOCUMENT_MIN_HEIGHT};
`;

export const PublicHeroSection = styled.div`
  background-color: ${FREEMAN_DARK_BLUE};
  position: relative;
  height: 135px;
`;

export const PublicHeroText = styled.div`
  text-align: center;
  position: absolute;
  top: 50%;
  left: 70%;
  transform: translate(0%, -50%);

  ${deviceSmallerThan.laptopL} {
    left: 65%;
  }
  ${deviceSmallerThan.laptopML} {
    left: 55%;
  }
  ${deviceSmallerThan.laptopM} {
    left: 50%;
  }
  ${deviceSmallerThan.laptopS} {
    left: 42%;
  }
`;

export const DocHomepageWrapper = styled.div`
  min-height: ${DOCUMENT_MIN_HEIGHT};
  padding: 15px 0;

  .ant-timeline-item-tail {
    border-color: #1890ff;
  }
`;

export const DocHomepageCardsRow = styled(Row)`
  justify-content: space-between;

  ${deviceSmallerThan.tabletM} {
    flex-direction: column;
  }
`;

export const StyledSkeleton = styled(Skeleton)`
  height: 100%;
  background-color: white;
  padding: 15px;
`;

export const DocsSider = styled(Sider)`
  background-color: white;
`;

export const DocsSiderContentWrapper = styled.div`
  position: sticky;
  top: 115px;
`;

export const DocsSideMenu = styled(Menu)`
  height: 100%;
  border-right: 0;
`;
