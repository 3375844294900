import { ApplicationEventScope } from '@/api/applicationEventScope/types';
import { ApplicationOrganizationScope } from '@/api/applicationOrganizationScope/types';
import { ApplicationScope } from '@/api/applicationScope/types';
import { Event } from '@/api/events/types';
import { Organization } from '@/api/organizations/types';
import { Scope } from '@/api/scopes/types';

type TComposeScopeMapProps = {
  allUsedScopes?: Scope[];
  allUsedOrganizations?: Organization[];
  allUsedEvents?: Event[];
  applicationScopes?: ApplicationScope[];
  applicationOrganizationScopes?: ApplicationOrganizationScope[];
  applicationEventScopes?: ApplicationEventScope[];
};

type TScopeMapObjects = {
  allUsedScopesIdToNameMap: Record<string, string>;
  allUsedOrganizationsMap: Record<string, string>;
  allUsedEventsMap: Record<string, Event>;
  scopeIdToApplicationScopesMap: Record<string, ApplicationScope>;
  scopeIdToApplicationOrganizationScopesArrayMap: Record<string, ApplicationOrganizationScope[]>;
  scopeIdToApplicationEventScopesArrayMap: Record<string, ApplicationEventScope[]>;
};

export const composeApplicationScopeMapObjects = ({
  allUsedScopes = [],
  allUsedOrganizations = [],
  allUsedEvents = [],
  applicationScopes = [],
  applicationOrganizationScopes = [],
  applicationEventScopes = [],
}: TComposeScopeMapProps): TScopeMapObjects => {
  const allUsedScopesIdToNameMap = allUsedScopes.reduce<Record<string, string>>(
    (acc, scope) => ({ ...acc, [scope.id]: scope.name }),
    {}
  );

  const allUsedOrganizationsMap = allUsedOrganizations.reduce<Record<string, string>>(
    (acc, organization) => ({ ...acc, [organization.id]: organization.fullName }),
    {}
  );

  const allUsedEventsMap = allUsedEvents.reduce<Record<string, Event>>(
    (acc, event) => ({ ...acc, [event.id]: event }),
    {}
  );

  const scopeIdToApplicationScopesMap = applicationScopes.reduce<Record<string, ApplicationScope>>(
    (acc, appScope) => ({ ...acc, [appScope.scopeId]: appScope }),
    {}
  );

  const scopeIdToApplicationOrganizationScopesArrayMap = applicationOrganizationScopes.reduce<
    Record<string, ApplicationOrganizationScope[]>
  >(
    (acc, appOrgScope) => ({
      ...acc,
      [appOrgScope.scopeId]: [...(acc[appOrgScope.scopeId] || []), appOrgScope],
    }),
    {}
  );

  const scopeIdToApplicationEventScopesArrayMap = applicationEventScopes.reduce<
    Record<string, ApplicationEventScope[]>
  >(
    (acc, appEventScope) => ({
      ...acc,
      [appEventScope.scopeId]: [...(acc[appEventScope.scopeId] || []), appEventScope],
    }),
    {}
  );

  return {
    allUsedScopesIdToNameMap,
    allUsedOrganizationsMap,
    allUsedEventsMap,
    scopeIdToApplicationScopesMap,
    scopeIdToApplicationOrganizationScopesArrayMap,
    scopeIdToApplicationEventScopesArrayMap,
  };
};
