import { Contentcategory, ContentcategoriesWithMeta } from '@/api/contentcategories/types';
import { FilterModelType, filterParamsToQueryParams } from '@/api/helpers';
import { useInfiniteQuery, useQuery } from 'react-query';

import { ApiDataResponseAnatomy } from '@/api/api.types';
import { ExtractFnReturnType } from '@/lib/react-query';
import { axios } from '@/lib/axios';

export type FilterContentcategoriesQueryParams = Partial<
  {
    [property in keyof Contentcategory]: string | string[];
  } & { page: number; filterModel: FilterModelType }
>;

export const allContentcategories = async (
  searchParams: FilterContentcategoriesQueryParams = {}
) => {
  const params = filterParamsToQueryParams(searchParams);
  const response = await axios.get<
    ContentcategoriesWithMeta,
    ApiDataResponseAnatomy<Contentcategory>
  >(`/contentcategories/v2`, {
    params,
  });
  const { collection, _meta } = response;
  return { collection, meta: _meta };
};

export type QueryGetContentcategoriesFnType = typeof allContentcategories;

export const contentcategoryById = async (id: string) =>
  axios.get<Contentcategory, Contentcategory>(`/contentcategories/v2/${id}`);

type QueryGetContentcategoryFnType = typeof contentcategoryById;

export const useAllContentcategories = (config = {}) => {
  return useQuery<ExtractFnReturnType<QueryGetContentcategoriesFnType>>({
    queryKey: ['contentcategories'],
    queryFn: () => allContentcategories(),
    ...config,
  });
};

export const useSearchInfiniteContentcategories = (
  filterParams: FilterContentcategoriesQueryParams = {},
  config = {}
) => {
  return useInfiniteQuery<ExtractFnReturnType<QueryGetContentcategoriesFnType>>({
    queryKey: ['contentcategories', filterParams],
    queryFn: ({ pageParam = 1 }) => allContentcategories({ ...filterParams, page: pageParam }),
    getPreviousPageParam: (firstPageData, allPagesData) => {
      const lastPageData = allPagesData.at(-1);
      if (!lastPageData?.meta) {
        // this should never happen unless the API returns an error
        return undefined;
      }
      const previousPage = lastPageData.meta.currentPageNumber - 1;

      if (previousPage <= 1) {
        // no previous page
        return undefined;
      }
      return previousPage;
    },
    getNextPageParam: (lastPageData) => {
      if (!lastPageData?.meta) {
        // this should never happen unless the API returns an error
        return undefined;
      }
      const nextPage = lastPageData.meta.currentPageNumber + 1;
      if (nextPage > lastPageData.meta.totalNumberOfPages) {
        // no more pages
        return undefined;
      }
      return nextPage;
    },
    ...config,
  });
};

export const useSearchContentcategories = (
  filterParams: FilterContentcategoriesQueryParams,
  config = {}
) => {
  return useQuery<ExtractFnReturnType<QueryGetContentcategoriesFnType>>({
    queryKey: ['contentcategories', filterParams],
    queryFn: () => allContentcategories(filterParams),
    ...config,
  });
};

export const useContentcategoryById = (id: string, config = {}) => {
  return useQuery<ExtractFnReturnType<QueryGetContentcategoryFnType>>({
    queryKey: ['contentcategories', id],
    queryFn: () => contentcategoryById(id),
    staleTime: 5 * (60 * 1000), // 5 minutes
    ...config,
  });
};
