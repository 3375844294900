import { CreateEventPayload, Event } from '../types';
import { MutationConfig, queryClient } from '@/lib/react-query';

import { addNotification } from '@/lib/notifications';
import { axios } from '@/lib/axios';
import { useMutation } from 'react-query';

export const createEvent = async (data: CreateEventPayload): Promise<Event> =>
  axios.post<any, Event>('/events/v2', data);

export type UseCreateEventOptions = {
  config?: MutationConfig<typeof createEvent>;
  data?: CreateEventPayload;
};

export const useCreateEvent = ({ config }: UseCreateEventOptions = {}) => {
  return useMutation({
    onMutate: async (newEvent) => {
      await queryClient.cancelQueries(['events']);
      const previousEvents = queryClient.getQueryData<Event[]>(['events']);
      queryClient.setQueryData(['events'], [...(previousEvents || []), newEvent]);
      return { previousEvents };
    },
    onError: (error, __, context: any) => {
      if (context?.previousEvents) {
        queryClient.setQueryData(['events'], context.previousEvents);
      }
      addNotification({
        type: 'error',
        message: error.message,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['events']);
      addNotification({
        type: 'success',
        message: 'New event created successfully!',
      });
    },
    ...config,
    mutationFn: createEvent,
  });
};
