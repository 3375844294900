import { User, UserWithMeta } from '@/api/users/types';

import { ApiDataResponseAnatomy } from '@/api/api.types';
import { ExtractFnReturnType } from '@/lib/react-query';
import { axios } from '@/lib/axios';
import { filterParamsToQueryParams } from '@/api/helpers';
import { useQuery } from 'react-query';
import { IntegrationApiService } from '@/api/integrations';

export type FilterUsersQueryParams = Partial<
  {
    [property in keyof User]: string;
  } & { page: number; scopeId: string }
>;

export const allUsers = async (searchParams: FilterUsersQueryParams = {}) => {
  const params = filterParamsToQueryParams(searchParams);

  const response = await axios.get<UserWithMeta, ApiDataResponseAnatomy<User>>(`/users/v2`, {
    params,
  });

  const { collection, _meta } = response;
  return { collection, meta: _meta };
};

export const allUsersWithIntegrationName = async (searchParams: FilterUsersQueryParams = {}) => {
  let users = await allUsers(searchParams);
  await Promise.all(
    users.collection.map(async (user) => {
      if (user.integrationId) {
        user.integration = await IntegrationApiService.integrationById(user.integrationId);
      }
      return user;
    })
  );
  const { collection, meta } = users;
  return { collection, meta };
};

export type QueryGetUsersFnType = typeof allUsers;

export const userById = async (id: string): Promise<User> => {
  return axios.get<User, User>(`/users/v2/${id}`);
};

type QueryGetUserFnType = typeof userById;

export const useAllUsers = (config = {}) => {
  return useQuery<ExtractFnReturnType<QueryGetUsersFnType>>({
    queryKey: ['users'],
    queryFn: () => allUsers(),
    ...config,
  });
};

export const useSearchUsers = (filterParams: FilterUsersQueryParams, config = {}) => {
  return useQuery<ExtractFnReturnType<QueryGetUsersFnType>>({
    queryKey: ['users', filterParams],
    queryFn: () => allUsers(filterParams),
    ...config,
  });
};

export const useUserById = (id: string, config = {}) => {
  return useQuery<ExtractFnReturnType<QueryGetUserFnType>>({
    queryKey: ['users', id],
    queryFn: () => userById(id),
    ...config,
  });
};
