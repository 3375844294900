import { Content, Layout } from './MainLayout.style';

import { Footer } from '@/components/Footer/Footer';
import { LoadingContentSkeleton } from '@/components/Layout/LoadingContentSkeleton';
import { LoadingHeaderNavigation } from '@/components/Navigation/HeaderNavigation/LoadingHeaderNavigation';
import { LoadingSideNavigation } from '@/components/Navigation/SideNavigation/LoadingSideNavigation';

export const LoadingMainLayout = () => {
  return (
    <Layout>
      <Layout>
        <LoadingSideNavigation />
        <Layout>
          <LoadingHeaderNavigation />
          <Content>
            <LoadingContentSkeleton />
          </Content>
        </Layout>
      </Layout>
      <Footer />
    </Layout>
  );
};
