import {
  HeaderLinkContainer,
  LoginButtonContainer,
  PublicContentContainer,
  PublicHeader,
  StyledMenu,
} from '@/components/Layout/PublicLayout.style';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { Button } from '@/components/Button';
import { FreemanLogoImg } from '@/components/Layout/FreemanLogoImg';
import { Layout } from 'antd';
import type { MenuProps } from 'antd';
import { useCurrentUser } from '@/providers/CurrentUserProvider';
import { useEffect } from 'react';

const topNavigationItems: MenuProps['items'] = [
  {
    key: 'home',
    label: `Home`,
  },
  {
    key: 'partners',
    label: `Partners`,
  },
  {
    key: 'docs',
    label: `Documentation`,
  },
  {
    key: 'api-reference',
    label: `API Reference`,
  },
  {
    key: 'release-notes',
    label: `Release Notes`,
  },
];

export const PublicLayout = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { isAuthenticated, loginWithRedirect } = useCurrentUser();

  const activeRoute = pathname.split('/')[1];

  const handleTopNavClick = ({ key }: { key: string }) => {
    navigate(`/${key}`);
  };

  const handleLogoClick = () => {
    navigate('/');
  };

  const handleLoginClick = () => {
    return isAuthenticated ? navigate('/auth/profile') : loginWithRedirect();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Layout>
      <PublicHeader>
        <Button id="freeman-public-section-logo" type="unstyled" onClick={handleLogoClick}>
          <FreemanLogoImg />
        </Button>
        <HeaderLinkContainer>
          <LoginButtonContainer>
            <Button id="login-button" type="primary" onClick={handleLoginClick}>
              {isAuthenticated ? 'Fuzion Portal' : 'Portal Login'}
            </Button>
          </LoginButtonContainer>
          <StyledMenu
            mode="horizontal"
            selectedKeys={activeRoute ? [activeRoute] : ['home']}
            items={topNavigationItems}
            onClick={handleTopNavClick}
          />
        </HeaderLinkContainer>
      </PublicHeader>
      <Layout>
        <PublicContentContainer>
          <Outlet />
        </PublicContentContainer>
      </Layout>
    </Layout>
  );
};
