import { useState } from 'react';
import { List } from 'antd';

import {
  TagFilterWrapper,
  StyledTagFilter,
  TagHeader,
  TagHeaderWrapper,
  ClearTagsButton,
} from '@/features/updates/UpdatePage.style';
import { tagData } from '@/api/contenttopics/types';

type TagFilterProps = {
  data: tagData[];
  handleTagChange: (tagIdFilters: string[]) => void;
};

export const TagFilters = ({ data, handleTagChange }: TagFilterProps) => {
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [tagIds, setTagIds] = useState<string[]>([]);

  const handleChange = (tag: tagData, checked: boolean) => {
    const nextSelectedTags = checked
      ? [...selectedTags, tag.name]
      : selectedTags.filter((t) => t !== tag.name);
    const nextTagIds = checked
      ? [...tagIds, tag.tagId]
      : tagIds.filter((tagId) => tagId !== tag.tagId);
    setSelectedTags(nextSelectedTags);
    setTagIds(nextTagIds);
    handleTagChange(nextTagIds);
  };

  const onClear = () => {
    setTagIds([]);
    setSelectedTags([]);
    handleTagChange([]);
  };

  return (
    <TagFilterWrapper>
      <TagHeaderWrapper>
        <TagHeader>Tags</TagHeader>
        <ClearTagsButton onClick={onClear}>Clear</ClearTagsButton>
      </TagHeaderWrapper>
      <List
        dataSource={data}
        split={false}
        renderItem={(tag) => (
          <List.Item>
            <StyledTagFilter
              key={tag?.name}
              checked={selectedTags?.includes(tag?.name)}
              onChange={(checked) => handleChange(tag, checked)}
            >
              {tag?.name}
            </StyledTagFilter>
          </List.Item>
        )}
      />
    </TagFilterWrapper>
  );
};
