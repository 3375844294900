import { Application } from '@/api/applications/types';
import { Nullable } from '@/api/typeHelpers';

type TComposeUpdatedAppDataProps = {
  addedPermissions: any;
  removedPermissions: any;
  application: Application;
};

type TUpdatedAppData =
  | {
      hasChangesInAppData: false;
      updatedAppData: Record<string, never>;
    }
  | {
      hasChangesInAppData: true;
      updatedAppData: {
        name: string;
        description: string;
        integrationId?: Nullable<string>;
        logoUrl?: Nullable<string>;
      };
    };

export const composeUpdatedAppData = ({
  addedPermissions,
  removedPermissions,
  application,
}: TComposeUpdatedAppDataProps): TUpdatedAppData => {
  const hasChangesInAppData =
    Object.hasOwn(addedPermissions, 'name') ||
    Object.hasOwn(addedPermissions, 'description') ||
    Object.hasOwn(addedPermissions, 'integration') ||
    Object.hasOwn(addedPermissions, 'logoUrl') ||
    Object.hasOwn(removedPermissions, 'integration') ||
    Object.hasOwn(removedPermissions, 'logoUrl');

  if (!hasChangesInAppData) {
    return { hasChangesInAppData: false, updatedAppData: {} };
  }

  const updatedAppData: {
    name: string;
    description: string;
    integrationId?: Nullable<string>;
    logoUrl?: string;
  } = {
    name: addedPermissions.name || application.name,
    description: addedPermissions.description || application.description,
    logoUrl: addedPermissions.logoUrl || '',
  };

  if (
    Object.hasOwn(removedPermissions, 'integration') &&
    !Object.hasOwn(addedPermissions, 'integration')
  ) {
    // if existing integration has been removed but new integration has not been added
    updatedAppData.integrationId = null;
  }
  if (Object.hasOwn(addedPermissions, 'integration')) {
    updatedAppData.integrationId = addedPermissions.integration.id;
  }

  return {
    hasChangesInAppData,
    updatedAppData,
  };
};

type TcomposeUniqueSystemLevelScopeIdsProps = {
  addedPermissions: {
    systemLevelPermissions?: { id: string }[];
  };
  removedPermissions: {
    systemLevelPermissions?: { id: string }[];
  };
};

export const composeUniqueSystemLevelScopeIds = ({
  addedPermissions,
  removedPermissions,
}: TcomposeUniqueSystemLevelScopeIdsProps) => {
  const uniqueAddedSystemLevelScopeIds = [
    ...new Set([...(addedPermissions.systemLevelPermissions ?? [])].map((scope) => scope.id)),
  ];
  const uniqueRemovedSystemLevelScopeIds = [
    ...new Set([...(removedPermissions.systemLevelPermissions ?? [])].map((scope) => scope.id)),
  ];
  return { uniqueAddedSystemLevelScopeIds, uniqueRemovedSystemLevelScopeIds };
};
