import { ButtonProps as AntButtonProps } from 'antd';
import { ButtonType } from 'antd/lib/button';
import { FunctionComponent } from 'react';
import { StyledButton } from '@/components/Button/Button.style';

type BaseButtonProps = Omit<AntButtonProps, 'type'>;

export interface IButtonProps extends BaseButtonProps {
  type?:
    | ButtonType
    | 'secondary'
    | 'danger'
    | 'unstyled'
    | 'editOrg'
    | 'tab'
    | 'outlined-animated'
    | 'link-end'
    | 'public-pages-orange'
    | 'public-pages-light';
}

export const Button: FunctionComponent<IButtonProps> = ({
  children,
  type = 'primary',
  onClick,
  ...rest
}) => {
  return (
    <StyledButton type={type} {...rest} onClick={onClick}>
      {children}
    </StyledButton>
  );
};
