import { Contenttag, ContenttagsWithMeta } from '@/api/contenttags/types';
import { FilterModelType, filterParamsToQueryParams } from '@/api/helpers';
import { useInfiniteQuery, useQuery } from 'react-query';

import { ApiDataResponseAnatomy } from '@/api/api.types';
import { ExtractFnReturnType } from '@/lib/react-query';
import { axios } from '@/lib/axios';

export type FilterContenttagsQueryParams = Partial<
  {
    [property in keyof Contenttag]: string | string[];
  } & { page: number; filterModel: FilterModelType }
>;

export const allContenttags = async (searchParams: FilterContenttagsQueryParams = {}) => {
  const params = filterParamsToQueryParams(searchParams);
  const response = await axios.get<ContenttagsWithMeta, ApiDataResponseAnatomy<Contenttag>>(
    `/contenttags/v2`,
    {
      params,
    }
  );
  const { collection, _meta } = response;
  return { collection, meta: _meta };
};

export type QueryGetContenttagsFnType = typeof allContenttags;

export const contenttagById = async (id: string) =>
  axios.get<Contenttag, Contenttag>(`/contenttags/v2/${id}`);

type QueryGetContenttagFnType = typeof contenttagById;

export const useAllContenttags = (config = {}) => {
  return useQuery<ExtractFnReturnType<QueryGetContenttagsFnType>>({
    queryKey: ['contenttags'],
    queryFn: () => allContenttags(),
    ...config,
  });
};

export const useSearchInfiniteContenttags = (
  filterParams: FilterContenttagsQueryParams = {},
  config = {}
) => {
  return useInfiniteQuery<ExtractFnReturnType<QueryGetContenttagsFnType>>({
    queryKey: ['contenttags', filterParams],
    queryFn: ({ pageParam = 1 }) => allContenttags({ ...filterParams, page: pageParam }),
    getPreviousPageParam: (firstPageData, allPagesData) => {
      const lastPageData = allPagesData.at(-1);
      if (!lastPageData?.meta) {
        // this should never happen unless the API returns an error
        return undefined;
      }
      const previousPage = lastPageData.meta.currentPageNumber - 1;

      if (previousPage <= 1) {
        // no previous page
        return undefined;
      }
      return previousPage;
    },
    getNextPageParam: (lastPageData) => {
      if (!lastPageData?.meta) {
        // this should never happen unless the API returns an error
        return undefined;
      }
      const nextPage = lastPageData.meta.currentPageNumber + 1;
      if (nextPage > lastPageData.meta.totalNumberOfPages) {
        // no more pages
        return undefined;
      }
      return nextPage;
    },
    ...config,
  });
};

export const useSearchContenttags = (filterParams: FilterContenttagsQueryParams, config = {}) => {
  return useQuery<ExtractFnReturnType<QueryGetContenttagsFnType>>({
    queryKey: ['contenttags', filterParams],
    queryFn: () => allContenttags(filterParams),
    ...config,
  });
};

export const useContenttagById = (id: string, config = {}) => {
  return useQuery<ExtractFnReturnType<QueryGetContenttagFnType>>({
    queryKey: ['contenttags', id],
    queryFn: () => contenttagById(id),
    staleTime: 5 * (60 * 1000), // 5 minutes
    ...config,
  });
};
