export const BRAND_BLUE = `#00bdf6`;
export const SUCCESS_GREEN = `#52c41a`;
export const FREEMAN_DARK_BLUE = `#001F5F`;
export const BRAND_BLUE_OPACITY = `rgba(0, 189, 246, 0.25)`;
export const TITLE_GRAY = 'rgba(0, 0, 0, 0.65)';

export const WHITE = `#fff`;
export const BLACK = `#000`;
export const RED = `#dc395f`;

export const FOOTER_COPYRIGHT_GRAY = `#919191`;
export const GRAY = `#adb1b1`;
export const TYPOGRAPHY_GRAY = '#000000d9';
export const BACKGROUND_GRAY = `#f0f2f5`;
export const DARK_GRAY = `#4f5859`;
export const LIGHT_GRAY = `#F8F9FA`;
export const MODAL_BG_GRAY = `#eaeaea`;
export const MODAL_BORDER_GRAY = `#e0e1e2`;
export const MENU_ITEM_GRAY = `#a0aec0`;
export const TABLE_BORDER_GRAY = `#babfc7`;

export const STEP_CONTENT_BG = '#fafafa';
export const STEP_CONTENT_BORDER = '#e9e9e9';

export const PUBLIC_PAGE_ELEMENT_ORANGE = '#FFB500';
export const PUBLIC_PAGE_TEXT_ORANGE = '#FFAC00';
export const PUBLIC_PAGE_ELEMENT_DARK_BLUE = '#001D54';
export const PUBLIC_PAGE_ELEMENT_FADED_BLUE = '#193465';

const xs = `4px`;
const sm = `8px`;
const md = `32px`;

export const SPACING = {
  xs,
  sm,
  md,
};

export const COLORS = {
  BRAND_BLUE,
  FREEMAN_DARK_BLUE,
  BRAND_BLUE_OPACITY,
  TITLE_GRAY,
  SUCCESS_GREEN,
  WHITE,
  BLACK,
  FOOTER_COPYRIGHT_GRAY,
  GRAY,
  BACKGROUND_GRAY,
  DARK_GRAY,
  LIGHT_GRAY,
  MODAL_BG_GRAY,
  MODAL_BORDER_GRAY,
  MENU_ITEM_GRAY,
  RED,
  STEP_CONTENT_BG,
  STEP_CONTENT_BORDER,
  PUBLIC_PAGE_ELEMENT_ORANGE,
  PUBLIC_PAGE_TEXT_ORANGE,
  PUBLIC_PAGE_ELEMENT_DARK_BLUE,
  PUBLIC_PAGE_ELEMENT_FADED_BLUE,
};

export type Theme = {
  colors: {
    primary: string;
    secondary: string;
    danger?: string;
  };
  spacing: {
    xs: string;
    sm: string;
    md: string;
  };
};

export const theme: Theme = {
  colors: {
    primary: BRAND_BLUE,
    secondary: DARK_GRAY,
    danger: RED,
  },
  spacing: {
    xs,
    sm,
    md,
  },
};
