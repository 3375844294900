import { MutationConfig, queryClient } from '@/lib/react-query';

import { Event } from '@/api/events/types';
import { addNotification } from '@/lib/notifications';
import { axios } from '@/lib/axios';
import { useMutation } from 'react-query';

export const deleteEvent = async (id: string): Promise<Event> => {
  return axios.delete<any, Event>(`/events/v2/${id}`);
};

export type UseDeleteEventOptions = {
  config?: MutationConfig<typeof deleteEvent>;
};

export const useDeleteEvent = ({ config }: UseDeleteEventOptions = {}) => {
  return useMutation({
    onMutate: async (deletedEventId: string) => {
      await queryClient.cancelQueries(['events']);
      const previousEvents = queryClient.getQueryData<Event[]>(['events']);
      queryClient.setQueryData(
        ['events'],
        previousEvents?.filter((event) => event.id !== deletedEventId)
      );

      return { previousEvents };
    },
    onError: (error, __, context: any) => {
      if (context?.previousEvents) {
        queryClient.setQueryData(['events'], context.previousEvents);
      }
      addNotification({
        type: 'error',
        message: error.message,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['events']);
      addNotification({
        type: 'success',
        message: `You have successfully deleted the event!`,
      });
    },
    ...config,
    mutationFn: deleteEvent,
  });
};
