import logoFreeman from '@/assets/logo-byFreeman.png';
import logoFreemanLight from '@/assets/logo-byFreeman-light.png';
import logoInline from '@/assets/logo-inline.svg';
import logoStacked from '@/assets/logo-stacked.svg';

type LogoSectionProps = {
  type?: 'inline' | 'stacked' | 'freeman' | 'freeman-light';
};

const typeToImageMap = {
  inline: logoInline,
  stacked: logoStacked,
  freeman: logoFreeman,
  'freeman-light': logoFreemanLight,
};

export const LogoImg = ({ type = 'freeman' }: LogoSectionProps) => {
  return <img src={typeToImageMap[type]} alt="Fuzion logo" style={{ minHeight: '90px' }} />;
};
