import { Navigate, Outlet } from 'react-router-dom';

import { LoadingMainLayout } from '@/components/Layout/LoadingMainLayout';
import { MainLayout } from '@/components/Layout';
import { lazyImport } from '@/utils/lazyImport';
import { withAuthenticationRequired } from '@auth0/auth0-react';

const { ApplicationsTable } = lazyImport(
  () => import('@/features/applications/ApplicationsTable'),
  'ApplicationsTable'
);
const { Events } = lazyImport(() => import('@/features/events/Events'), 'Events');
const { IntegrationsTable } = lazyImport(
  () => import('@/features/integrations/IntegrationsTable'),
  'IntegrationsTable'
);
const { SessionFormatsTable } = lazyImport(
  () => import('@/features/sessionFormats/SessionFormatsTable'),
  'SessionFormatsTable'
);
const { SubsessionFormatsTable } = lazyImport(
  () => import('@/features/subsessionFormats/SubsessionFormatsTable'),
  'SubsessionFormatsTable'
);
const { PosterFormatsTable } = lazyImport(
  () => import('@/features/posterFormats/PosterFormatsTable'),
  'PosterFormatsTable'
);
const { SocialMediaTypesTable } = lazyImport(
  () => import('@/features/socialMediaTypes/SocialMediaTypesTable'),
  'SocialMediaTypesTable'
);
const { ContentTopics } = lazyImport(
  () => import('@/features/contentTopics/ContentTopics'),
  'ContentTopics'
);
const { ContentCategories } = lazyImport(
  () => import('@/features/contentCategories/ContentCategories'),
  'ContentCategories'
);
const { ContentTags } = lazyImport(
  () => import('@/features/contentTags/ContentTags'),
  'ContentTags'
);
const { OrganizationsTable } = lazyImport(
  () => import('@/features/organizations/OrganizationsTable'),
  'OrganizationsTable'
);
const { SingleEvent } = lazyImport(() => import('@/features/events/SingleEvent'), 'SingleEvent');
const { SingleOrganization } = lazyImport(
  () => import('@/features/organizations/SingleOrganization'),
  'SingleOrganization'
);
const { UsersTable } = lazyImport(() => import('@/features/users/UsersTable'), 'UsersTable');
const { WebhooklogsTable } = lazyImport(
  () => import('@/features/webhooklogs/WebhookLogsTable'),
  'WebhooklogsTable'
);
const { AuthProfile } = lazyImport(() => import('@/features/auth0/AuthProfile'), 'AuthProfile');
const { CreateUserStepFlow } = lazyImport(
  () => import('@/features/auth0/CreateUserStepFlow'),
  'CreateUserStepFlow'
);
const { UpdateUserStepFlow } = lazyImport(
  () => import('@/features/auth0/UpdateUserStepFlow'),
  'UpdateUserStepFlow'
);
const { UpdateClientStepFlow } = lazyImport(
  () => import('@/features/auth0/UpdateClientStepFlow'),
  'UpdateClientStepFlow'
);
const { CreateClientStepFlow } = lazyImport(
  () => import('@/features/auth0/CreateClientStepFlow'),
  'CreateClientStepFlow'
);
const { TechnicalSupport } = lazyImport(
  () => import('@/features/technicalSupport/TechnicalSupport'),
  'TechnicalSupport'
);

const Auth0PrivateRoute = withAuthenticationRequired(MainLayout, {
  // Show a message while the user waits to be redirected to the login page.
  onRedirecting: LoadingMainLayout,
});

export const protectedRoutes = [
  {
    path: '/app',
    element: <Auth0PrivateRoute />,
    children: [
      { path: '', index: true, element: <AuthProfile /> },
      {
        path: 'events',
        element: <Outlet />,
        children: [
          { path: '', element: <Events /> },
          { path: ':eventId', element: <SingleEvent /> },
          { path: ':eventId/:tab', element: <SingleEvent /> },
          {
            path: ':eventId/webhook-logs/:subscriptionId',
            element: <WebhooklogsTable />,
          },
        ],
      },
      {
        path: 'organizations',
        element: <Outlet />,
        children: [
          { path: '', element: <OrganizationsTable /> },
          { path: 'users', element: <OrganizationsTable /> },
          { path: ':organizationId', element: <SingleOrganization /> },
        ],
      },
      { path: 'integrations', element: <IntegrationsTable /> },
      { path: 'session_formats', element: <SessionFormatsTable /> },
      { path: 'subsession_formats', element: <SubsessionFormatsTable /> },
      { path: 'poster_formats', element: <PosterFormatsTable /> },
      { path: 'social_media_types', element: <SocialMediaTypesTable /> },
      { path: 'content_topics', element: <ContentTopics /> },
      { path: 'content_categories', element: <ContentCategories /> },
      { path: 'content_tags', element: <ContentTags /> },
      {
        path: 'applications',
        element: <ApplicationsTable />,
      },
      {
        path: 'users',
        element: <UsersTable />,
      },
      { path: 'technical_support', element: <TechnicalSupport /> },

      { path: '*', element: <Navigate to="/" /> },
    ],
  },
  {
    path: '/auth',
    element: <Auth0PrivateRoute />,
    children: [
      { path: '', index: true, element: <AuthProfile /> },
      { path: 'profile', element: <AuthProfile /> },
      { path: 'create-user', element: <CreateUserStepFlow /> },
      { path: 'update-user', element: <UpdateUserStepFlow /> },
      { path: 'update-application', element: <UpdateClientStepFlow /> },
      { path: 'create-application', element: <CreateClientStepFlow /> },
      { path: '*', element: <Navigate to="/" /> },
    ],
  },
];
