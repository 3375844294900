import { MutationConfig, queryClient } from '@/lib/react-query';

import { EventScopeUser } from '@/api/eventScopeUser/types';
import { addNotification } from '@/lib/notifications';
import { axios } from '@/lib/axios';
import { useMutation } from 'react-query';

export const deleteEventScopeUser = async (id: string): Promise<EventScopeUser> => {
  return axios.delete<any, EventScopeUser>(`/event-scope-user/v2/${id}`);
};

export type UseDeleteEventScopeUserOptions = {
  config?: MutationConfig<typeof deleteEventScopeUser>;
};

export const useDeleteEventScopeUser = ({ config }: UseDeleteEventScopeUserOptions = {}) => {
  return useMutation({
    onMutate: async (deletedEventScopeUserId: string) => {
      await queryClient.cancelQueries(['event-scope-user']);
      const previousEventScopeUsers = queryClient.getQueryData<EventScopeUser[]>([
        'event-scope-user',
      ]);
      queryClient.setQueryData(
        ['event-scope-user'],
        previousEventScopeUsers?.filter(
          (eventScopeUser) => eventScopeUser.id !== deletedEventScopeUserId
        )
      );

      return { previousEventScopeUsers };
    },
    onError: (error, __, context: any) => {
      if (context?.previousEventScopeUsers) {
        queryClient.setQueryData(['event-scope-user'], context.previousEventScopeUsers);
      }
      addNotification({
        type: 'error',
        message: error.message,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['event-scope-user']);
    },
    ...config,
    mutationFn: deleteEventScopeUser,
  });
};
