export enum ScopeLevel {
  Top = 'top',
  SingleOrganization = 'single organization',
  AnyOrganization = 'any organization',
  SingleEvent = 'single event',
}

export enum Actions {
  AddEvent = 'Add Event',
  ReadEvent = 'Read Event',
  WriteEvent = 'Write Event',
  UpdateEvent = 'Update Event',
  DeleteEvent = 'Delete Event',
  AddOrganization = 'Add Organization',
  ReadOrganizations = 'Read Organizations',
  ReadSingleOrganization = 'Read Single Organization',
  UpdateOrganization = 'Update Organization',
  DeleteOrganization = 'Delete Organization',
  ReadApplication = 'Read Application',
  CreateApplication = 'Create Application',
  UpdateApplication = 'Update Application',
  DeleteApplication = 'Delete Application',
  ReadUser = 'Read User',
  CreateUser = 'Create User',
  UpdateUser = 'Update User',
  DeleteUser = 'Delete User',
  ReadPresentationData = 'Read Presentation Data',
  DeletePresentationData = 'Delete Presentation Data',
  WritePresentationData = 'Write Presentation Data',
  ReadRegistrationData = 'Read Registration Data',
  DeleteRegistrationData = 'Delete Registration Data',
  WriteRegistrationData = 'Write Registration Data',
  AddWebhooksubscription = 'Add Webhook Subscription',
  UpdateWebhooksubscription = 'Update Webhook Subscription',
  DeleteWebhooksubscription = 'Delete Webhook Subscription',
  BackToWebhooksubscription = 'Back to Subscription',
  ReadWebhooks = 'Read Webhooks',
  CreateSocialmediatype = 'Create Social Media Type',
  ReadSocialmediatype = 'Read Social Media Type',
  UpdateSocialmediatype = 'Update Social Media Type',
  DeleteSocialmediatype = 'Delete Social Media Type',
  ReadExhibitorData = 'Read Exhibitor Data',
  DeleteExhibitorData = 'Delete Exhibitor Data',
  WriteExhibitorData = 'Write Exhibitor Data',
  ReadConfigManagement = 'Read Config Management',
  WriteConfigManagement = 'Write Config Management',
  DeleteConfigManagement = 'Delete Config Management',
  CreateSessionformat = 'Create Session Format',
  ReadSessionformat = 'Read Session Format',
  UpdateSessionformat = 'Update Session Format',
  DeleteSessionformat = 'Delete Session Format',
  CreateSubsessionformat = 'Create Subsession Format',
  ReadSubsessionformat = 'Read Subsession Format',
  UpdateSubsessionformat = 'Update Subsession Format',
  DeleteSubsessionformat = 'Delete Subsession Format',
  CreatePosterformat = 'Create Poster Format',
  ReadPosterformat = 'Read Poster Format',
  UpdatePosterformat = 'Update Poster Format',
  DeletePosterformat = 'Delete Poster Format',
  ReadIntegrations = 'Read Integrations',
  AddIntegration = 'Add Integration',
  UpdateIntegration = 'Update Integration',
  DeleteIntegration = 'Delete Integration',
  CreateContenttopic = 'Create Topic',
  UpdateContenttopic = 'Update Topic',
  DeleteContenttopic = 'Delete Topic',
  CreateContentcategory = 'Create Category',
  UpdateContentcategory = 'Update Category',
  DeleteContentcategory = 'Delete Category',
  CreateContenttag = 'Create Tag',
  UpdateContenttag = 'Update Tag',
  DeleteContenttag = 'Delete Tag',
  ReadContentManagement = 'Read Content Management',
  AddContentManagement = 'Add Content Management',
  UpdateContentManagement = 'Update Content Management',
  DeleteContentManagement = 'Delete Content Management',
}

export enum ActionConditions {
  CannotBePerformedOnCurrentUser = 'Cannot Be Performed On CurrentUser',
  AllowedToReadAtLeastOneOrganization = 'Allowed To Read At Least One Organization',
}
