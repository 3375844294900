import { Card, Tag } from 'antd';
import {
  PartnerCardTagsContainer,
  StyledPartnerCard,
} from '@/features/publicPages/PublicPage.style';

import { Record } from 'airtable';
import { TPartner } from '@/features/publicPages/types';

const { Meta } = Card;

type PartnerCardProps = {
  partner: Record<TPartner>;
};

export const PartnerCard = ({ partner }: PartnerCardProps) => {
  return (
    <StyledPartnerCard
      hoverable
      bodyStyle={{ padding: 0 }}
      cover={<img alt={partner.get('Name')} src={partner.get('LogoUrl')} />}
    >
      <Meta
        title={partner.get('Name')}
        description={
          <a href={partner.get('Url')} target="_blank" rel="noreferrer">
            {partner.get('Url')}
          </a>
        }
      />
      <PartnerCardTagsContainer>
        {partner.get('Tags')?.map((tag) => (
          <Tag key={tag} color="blue">
            {tag}
          </Tag>
        ))}
      </PartnerCardTagsContainer>
    </StyledPartnerCard>
  );
};
