import {
  IntegrationApiServiceHooksType,
  IntegrationApiServiceType,
} from '@/api/integrations/types';
import {
  allIntegrations,
  integrationById,
  useAllIntegrations,
  useInfiniteIntegrations,
  useIntegrationById,
  useSearchIntegrations,
} from '@/api/integrations/crud/get-integration.api';
import {
  createIntegration,
  useCreateIntegration,
} from '@/api/integrations/crud/create-integration.api';
import {
  deleteIntegration,
  useDeleteIntegration,
} from '@/api/integrations/crud/delete-integration.api';
import {
  updateIntegration,
  useUpdateIntegration,
} from '@/api/integrations/crud/update-integration.api';

export const IntegrationApiServiceHooks: IntegrationApiServiceHooksType = {
  useAllIntegrations,
  useIntegrationById,
  useSearchIntegrations,
  useCreateIntegration,
  useUpdateIntegration,
  useDeleteIntegration,
  useInfiniteIntegrations,
};

export const IntegrationApiService: IntegrationApiServiceType = {
  allIntegrations,
  integrationById,
  createIntegration,
  updateIntegration,
  deleteIntegration,
};
