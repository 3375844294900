import { Event, EventsWithMeta } from '@/api/events/types';
import { FilterModelType, filterParamsToQueryParams } from '@/api/helpers';
import { useInfiniteQuery, useQuery } from 'react-query';

import { ApiDataResponseAnatomy } from '@/api/api.types';
import { ExtractFnReturnType } from '@/lib/react-query';
import { axios } from '@/lib/axios';

export type FilterEventsQueryParams = Partial<
  {
    [property in keyof Event]: string | string[];
  } & { page: number; filterModel: FilterModelType }
>;

export const allEvents = async (searchParams: FilterEventsQueryParams = {}) => {
  const params = filterParamsToQueryParams(searchParams);
  const response = await axios.get<EventsWithMeta, ApiDataResponseAnatomy<Event>>(`/events/v2`, {
    params,
  });
  const { collection, _meta } = response;
  return { collection, meta: _meta };
};

export type QueryGetEventsFnType = typeof allEvents;

export const eventById = async (id: string) => axios.get<Event, Event>(`/events/v2/${id}`);

type QueryGetEventFnType = typeof eventById;

export const useAllEvents = (config = {}) => {
  return useQuery<ExtractFnReturnType<QueryGetEventsFnType>>({
    queryKey: ['events'],
    queryFn: () => allEvents(),
    ...config,
  });
};

export const useSearchInfiniteEvents = (
  filterParams: FilterEventsQueryParams = {},
  config = {}
) => {
  return useInfiniteQuery<ExtractFnReturnType<QueryGetEventsFnType>>({
    queryKey: ['events', filterParams],
    queryFn: ({ pageParam = 1 }) => allEvents({ ...filterParams, page: pageParam }),
    getPreviousPageParam: (firstPageData, allPagesData) => {
      const lastPageData = allPagesData.at(-1);
      if (!lastPageData?.meta) {
        // this should never happen unless the API returns an error
        return undefined;
      }
      const previousPage = lastPageData.meta.currentPageNumber - 1;

      if (previousPage <= 1) {
        // no previous page
        return undefined;
      }
      return previousPage;
    },
    getNextPageParam: (lastPageData) => {
      if (!lastPageData?.meta) {
        // this should never happen unless the API returns an error
        return undefined;
      }
      const nextPage = lastPageData.meta.currentPageNumber + 1;
      if (nextPage > lastPageData.meta.totalNumberOfPages) {
        // no more pages
        return undefined;
      }
      return nextPage;
    },
    ...config,
  });
};

export const useSearchEvents = (filterParams: FilterEventsQueryParams, config = {}) => {
  return useQuery<ExtractFnReturnType<QueryGetEventsFnType>>({
    queryKey: ['events', filterParams],
    queryFn: () => allEvents(filterParams),
    ...config,
  });
};

export const useEventById = (id: string, config = {}) => {
  return useQuery<ExtractFnReturnType<QueryGetEventFnType>>({
    queryKey: ['events', id],
    queryFn: () => eventById(id),
    staleTime: 5 * (60 * 1000), // 5 minutes
    ...config,
  });
};
