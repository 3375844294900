import styled from 'styled-components';

export const SideMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  margin: 8px;

  .ant-skeleton.ant-skeleton-block {
    padding: 2px 10px;
  }
`;
