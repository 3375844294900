import {
  CTAButtonContainer,
  CTARow,
  DisclaimerColumn,
  DisclaimerRow,
  IntegrationPartner,
  IntegrationPartnerLogoContainer,
  IntegrationsRow,
  PartnerRowContent,
  PartnersRow,
} from '@/features/publicPages/Partners.style';
import { Col, Skeleton, Typography } from 'antd';
import {
  PartnersCardContainer,
  PublicPageContainer,
  PublicPageHeroRow,
  PublicPageTitle,
} from '@/features/publicPages/PublicPage.style';
import { PublicHeroSection, PublicHeroText } from '@/features/documentation/Documentation.style';
import { TIntegrationPartner, TPartner } from '@/features/publicPages/types';
import { useEffect, useState } from 'react';

import { ButtonlikeLink } from '@/components/Link/ButtonlikeLink';
import { PartnerCard } from '@/components/Card/PartnerCard';
import { PublicPageFooter } from '@/components/Footer/PublicPageFooter';
import { Records } from 'airtable';
import airtable from '@/api/airtable/airtable';
import { composePathToDocsRecord } from '@/features/documentation/helpers';

const { Title } = Typography;

export const Partners = () => {
  const [partners, setPartners] = useState<Records<TPartner> | null>(null);
  const [integrationPartners, setIntegrationPartners] =
    useState<Records<TIntegrationPartner> | null>(null);

  useEffect(() => {
    (async () => {
      try {
        const response = await airtable<TPartner>('partners')
          .select({
            view: 'Grid',
            filterByFormula: 'AND({Status} = "Active")',
          })
          .all();
        setPartners([...response]);
      } catch (error) {
        console.error(error);
        setPartners([]);
      }
    })();
    (async () => {
      try {
        const response = await airtable<TIntegrationPartner>('integration partners')
          .select({
            view: 'Grid',
            filterByFormula: 'AND({Status} = "Active")',
          })
          .all();
        setIntegrationPartners([...response]);
      } catch (error) {
        console.error(error);
        setIntegrationPartners([]);
      }
    })();
  }, []);

  return (
    <>
      <PublicPageContainer>
        <PublicPageHeroRow>
          <Col span={24}>
            <PublicHeroSection>
              <PublicHeroText>
                <PublicPageTitle>Partners</PublicPageTitle>
              </PublicHeroText>
            </PublicHeroSection>
          </Col>
        </PublicPageHeroRow>
        <CTARow>
          <Col span={10} offset={2}>
            <Title level={3}>Fuzion integrated</Title>
          </Col>
          <CTAButtonContainer span={10}>
            <ButtonlikeLink
              to={composePathToDocsRecord({
                category: 'Integrations',
                record: 'Integrations partners',
              })}
              label="Learn More"
            />
          </CTAButtonContainer>
        </CTARow>
        <IntegrationsRow>
          {integrationPartners ? (
            integrationPartners.map((integrationPartner) => (
              <IntegrationPartner key={integrationPartner.id}>
                <IntegrationPartnerLogoContainer>
                  <img
                    src={integrationPartner.get('LogoUrl')}
                    alt={integrationPartner.get('Name')}
                  />
                </IntegrationPartnerLogoContainer>
                <Title level={4}>
                  <a href={integrationPartner.get('Url')} target="_blank" rel="noreferrer">
                    {integrationPartner.get('Name')}
                  </a>
                </Title>
              </IntegrationPartner>
            ))
          ) : (
            <Skeleton active />
          )}
        </IntegrationsRow>
        <PartnersRow>
          <PartnerRowContent>
            <Col span={24}>
              <PartnersCardContainer>
                {partners ? (
                  partners.map((partner) => <PartnerCard key={partner.id} partner={partner} />)
                ) : (
                  <Skeleton active />
                )}
              </PartnersCardContainer>
            </Col>
          </PartnerRowContent>
        </PartnersRow>
        <DisclaimerRow>
          <PartnerRowContent>
            <DisclaimerColumn span={24}>
              Speak with your Digital Channel Sales Manager for specifics on each integration
              offering. Implementation, setup, and custom integration fees apply.
            </DisclaimerColumn>
          </PartnerRowContent>
        </DisclaimerRow>
      </PublicPageContainer>
      <PublicPageFooter />
    </>
  );
};
