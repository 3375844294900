import {
  ContentcategoryApiServiceHooksType,
  ContentcategoryApiServiceType,
} from '@/api/contentcategories/types';
import {
  allContentcategories,
  contentcategoryById,
  useAllContentcategories,
  useContentcategoryById,
  useSearchContentcategories,
  useSearchInfiniteContentcategories,
} from '@/api/contentcategories/crud/get-contentcategory.api';
import {
  createContentcategory,
  useCreateContentcategory,
} from '@/api/contentcategories/crud/create-contentcategory.api';
import {
  deleteContentcategory,
  useDeleteContentcategory,
} from '@/api/contentcategories/crud/delete-contentcategory.api';
import {
  updateContentcategory,
  useUpdateContentcategory,
} from '@/api/contentcategories/crud/update-contentcategory.api';

export const ContentcategoryApiServiceHooks: ContentcategoryApiServiceHooksType = {
  useAllContentcategories,
  useSearchInfiniteContentcategories,
  useContentcategoryById,
  useSearchContentcategories,
  useCreateContentcategory,
  useUpdateContentcategory,
  useDeleteContentcategory,
};

export const ContentcategoryApiService: ContentcategoryApiServiceType = {
  allContentcategories,
  contentcategoryById,
  createContentcategory,
  updateContentcategory,
  deleteContentcategory,
};
