import { MutationConfig, queryClient } from '@/lib/react-query';

import { OrganizationScopeUser } from '@/api/organizationScopeUser/types';
import { addNotification } from '@/lib/notifications';
import { axios } from '@/lib/axios';
import { useMutation } from 'react-query';

export const deleteOrganizationScopeUser = async (id: string): Promise<OrganizationScopeUser> => {
  return axios.delete<any, OrganizationScopeUser>(`/organization-scope-user/v2/${id}`);
};

export type UseDeleteOrganizationScopeUserOptions = {
  config?: MutationConfig<typeof deleteOrganizationScopeUser>;
};

export const useDeleteOrganizationScopeUser = ({
  config,
}: UseDeleteOrganizationScopeUserOptions = {}) => {
  return useMutation({
    onMutate: async (deletedOrganizationScopeUserId: string) => {
      await queryClient.cancelQueries(['organization-scope-user']);
      const previousOrganizationScopeUsers = queryClient.getQueryData<OrganizationScopeUser[]>([
        'organization-scope-user',
      ]);
      queryClient.setQueryData(
        ['organization-scope-user'],
        previousOrganizationScopeUsers?.filter(
          (organizationScopeUser) => organizationScopeUser.id !== deletedOrganizationScopeUserId
        )
      );

      return { previousOrganizationScopeUsers };
    },
    onError: (error, __, context: any) => {
      if (context?.previousOrganizationScopeUsers) {
        queryClient.setQueryData(
          ['organization-scope-user'],
          context.previousOrganizationScopeUsers
        );
      }
      addNotification({
        type: 'error',
        message: error.message,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['organization-scope-user']);
    },
    ...config,
    mutationFn: deleteOrganizationScopeUser,
  });
};
