import { Icon } from '@/components/Icon';
import { notification } from 'antd';

type NotificationParams = {
  message: string;
  description?: string;
  type?: `success` | `info` | `warning` | `error`;
};

export const addNotification = ({ message, description, type = 'success' }: NotificationParams) =>
  notification.open({
    message,
    description,
    placement: 'top',
    className: `generic-notification-custom-class ${type}`,
    closeIcon: <Icon name="cancel" />,
  });
