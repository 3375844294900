import { SideMenuContainer } from '@/components/Navigation/SideNavigation/LoadingSideNavigation.style';
import { SidebarSection } from './SideNavigation.style';
import { Sider } from '@/components/Layout/MainLayout.style';
import { Skeleton } from 'antd';

export const LoadingSideNavigation = () => {
  return (
    <Sider id="layout-sidenav-container" width="260px">
      <SidebarSection>
        <Skeleton.Image active />
      </SidebarSection>
      <SideMenuContainer>
        {Array.from({ length: 8 }).map((_, i) => (
          <Skeleton.Button key={`skeleton-item-${i}`} active block shape="round" size="small" />
        ))}
      </SideMenuContainer>
    </Sider>
  );
};
