import { StyledTextArea } from './Form.style';

export interface ITextAreaProps {
  rows: number;
  placeholder: string;
  defaultValue?: string;
  disabled?: boolean;
}

export const TextArea = ({
  rows = 5,
  placeholder = 'Enter text',
  defaultValue,
  ...rest
}: ITextAreaProps) => {
  return (
    <StyledTextArea
      style={{ borderRadius: '15px' }}
      allowClear
      rows={rows}
      placeholder={placeholder}
      autosize={{ minRows: 3, maxRows: 6 }}
      defaultValue={defaultValue}
      {...rest}
    />
  );
};
