import {
  OrganizationScopeUserApiServiceHooksType,
  OrganizationScopeUserApiServiceType,
} from '@/api/organizationScopeUser/types';
import {
  allOrganizationScopeUsers,
  organizationScopeUserById,
  useAllOrganizationScopeUsers,
  useOrganizationScopeUserById,
  useSearchOrganizationScopeUsers,
} from '@/api/organizationScopeUser/crud/get-organization-scope-user.api';
import {
  createOrganizationScopeUser,
  useCreateOrganizationScopeUser,
} from '@/api/organizationScopeUser/crud/create-organization-scope-user.api';
import {
  deleteOrganizationScopeUser,
  useDeleteOrganizationScopeUser,
} from '@/api/organizationScopeUser/crud/delete-organization-scope-user.api';

export const OrganizationScopeUserApiServiceHooks: OrganizationScopeUserApiServiceHooksType = {
  useAllOrganizationScopeUsers,
  useOrganizationScopeUserById,
  useSearchOrganizationScopeUsers,
  useCreateOrganizationScopeUser,
  useDeleteOrganizationScopeUser,
};

export const OrganizationScopeUserApiService: OrganizationScopeUserApiServiceType = {
  allOrganizationScopeUsers,
  organizationScopeUserById,
  createOrganizationScopeUser,
  deleteOrganizationScopeUser,
};
