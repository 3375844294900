import { APIReference } from '@/features/publicPages/APIReference';
import { DocHomepage } from '@/features/documentation/DocHomepage';
import { Documentation } from '@/features/documentation/Documentation';
import { DocumentationLayout } from '@/components/Layout/DocumentationLayout';
import { Help } from '@/features/publicPages/Help';
import { Home } from '@/features/publicPages/Home';
import { Navigate } from 'react-router-dom';
import { Partners } from '@/features/publicPages/Partners';
import { PublicLayout } from '@/components/Layout/PublicLayout';
import { PublicLayoutv2 } from '@/components/Layout/PublicLayoutv2';
import { ReleaseNotes } from '@/features/publicPages/ReleaseNotes';
import { PublicTechnicalSupport } from '@/features/publicPages/PublicTechnicalSupport';
import { UpdatesPage } from '@/features/updates/UpdatesPage';

export const publicRoutes = (() => [
  {
    path: '/',
    element: <PublicLayoutv2 />,
    children: [
      {
        path: '',
        index: true,
        element: <Home />,
      },
      {
        path: '/updates',
        element: <UpdatesPage />,
      },
      {
        path: '/technical-support',
        element: <PublicTechnicalSupport />,
      },
    ],
  },
  {
    path: '/',
    element: <PublicLayout />,
    children: [
      {
        path: '/api-reference',
        element: <APIReference />,
      },
      {
        path: '/release-notes',
        element: <ReleaseNotes />,
      },
      {
        path: '/partners',
        element: <Partners />,
      },
      {
        path: '/help',
        element: <Help />,
      },

      {
        path: '/docs',
        element: <DocumentationLayout />,
        children: [
          { path: '', index: true, element: <DocHomepage /> },
          { path: ':category/:record', element: <Documentation /> },
          { path: '*', element: <Navigate to="/" /> },
        ],
      },
    ],
  },
  { path: '*', element: <Navigate to="/" /> },
])();
