import { FOOTER_COPYRIGHT_GRAY, FREEMAN_DARK_BLUE, MODAL_BG_GRAY } from '@/styles/theme';

import styled from 'styled-components';

export const FreemanBannerContainer = styled.div`
  padding: 0 50px;
  display: flex;
  justify-content: center;

  img {
    max-width: 100%;
    max-height: 200px;
    margin: 80px 0 80px;
  }
`;

export const FooterContent = styled.div`
  background-color: ${MODAL_BG_GRAY};
  display: flex;
  justify-content: space-between;
  padding: 0 50px;
  height: 130px;
`;

export const LinkContainer = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  text-decoration: underline;
  font-size: 18px;

  a {
    color: ${FREEMAN_DARK_BLUE};
  }
`;

export const FooterLogo = styled.div`
  display: flex;
  align-items: center;

  img {
    height: 30px;
  }
`;

export const FooterCopyRight = styled.div`
  background-color: ${FOOTER_COPYRIGHT_GRAY};
  color: white;
  padding: 10px 50px;
  height: 48px;
`;
