import { Card } from 'antd';
import styled from 'styled-components';

export const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  width: 270px;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);

  .ant-card-meta-title {
    text-overflow: ellipsis;
    white-space: break-spaces;
    font-size: 20px;
  }

  .ant-card-body {
    padding: 24px 0 12px 0;
  }
`;
