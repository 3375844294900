export const composePathToDocsRecord = ({
  category,
  record,
}: {
  category: string;
  record: string;
}) => {
  const path = `${encodeURIComponent(category)}/${encodeURIComponent(record)}`;
  return `/docs/${path}`;
};
