import { IconNames, IconsDict } from '@/components/Icon/IconsDict';

import { StyledIcon } from './Icon.style';

type IconProps = {
  name: IconNames;
  fillColor?: string;
};

export const Icon = ({ name, fillColor = '#000' }: IconProps) => {
  return <StyledIcon>{IconsDict[name](fillColor)}</StyledIcon>;
};
