import {
  ContentWrapper,
  DocumentContainer,
  LoadingContentContainer,
} from '@/features/documentation/Documentation.style';
import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import ReactMarkdown from 'react-markdown';
import { Skeleton } from 'antd';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import airtable from '@/api/airtable/airtable';
import gfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import rehypeSlug from 'rehype-slug';
import remarkToc from 'remark-toc';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';

export const Documentation = () => {
  const [text, setText] = useState('');
  const { category = '', record = '' } = useParams();
  const location = useLocation();

  useEffect(() => {
    (async () => {
      try {
        setText('');
        const [response] = await airtable<{ Markdown: string; Title: string; Category: string }>(
          'documents'
        )
          .select({
            view: 'Grid',
            filterByFormula: `AND(Category = "${category}", Title = "${record}")`,
            fields: ['Markdown', 'Title', 'Category'],
          })
          .all();
        setText(response?.fields?.Markdown || 'Content not found');
      } catch (error) {
        console.log(error);
        setText('Error loading content');
      }
    })();
  }, [category, record]);

  useEffect(() => {
    if (!text) {
      return;
    }
    if (location.hash === '') {
      window.scrollTo(0, 0);
      return;
    }
    setTimeout(() => {
      const id = location.hash.replace('#', '');
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100);
  }, [location, text]);

  if (!text) {
    return (
      <LoadingContentContainer>
        <Skeleton active />
      </LoadingContentContainer>
    );
  }

  return (
    <DocumentContainer>
      <ContentWrapper>
        <ReactMarkdown
          remarkPlugins={[gfm, remarkToc]}
          rehypePlugins={[rehypeRaw, rehypeSlug]}
          linkTarget={(href) => {
            if (href?.startsWith('http')) {
              return '_blank';
            }
            return '_self';
          }}
          components={{
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            code({ node, inline, className, children, style, ...props }) {
              const match = /language-(\w+)/.exec(className || '');
              return !inline && match ? (
                <SyntaxHighlighter style={vscDarkPlus} language={match[1]} PreTag="div" {...props}>
                  {String(children).replace(/\n$/, '')}
                </SyntaxHighlighter>
              ) : (
                <code className={className} {...props}>
                  {children}
                </code>
              );
            },
          }}
        >
          {text}
        </ReactMarkdown>
      </ContentWrapper>
    </DocumentContainer>
  );
};
