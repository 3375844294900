import { Integration, UpdateIntegrationPayload } from '@/api/integrations/types';
import { MutationConfig, queryClient } from '@/lib/react-query';

import { addNotification } from '@/lib/notifications';
import { axios } from '@/lib/axios';
import { useMutation } from 'react-query';

export const updateIntegration = async ({
  data,
  id,
}: UpdateIntegrationPayload): Promise<Integration> => {
  return axios.patch(`/integrations/v2/${id}`, data);
};

export type UseUpdateIntegrationOptions = {
  config?: MutationConfig<typeof updateIntegration>;
  data?: UpdateIntegrationPayload;
};

export const useUpdateIntegration = ({ config }: UseUpdateIntegrationOptions = {}) => {
  return useMutation({
    onMutate: async (updatedIntegration) => {
      await queryClient.cancelQueries(['integration', updatedIntegration?.id]);
      const previousIntegration = queryClient.getQueryData<Integration>([
        'integration',
        updatedIntegration?.id,
      ]);
      queryClient.setQueryData(['integration', updatedIntegration?.id], {
        ...previousIntegration,
        ...updatedIntegration.data,
        id: updatedIntegration.id,
      });

      return { previousIntegration };
    },
    onError: (error, __, context: any) => {
      if (context?.previousIntegration) {
        queryClient.setQueryData(
          ['integration', context.previousIntegration.id],
          context.previousIntegration
        );
      }
      addNotification({
        type: 'error',
        message: error.message,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['integrations']);
      addNotification({
        type: 'success',
        message: 'You have successfully updated this integration!',
      });
    },
    ...config,
    mutationFn: updateIntegration,
  });
};
