import {
  TPermissionItem,
  TScopeName,
  composeEventLevelPermissions,
  composeEventLevelScopes,
  composeOrganizationLevelPermissions,
  composeOrganizationLevelScopes,
  composeUserScopeMapObjects,
  sortScopeItemsWithLabelByCustomOrder,
} from '@/features/auth0/helpers';

import { EventApiServiceHooks } from '@/api/events';
import { EventScopeUserApiServiceHooks } from '@/api/eventScopeUser';
import { IntegrationApiServiceHooks } from '@/api/integrations';
import { OrganizationApiServiceHooks } from '@/api/organizations';
import { OrganizationScopeUserApiServiceHooks } from '@/api/organizationScopeUser';
import { ScopeApiServiceHooks } from '@/api/scopes';
import { ScopeUserApiServiceHooks } from '@/api/scopeUser';
import { User } from '@/api/users/types';
import { useMemo } from 'react';

const { useSearchScopeUsers } = ScopeUserApiServiceHooks;
const { useSearchOrganizationScopeUsers } = OrganizationScopeUserApiServiceHooks;
const { useSearchEventScopeUsers } = EventScopeUserApiServiceHooks;
const { useSearchScopes } = ScopeApiServiceHooks;
const { useSearchOrganizations } = OrganizationApiServiceHooks;
const { useSearchEvents } = EventApiServiceHooks;
const { useIntegrationById } = IntegrationApiServiceHooks;

export const useUsersPermissions = (user?: User) => {
  const {
    data: scopeUsers,
    isLoading: isUserScopesLoading,
    isSuccess: isUserScopesSuccess,
  } = useSearchScopeUsers(
    {
      userId: user?.id,
    },
    { enabled: !!user }
  );
  const {
    data: organizationScopeUsers,
    isLoading: isUserOrgScopesLoading,
    isSuccess: isUserOrgScopesSuccess,
  } = useSearchOrganizationScopeUsers(
    {
      userId: user?.id,
    },
    { enabled: !!user }
  );
  const {
    data: eventScopeUsers,
    isLoading: isUserEventScopesLoading,
    isSuccess: isUserEventScopesSuccess,
  } = useSearchEventScopeUsers(
    {
      userId: user?.id,
    },
    { enabled: !!user }
  );
  const {
    data: integration,
    isLoading: isIntegrationLoading,
    isSuccess: isIntegrationSuccess,
  } = useIntegrationById(user?.integrationId as string, {
    enabled: !!user?.integrationId,
  });

  const hasSystemPermissions = !!scopeUsers?.collection?.length;
  const hasOrganizationPermissions = !!organizationScopeUsers?.collection?.length;
  const hasEventPermissions = !!eventScopeUsers?.collection?.length;
  const hasAnyLevelPermissions =
    hasSystemPermissions || hasOrganizationPermissions || hasEventPermissions;

  // We need to know scopes names, so we need to fetch scopes
  // However scopes might be repeated, so it is better to fetch all used scopes at once
  const {
    data: allUsedScopes,
    isLoading: isScopesLoading,
    isSuccess: isScopesSuccess,
  } = useSearchScopes(
    {
      id: [
        ...new Set([
          ...(scopeUsers?.collection || []).map((scopeUser) => scopeUser.scopeId),
          ...(organizationScopeUsers?.collection || []).map((userOrgScope) => userOrgScope.scopeId),
          ...(eventScopeUsers?.collection || []).map((eventScopeUser) => eventScopeUser.scopeId),
        ]),
      ],
    },
    {
      enabled:
        !isUserScopesLoading &&
        !isUserOrgScopesLoading &&
        !isUserEventScopesLoading &&
        hasAnyLevelPermissions,
    }
  );

  const {
    data: allUsedEvents,
    isLoading: isAllUsedEventsLoading,
    isSuccess: isEventsSuccess,
  } = useSearchEvents(
    {
      id: [
        ...new Set(
          (eventScopeUsers?.collection || []).map((eventScopeUser) => eventScopeUser.eventId)
        ),
      ],
    },
    {
      enabled: !isUserEventScopesLoading && hasEventPermissions,
    }
  );

  // Loading organizations that are used in organization scopes and also
  // parent organizations for the events that are used in event scopes
  const {
    data: allUsedOrganizations,
    isLoading: isOrganizationLoading,
    isSuccess: isOrganizationsSuccess,
  } = useSearchOrganizations(
    {
      id: [
        ...new Set([
          ...(organizationScopeUsers?.collection || []).map(
            (userOrgScope) => userOrgScope.organizationId
          ),
          ...(allUsedEvents?.collection || []).map((event) => event.organizationId),
        ]),
      ],
    },
    {
      enabled:
        !isUserOrgScopesLoading &&
        !isAllUsedEventsLoading &&
        (hasOrganizationPermissions || hasEventPermissions),
    }
  );

  // We need to match scopeIds with their names for all three scopes levels
  // To do this efficiently we need to format scopes array into a map

  const {
    allUsedScopesIdToNameMap,
    allUsedScopesNameMap,
    allUsedOrganizationsMap,
    allUsedEventsMap,
    scopeIdToScopeUsersMap,
    scopeIdToOrganizationScopeUsersArrayMap,
    scopeIdToEventScopeUsersArrayMap,
  } = useMemo(
    () =>
      composeUserScopeMapObjects({
        allUsedScopes: allUsedScopes?.collection,
        allUsedOrganizations: allUsedOrganizations?.collection,
        allUsedEvents: allUsedEvents?.collection,
        scopeUsers: scopeUsers?.collection,
        organizationScopeUsers: organizationScopeUsers?.collection,
        eventScopeUsers: eventScopeUsers?.collection,
      }),
    [
      allUsedEvents?.collection,
      allUsedOrganizations?.collection,
      allUsedScopes,
      eventScopeUsers?.collection,
      organizationScopeUsers?.collection,
      scopeUsers?.collection,
    ]
  );

  // Split permissions into system level (system step) and all orgs all events (granular step)
  const { systemLevelPermissions, topLevelScopes } = useMemo(() => {
    const systemLevelPermissions: TPermissionItem[] = [];

    const topLevelScopes: string[] = [];

    scopeUsers?.collection?.forEach((scopeUser) => {
      const scopeName = allUsedScopesIdToNameMap[scopeUser.scopeId] as TScopeName;
      if (!scopeName) {
        return;
      }

      topLevelScopes.push(scopeName);
      systemLevelPermissions.push({
        label: scopeName,
        value: scopeUser.scopeId,
      });
    });
    return {
      systemLevelPermissions,
      topLevelScopes,
    };
  }, [allUsedScopesIdToNameMap, scopeUsers?.collection]);

  // Compose Organization Level Permissions
  const organizationLevelPermissions = useMemo(
    () =>
      composeOrganizationLevelPermissions({
        organizationLevelScopeRecords: organizationScopeUsers?.collection,
        organizationsMap: allUsedOrganizationsMap,
        scopesMap: allUsedScopesIdToNameMap,
      }),
    [allUsedOrganizationsMap, allUsedScopesIdToNameMap, organizationScopeUsers?.collection]
  );
  // Compose Organization Level List of Scopes
  const organizationLevelScopes = useMemo(
    () =>
      composeOrganizationLevelScopes({
        organizationLevelScopeRecords: organizationScopeUsers?.collection,
        scopesMap: allUsedScopesIdToNameMap,
      }),
    [allUsedScopesIdToNameMap, organizationScopeUsers?.collection]
  );

  // Compose Event Level Permissions
  const eventLevelPermissions = useMemo(
    () =>
      composeEventLevelPermissions({
        eventLevelScopeRecords: eventScopeUsers?.collection,
        eventsMap: allUsedEventsMap,
        scopesMap: allUsedScopesIdToNameMap,
        organizationsMap: allUsedOrganizationsMap,
      }),
    [
      allUsedEventsMap,
      allUsedOrganizationsMap,
      allUsedScopesIdToNameMap,
      eventScopeUsers?.collection,
    ]
  );
  // Compose Event Level List of Scopes
  const eventLevelScopes = useMemo(
    () =>
      composeEventLevelScopes({
        eventLevelScopeRecords: eventScopeUsers?.collection,
        eventsMap: allUsedEventsMap,
        scopesMap: allUsedScopesIdToNameMap,
      }),
    [allUsedEventsMap, allUsedScopesIdToNameMap, eventScopeUsers?.collection]
  );

  const isLoading = [
    isUserScopesLoading,
    isUserOrgScopesLoading,
    isUserEventScopesLoading,
    isIntegrationLoading,
    isAllUsedEventsLoading,
    isOrganizationLoading,
    isScopesLoading,
  ].some((item) => item);

  const isSuccess = [
    isIntegrationSuccess || !user?.integrationId,
    isEventsSuccess,
    isOrganizationsSuccess,
    isScopesSuccess,
    isUserScopesSuccess,
    isUserOrgScopesSuccess,
    isUserEventScopesSuccess,
  ].every((item) => item);

  return {
    systemLevelPermissions: sortScopeItemsWithLabelByCustomOrder(systemLevelPermissions),
    organizationLevelPermissions,
    eventLevelPermissions,
    scopeIdToScopeUsersMap,
    scopeIdToOrganizationScopeUsersArrayMap,
    scopeIdToEventScopeUsersArrayMap,
    allUsedScopesNameMap,
    topLevelScopes,
    organizationLevelScopes,
    eventLevelScopes,
    user,
    integration,
    isLoading,
    isSuccess,
    hasAnyLevelPermissions,
  };
};
