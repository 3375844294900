import { Card, Col, Row, Timeline, Typography } from 'antd';
import {
  DocHomepageCardsRow,
  DocHomepageWrapper,
} from '@/features/documentation/Documentation.style';

import { BACKGROUND_GRAY } from '@/styles/theme';
import { Link } from 'react-router-dom';
import { composePathToDocsRecord } from '@/features/documentation/helpers';

const { Title } = Typography;

export const DocHomepage = () => {
  return (
    <DocHomepageWrapper>
      <Row>
        <Col span={12} offset={6} style={{ textAlign: 'center' }}>
          <Title level={3}> Fuzion event line</Title>
          <Timeline mode="right">
            <Timeline.Item color="red" label="2022-09-01">
              Technical testing 2022-09-01
            </Timeline.Item>
            <Timeline.Item label="2022-09-01">
              Network problems being solved 2022-09-01
            </Timeline.Item>
            <Timeline.Item label="2022-09-01">Create a services</Timeline.Item>
            <Timeline.Item label="2022-09-01 09:12:11">
              Solve initial network problems
            </Timeline.Item>
            <Timeline.Item label="2022-09-01 09:12:11">Technical testing</Timeline.Item>
            <Timeline.Item label="2022-09-01 09:12:11">Network problems being solved</Timeline.Item>
          </Timeline>
        </Col>
      </Row>
      <DocHomepageCardsRow justify="space-between">
        <Col span={18} md={{ span: 6, push: 1 }}>
          <Card title="No-code" style={{ background: BACKGROUND_GRAY }}>
            <p>
              <Link
                to={composePathToDocsRecord({
                  category: 'Applications',
                  record: 'Application permissions',
                })}
              >
                View and manage data with Fuzion
              </Link>
            </p>
            <p>
              <Link
                to={composePathToDocsRecord({
                  category: 'Applications',
                  record: 'Create application',
                })}
              >
                No coding required
              </Link>
            </p>
            <p>
              <Link
                to={composePathToDocsRecord({
                  category: 'Applications',
                  record: 'Applications integrations',
                })}
              >
                Contact Freeman to get your account setup
              </Link>
            </p>
          </Card>
        </Col>
        <Col span={18} md={{ span: 6 }}>
          <Card title="REST API" style={{ background: BACKGROUND_GRAY }}>
            <p>
              <Link
                to={composePathToDocsRecord({
                  category: 'Users',
                  record: 'Internal users',
                })}
              >
                Fast and predictable responises using REST principles
              </Link>
            </p>
            <p>
              <Link
                to={composePathToDocsRecord({
                  category: 'Webhooks',
                  record: 'Webhook logs',
                })}
              >
                View code examples
              </Link>
            </p>
            <p>
              <Link
                to={composePathToDocsRecord({
                  category: 'Webhooks',
                  record: 'Subscriptions',
                })}
              >
                Anything that you can do through the UI and more
              </Link>
            </p>
          </Card>
        </Col>
        <Col md={{ span: 6 }} span={18}>
          <Card title="For developers" style={{ background: BACKGROUND_GRAY }}>
            <p>
              <Link
                to={composePathToDocsRecord({
                  category: 'Users',
                  record: 'External users',
                })}
              >
                API reference
              </Link>
            </p>
            <p>
              <Link
                to={composePathToDocsRecord({
                  category: 'Users',
                  record: 'Creating users',
                })}
              >
                Development quickstart
              </Link>
            </p>
            <p>
              <Link
                to={composePathToDocsRecord({
                  category: 'Webhooks',
                  record: 'How to create a webhook',
                })}
              >
                Card content
              </Link>
            </p>
          </Card>
        </Col>
      </DocHomepageCardsRow>
    </DocHomepageWrapper>
  );
};
