export const granularLevelActionsToDescriptionMap = {
  'events:read': 'Permission to view events',
  'events:write': 'Permission to create and update events',
  'events:delete': 'Permission to delete events',
  'exhibitor-data:read': 'Permission to view exhibitor data',
  'exhibitor-data:write': 'Permission to create and update exhibitor data',
  'exhibitor-data:delete': 'Permission to delete exhibitor data',
  'organizations:read': 'Permission to view organizations',
  'organizations:write': 'Permission to create and update organizations',
  'organizations:delete': 'Permission to delete organizations',
  'presentation-data:read': 'Permission to view session, subsession, poster and speaker data',
  'presentation-data:write':
    'Permission to create and update session, subsession, poster and speaker data',
  'presentation-data:delete': 'Permission to delete session, subsession, poster and speaker data',
  'registration-data:read': 'Permission to view registration data',
  'registration-data:write': 'Permission to create and update registration data',
  'registration-data:delete': 'Permission to delete registration data',
  'webhooks:read': 'Permission to view webhooks',
  'webhooks:write': 'Permission to create and update webhooks',
  'webhooks:delete': 'Permission to delete webhooks',
};

export const granularLevelActionsToPermissionsMap = {
  'events:read': 'ReadEvent',
  'events:write': 'WriteEvent',
  'events:delete': 'DeleteEvent',
  'organizations:read': 'ReadOrganizations',
  'organizations:write': 'AddOrganization',
  'organizations:delete': 'DeleteOrganization',
  'presentation-data:read': 'ReadPresentationData',
  'presentation-data:write': 'WritePresentationData',
  'presentation-data:delete': 'DeletePresentationData',
  'exhibitor-data:read': 'ReadExhibitorData',
  'exhibitor-data:write': 'WriteExhibitorData',
  'exhibitor-data:delete': 'DeleteExhibitorData',
  'registration-data:read': 'ReadRegistrationData',
  'registration-data:write': 'WriteRegistrationData',
  'registration-data:delete': 'DeleteRegistrationData',
  'webhooks:read': 'BackToWebhooksubscription',
  'webhooks:write': 'AddWebhooksubscription',
  'webhooks:delete': 'DeleteWebhooksubscription',
  'config-management:read': 'ReadConfigManagement',
  'config-management:write': 'WriteConfigManagement',
  'config-management:delete': 'DeleteConfigManagement',
};

export type TGranularLevelAction = keyof typeof granularLevelActionsToDescriptionMap;
