import { DARK_GRAY } from '@/styles/theme';
import { Dropdown } from 'antd';
import styled from 'styled-components';

export const HeaderNavigationContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  list-style: none;
  padding: 0;
  width: 100%;
  margin: 0;
  gap: 15px;
  font-weight: 700;
  color: #000;
`;

export const HoverableSpan = styled.span`
  cursor: pointer;
`;

export const HeaderNavLeft: any = styled.div`
  height: 100%;
  min-width: 300px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  h1 {
    font-weight: 700;
    font-size: 24px;
    color: ${DARK_GRAY};
    margin: 0 20px 5px 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const HeaderNavRight: any = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledDropdown = styled(Dropdown)`
  .ant-dropdown-menu {
    border-radius: 15px;
  }

  .ant-dropdown-menu-item {
    &:hover {
      border-radius: 15px;
    }
  }
`;
