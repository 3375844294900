import { ScopeUser, ScopeUserWithMeta } from '@/api/scopeUser/types';

import { ApiDataResponseAnatomy } from '@/api/api.types';
import { ExtractFnReturnType } from '@/lib/react-query';
import { axios } from '@/lib/axios';
import { filterParamsToQueryParams } from '@/api/helpers';
import { useQuery } from 'react-query';

export type FilterScopeUserQueryParams = Partial<
  {
    [property in keyof ScopeUser]: string;
  } & { page: number }
>;

export const allScopeUsers = async (searchParams: FilterScopeUserQueryParams = {}) => {
  const params = filterParamsToQueryParams(searchParams);

  const response = await axios.get<ScopeUserWithMeta, ApiDataResponseAnatomy<ScopeUser>>(
    `/scope-user/v2`,
    {
      params,
    }
  );

  const { collection, _meta } = response;
  return { collection, meta: _meta };
};

type QueryGetScopeUsersFnType = typeof allScopeUsers;

export const scopeUserById = async (id: string) =>
  axios.get<ScopeUser, ScopeUser>(`/scope-user/v2/${id}`);

type QueryGetScopeUserFnType = typeof scopeUserById;

export const useAllScopeUsers = (config = {}) => {
  return useQuery<ExtractFnReturnType<QueryGetScopeUsersFnType>>({
    queryKey: ['scope-user'],
    queryFn: () => allScopeUsers(),
    ...config,
  });
};

export const useSearchScopeUsers = (filterParams: FilterScopeUserQueryParams, config = {}) => {
  return useQuery<ExtractFnReturnType<QueryGetScopeUsersFnType>>({
    queryKey: ['scope-user', filterParams],
    queryFn: () => allScopeUsers(filterParams),
    ...config,
  });
};

export const useScopeUserById = (id: string, config = {}) => {
  return useQuery<ExtractFnReturnType<QueryGetScopeUserFnType>>({
    queryKey: ['scope-user', id],
    queryFn: () => scopeUserById(id),
    ...config,
  });
};
