export const size = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  antdXS: '576px',
  mobileXL: '580px',
  tabletS: '700px',
  tabletM: '767px',
  antdMD: '768px',
  antdLG: '992px',
  laptopS: '1024px',
  laptopM: '1080px',
  laptopML: '1180px',
  antdXL: '1200px',
  laptopMLL: '1380px',
  laptopL: '1440px',
  antdXXL: '1600px',
  desktop: '2560px',
} as const;

export const deviceLargerThan = {
  mobileS: `@media only screen and (min-width: ${size.mobileS})`,
  mobileM: `@media only screen and (min-width: ${size.mobileM})`,
  mobileL: `@media only screen and (min-width: ${size.mobileL})`,
  antdXS: `@media only screen and (min-width: ${size.antdXS})`,
  mobileXL: `@media only screen and (min-width: ${size.mobileXL})`,
  tabletS: `@media only screen and (min-width: ${size.tabletS})`,
  tabletM: `@media only screen and (min-width: ${size.tabletM})`,
  antdMD: `@media only screen and (min-width: ${size.antdMD})`,
  antdLG: `@media only screen and (min-width: ${size.antdLG})`,
  laptopS: `@media only screen and (min-width: ${size.laptopS})`,
  laptopM: `@media only screen and (min-width: ${size.laptopM})`,
  laptopML: `@media only screen and (min-width: ${size.laptopML})`,
  antdXL: `@media only screen and (min-width: ${size.antdXL})`,
  laptopMLL: `@media only screen and (min-width: ${size.laptopMLL})`,
  laptopL: `@media only screen and (min-width: ${size.laptopL})`,
  antdXXL: `@media only screen and (min-width: ${size.antdXXL})`,
  desktop: `@media only screen and (min-width: ${size.desktop})`,
} as const;

export const deviceSmallerThan = {
  mobileS: `@media only screen and (max-width: ${size.mobileS})`,
  mobileM: `@media only screen and (max-width: ${size.mobileM})`,
  mobileL: `@media only screen and (max-width: ${size.mobileL})`,
  antdXS: `@media only screen and (max-width: ${size.antdXS})`,
  mobileXL: `@media only screen and (max-width: ${size.mobileXL})`,
  tabletS: `@media only screen and (max-width: ${size.tabletS})`,
  tabletM: `@media only screen and (max-width: ${size.tabletM})`,
  antdMD: `@media only screen and (max-width: ${size.antdMD})`,
  antdLG: `@media only screen and (max-width: ${size.antdLG})`,
  laptopS: `@media only screen and (max-width: ${size.laptopS})`,
  laptopM: `@media only screen and (max-width: ${size.laptopM})`,
  laptopML: `@media only screen and (max-width: ${size.laptopML})`,
  antdXL: `@media only screen and (max-width: ${size.antdXL})`,
  laptopMLL: `@media only screen and (max-width: ${size.laptopMLL})`,
  laptopL: `@media only screen and (max-width: ${size.laptopL})`,
  antdXXL: `@media only screen and (max-width: ${size.antdXXL})`,
  desktop: `@media only screen and (max-width: ${size.desktop})`,
} as const;
