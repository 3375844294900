import { Skeleton } from 'antd';
import styled from 'styled-components';

export const LoadingContentSkeletonContainer = styled.div`
  display: flex;
  height: 20vh;
  justify-content: center;
  align-items: flex-start;
  margin: 10px 0;
  padding: 0 10px;
`;

export const StyledSkeletonInput = styled(Skeleton.Input)`
  width: 200px;
  margin: 0 10px;
`;
