import { IconNames } from '@/components/Icon/IconsDict';
import { SidebarIcon } from '@/components/Icon/SidebarIcon';
import { StyledMenuItem } from './MenuItem.style';

interface MenuItemProps {
  selected: boolean;
  key: string;
  iconName?: IconNames | null;
  children: any;
  id: string;
  onClick?: () => void;
}

export const MenuItem = ({ selected, id, iconName, children, ...rest }: MenuItemProps) => {
  return (
    <StyledMenuItem
      id={id}
      selected={selected}
      icon={iconName && <SidebarIcon name={iconName} selected={selected} />}
      {...rest}
    >
      {children}
    </StyledMenuItem>
  );
};
