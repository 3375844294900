import { CreateOrganizationPayload, Organization } from '../types';
import { MutationConfig, queryClient } from '@/lib/react-query';

import { addNotification } from '@/lib/notifications';
import { axios } from '@/lib/axios';
import { useMutation } from 'react-query';

export const createOrganization = async (
  data: CreateOrganizationPayload
): Promise<Organization> => {
  const organization: Organization = await axios.post('/organizations/v2', data);

  return organization;
};

export type UseCreateOrganizationOptions = {
  config?: MutationConfig<typeof createOrganization>;
  data?: CreateOrganizationPayload;
};

export const useCreateOrganization = ({ config }: UseCreateOrganizationOptions = {}) => {
  return useMutation({
    onMutate: async (newOrganization) => {
      await queryClient.cancelQueries(['organizations']);
      const previousOrganizations = queryClient.getQueryData<Organization[]>(['organizations']);
      queryClient.setQueryData(
        ['organizations'],
        [...(previousOrganizations || []), newOrganization]
      );
      return { previousOrganizations };
    },
    onError: (error, __, context: any) => {
      if (context?.previousOrganizations) {
        queryClient.setQueryData(['organizations'], context.previousOrganizations);
      }
      addNotification({
        type: 'error',
        message: error.message,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['organizations']);
      addNotification({
        type: 'success',
        message: 'New organization created successfully!',
      });
    },
    ...config,
    mutationFn: createOrganization,
  });
};
