import { CreateContentcategoryPayload, Contentcategory } from '../types';
import { MutationConfig, queryClient } from '@/lib/react-query';

import { addNotification } from '@/lib/notifications';
import { axios } from '@/lib/axios';
import { useMutation } from 'react-query';

export const createContentcategory = async (
  data: CreateContentcategoryPayload
): Promise<Contentcategory> => axios.post<any, Contentcategory>('/contentcategories/v2', data);

export type UseCreateContentcategoryOptions = {
  config?: MutationConfig<typeof createContentcategory>;
  data?: CreateContentcategoryPayload;
};

export const useCreateContentcategory = ({ config }: UseCreateContentcategoryOptions = {}) => {
  return useMutation({
    onMutate: async (newContentcategory) => {
      await queryClient.cancelQueries(['contentcategories']);
      const previousContentcategories = queryClient.getQueryData<Contentcategory[]>([
        'contentcategories',
      ]);
      queryClient.setQueryData(
        ['contentcategories'],
        [...(previousContentcategories || []), newContentcategory]
      );
      return { previousContentcategories };
    },
    onError: (error, __, context: any) => {
      if (context?.previousContentcategories) {
        queryClient.setQueryData(['contentcategories'], context.previousContentcategories);
      }
      addNotification({
        type: 'error',
        message: error.message,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['contentcategories']);
      addNotification({
        type: 'success',
        message: 'New contentcategory created successfully!',
      });
    },
    ...config,
    mutationFn: createContentcategory,
  });
};
