import { FREEMAN_DARK_BLUE, PUBLIC_PAGE_ELEMENT_DARK_BLUE } from '@/styles/theme';
import { Layout, Menu } from 'antd';

import { deviceSmallerThan } from '@/styles/mediaqueries';
import styled from 'styled-components';

const { Header, Content } = Layout;

const HEADER_HEIGHT = 105;
const HEADER_HEIGHT_MOBILE = 220;

export const PublicHeaderv2 = styled(Header)`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${PUBLIC_PAGE_ELEMENT_DARK_BLUE};
  height: ${HEADER_HEIGHT}px;

  ${deviceSmallerThan.mobileXL} {
    height: ${HEADER_HEIGHT_MOBILE}px;
  }

  #freeman-public-section-logo {
    background-color: white;
    border-radius: 0;
  }
`;
export const HeaderContainer = styled.div`
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 65px;
  background-color: white;
  padding: 12px 20px;

  ${deviceSmallerThan.mobileXL} {
    height: 140px;
  }

  ${deviceSmallerThan.mobileXL} {
    flex-direction: column;
    justify-content: center;
    height: ${HEADER_HEIGHT_MOBILE - 20}px;
  }
`;

export const HeaderLinkContainer = styled.div`
  min-width: 50%;
  display: flex;
  flex: 1;
  gap: 20px;
  justify-items: center;
  align-items: center;

  ${deviceSmallerThan.mobileXL} {
    gap: 10px;
    flex-direction: column;
  }

  .ant-menu-horizontal,
  .ant-menu-item::after,
  .ant-menu-submenu::after {
    border: none !important;
  }

  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item {
    margin-left: -2px;
  }
`;

export const StyledMenu = styled(Menu)`
  width: 100%;
  justify-content: flex-end;
  color: ${FREEMAN_DARK_BLUE};

  ${deviceSmallerThan.mobileXL} {
    justify-content: center;
  }
`;

export const PublicContentContainer = styled(Content)``;
