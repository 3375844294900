import { Button, Card, Tag } from 'antd';
import styled, { css } from 'styled-components';
import { HEADER_HEIGHT, HEADER_HEIGHT_MOBILE } from '@/components/Layout/PublicLayout.style';
import { deviceSmallerThan } from '@/styles/mediaqueries';
const { CheckableTag } = Tag;

type MonthFilterProps = {
  isActive: boolean;
};

export const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  width: 50vw;
  border-radius: 12px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  background-color: white;
  margin: 0 20px;
  max-width: 800px;

  @media screen and (max-width: 1200px) {
    max-width: 500px;
  }

  .ant-card-meta-title {
    text-overflow: ellipsis;
    white-space: break-spaces;
    font-size: 20px;
  }

  .ant-card-body {
    padding: 0 0 12px 0;
  }
`;

export const StyledDate = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  gap: 10px;
  color: #b9b9b9;
  min-width: 30%;
  font-size: 16px;
`;

export const TagsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 20px;
  gap: 10px;
  color: white;
  font-size: 16px;
  flex-wrap: wrap;
  max-width: 95%;
`;

export const StyledTag = styled.div`
  color: #b9b9b9;
  font-size: 16px;
  overflow-wrap: anywhere;
`;

export const StyledTitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  gap: 10px;
  color: #000;
  min-width: 30%;
  font-size: 22px;
  max-width: 95%;
  overflow-wrap: anywhere;
`;

export const StyledBody = styled.div`
  padding: 10px 20px 0;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  color: #000;
  font-size: unset;
  max-width: 95%;
  overflow: hidden;
  overflow-wrap: anywhere;

  & h4 a {
    color: white;
  }

  img {
    max-width: 100%;
  }
`;

export const CardShowButton = styled(Button)`
  border: none;
  background: none;
  padding: 10px;
  box-shadow: none;
  &:hover,
  &:active,
  &:focus {
    color: #29d4ff;
    background: none;
    border: none;
    box-shadow: none;
  }
  color: #000;
`;

export const MonthButton = styled(Button)<MonthFilterProps>`
  border: none;
  background: none;
  padding: 10px 0;
  box-shadow: none;
  &:hover,
  &:active,
  &:focus {
    background: none;
    border: none;
    box-shadow: none;
  }
  color: #b9b9b9;
  font-size: 16px;
  ${(props: MonthFilterProps) =>
    props.isActive &&
    css`
      color: #29d4ff;
    `}
`;

export const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 50px 50px;
  justify-content: space-between;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    scroll-margin-top: ${HEADER_HEIGHT}px;

    ${deviceSmallerThan.tabletS} {
      scroll-margin-top: ${HEADER_HEIGHT_MOBILE}px;
    }
  }
`;

export const TagFilterWrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  width: 300px;
  border-radius: 12px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.3);
  background-color: #d9d9d9;
  padding: 10px 20px;
  margin-top: 12px;
  .ant-card-meta-title {
    text-overflow: ellipsis;
    white-space: break-spaces;
    font-size: 20px;
  }

  &.ant-card {
    background: #d9d9d9;
  }
  .ant-card-body {
    padding: 0 0 12px 0;
  }

  &.ant-card-bordered {
    border: none;
  }

  .ant-list-items {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding-top: 10px;
  }

  .ant-list-item {
    padding: 5px 0;
  }
`;

export const TagHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin: 0 auto;
`;

export const TagHeader = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: #000;
`;

export const ClearTagsButton = styled(Button)`
  border: none;
  background: none;
  box-shadow: none;
  &:hover,
  &:active,
  &:focus {
    color: #29d4ff;
    background: none;
    border: none;
    box-shadow: none;
  }
  padding: 0;
  font-size: 20px;
  color: #000;
`;

export const StyledTagFilter = styled(CheckableTag)`
  width: 100%;
  color: #000;
  background: #f2f2f2;
  border-radius: 4px;
  font-size: 16px;
  padding: 8px;
  &.ant-tag-checkable-checked {
    background: #29d4ff;
    color: #fff;
  }
`;

export const MonthHeader = styled.div`
  color: #000;
  font-size: 16px;
  padding-top: 12px;
  width: 100%;
`;

export const MonthFilterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  .ant-list-items {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  min-width: 115px;
`;
