import { differenceBy, isArray, isEmpty, isEqual, isObject, transform } from 'lodash';

function checkIfArray(value: any): value is any[] {
  return Array.isArray(value);
}
/**
 * Deep diff between two object, using lodash
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Return a new object who represent the diff
 */
export function difference(
  object: Record<string, unknown>,
  base: Record<string, unknown>
): unknown {
  function changes(object: Record<string, unknown>, base: Record<string, unknown>): unknown {
    const diff = transform(object, function (result, value: unknown, key) {
      if (isEqual(value, base[key])) {
        return;
      }
      if (isArray(value) && isArray(base[key])) {
        const arrayDiff = differenceBy(value, base[key] as ArrayLike<any>, 'id');
        if (isEmpty(arrayDiff)) {
          return;
        }
        (result as Record<string, unknown>)[key] = arrayDiff;
        return;
      }

      (result as Record<string, unknown>)[key] =
        isObject(value) && isObject(base[key])
          ? changes(value as Record<string, unknown>, base[key] as Record<string, unknown>)
          : value;
    });
    if (checkIfArray(diff)) {
      return diff.filter(Boolean);
    }
    return diff;
  }
  return changes(object, base);
}

// Helper function to remove empty values from an object
export function removeEmpty(obj: Record<string, unknown>): Record<string, any> {
  return Object.fromEntries(
    Object.entries(obj)
      .filter(([, value]) => {
        if (isObject(value) && isEmpty(value)) {
          return false;
        }
        return value != null;
      })
      .map(([key, value]) => {
        if (isArray(value)) {
          return [key, value];
        }
        return [
          key,
          value === Object(value) ? removeEmpty(value as Record<string, unknown>) : value,
        ];
      })
  );
}
