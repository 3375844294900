import { EventScopeUser, EventScopeUserWithMeta } from '@/api/eventScopeUser/types';

import { ApiDataResponseAnatomy } from '@/api/api.types';
import { ExtractFnReturnType } from '@/lib/react-query';
import { axios } from '@/lib/axios';
import { filterParamsToQueryParams } from '@/api/helpers';
import { useQuery } from 'react-query';

export type FilterEventScopeUserQueryParams = Partial<
  {
    [property in keyof EventScopeUser]: string;
  } & { page: number }
>;

export const allEventScopeUsers = async (searchParams: FilterEventScopeUserQueryParams = {}) => {
  const params = filterParamsToQueryParams(searchParams);

  const response = await axios.get<EventScopeUserWithMeta, ApiDataResponseAnatomy<EventScopeUser>>(
    `/event-scope-user/v2`,
    {
      params,
    }
  );

  const { collection, _meta } = response;
  return { collection, meta: _meta };
};

type QueryGetEventScopeUsersFnType = typeof allEventScopeUsers;

export const eventScopeUserById = async (id: string) =>
  axios.get<EventScopeUser, EventScopeUser>(`/event-scope-user/v2/${id}`);

type QueryGetEventScopeUserFnType = typeof eventScopeUserById;

export const useAllEventScopeUsers = (config = {}) => {
  return useQuery<ExtractFnReturnType<QueryGetEventScopeUsersFnType>>({
    queryKey: ['event-scope-user'],
    queryFn: () => allEventScopeUsers(),
    ...config,
  });
};

export const useSearchEventScopeUsers = (
  filterParams: FilterEventScopeUserQueryParams,
  config = {}
) => {
  return useQuery<ExtractFnReturnType<QueryGetEventScopeUsersFnType>>({
    queryKey: ['event-scope-user', filterParams],
    queryFn: () => allEventScopeUsers(filterParams),
    ...config,
  });
};

export const useEventScopeUserById = (id: string, config = {}) => {
  return useQuery<ExtractFnReturnType<QueryGetEventScopeUserFnType>>({
    queryKey: ['event-scope-user', id],
    queryFn: () => eventScopeUserById(id),
    ...config,
  });
};
