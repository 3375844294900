import {
  OrganizationApiServiceHooksType,
  OrganizationApiServiceType,
} from '@/api/organizations/types';
import {
  allOrganizations,
  organizationById,
  useAllOrganizations,
  useOrganizationById,
  useSearchInfiniteOrganizations,
  useSearchOrganizations,
} from '@/api/organizations/crud/get-organization.api';
import {
  createOrganization,
  useCreateOrganization,
} from '@/api/organizations/crud/create-organization.api';
import {
  deleteOrganization,
  useDeleteOrganization,
} from '@/api/organizations/crud/delete-organization.api';
import {
  updateOrganization,
  useUpdateOrganization,
} from '@/api/organizations/crud/update-organization.api';

export const OrganizationApiServiceHooks: OrganizationApiServiceHooksType = {
  useAllOrganizations,
  useOrganizationById,
  useSearchInfiniteOrganizations,
  useSearchOrganizations,
  useCreateOrganization,
  useUpdateOrganization,
  useDeleteOrganization,
};

export const OrganizationApiService: OrganizationApiServiceType = {
  allOrganizations,
  organizationById,
  createOrganization,
  updateOrganization,
  deleteOrganization,
};
