import { MutationConfig, queryClient } from '@/lib/react-query';

import { ScopeUser } from '@/api/scopeUser/types';
import { addNotification } from '@/lib/notifications';
import { axios } from '@/lib/axios';
import { useMutation } from 'react-query';

export const deleteScopeUser = async (id: string): Promise<ScopeUser> => {
  return axios.delete<any, ScopeUser>(`/scope-user/v2/${id}`);
};

export type UseDeleteScopeUserOptions = {
  config?: MutationConfig<typeof deleteScopeUser>;
};

export const useDeleteScopeUser = ({ config }: UseDeleteScopeUserOptions = {}) => {
  return useMutation({
    onMutate: async (deletedScopeUserId: string) => {
      await queryClient.cancelQueries(['scope-user']);
      const previousScopeUsers = queryClient.getQueryData<ScopeUser[]>(['scope-user']);
      queryClient.setQueryData(
        ['scope-user'],
        previousScopeUsers?.filter((scopeUser) => scopeUser.id !== deletedScopeUserId)
      );

      return { previousScopeUsers };
    },
    onError: (error, __, context: any) => {
      if (context?.previousScopeUsers) {
        queryClient.setQueryData(['scope-user'], context.previousScopeUsers);
      }
      addNotification({
        type: 'error',
        message: error.message,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['scope-user']);
    },
    ...config,
    mutationFn: deleteScopeUser,
  });
};
