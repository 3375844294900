import {
  AdvancedFilterModel,
  BooleanAdvancedFilterModel,
  DateAdvancedFilterModel,
  DateStringAdvancedFilterModel,
  FilterModel,
  NumberAdvancedFilterModel,
  ObjectAdvancedFilterModel,
  SortModelItem,
  TextAdvancedFilterModel,
} from '@ag-grid-community/core';

const filterTypesMap = {
  contains: 'substring',
  equals: 'eq',
  In: 'in',
  notEqual: 'ne',
  endsWith: 'endsWith',
  startsWith: 'startsWith',
  'Less than or equal': 'lte',
  'Less than': 'lt',
  'Greater than or equal': 'gte',
  'Greater than': 'gt',
  Includes: 'contains',
  Like: 'like',
};

export type FilterModelType = AdvancedFilterModel | FilterModel | null;

export type ExcludeBooleanFilterModelType =
  | TextAdvancedFilterModel
  | NumberAdvancedFilterModel
  | DateAdvancedFilterModel
  | DateStringAdvancedFilterModel
  | ObjectAdvancedFilterModel;

export type SortModelType = SortModelItem[];

const handleStringType = (key: string, value: string) => [`filter[${key}][eq]`, value];

const handleNumberOrBooleanType = (key: string, value: number | boolean) => [key, value];

const handleSortModelType = (key: string, value: SortModelItem) => [
  `sort[${value.sort}]`,
  value.colId,
];

const handleArrayType = (key: string, value: string[]) => [`filter[${key}][in]`, value];

const handleBooleanAdvancedFilterModel = (key: string, value: BooleanAdvancedFilterModel) => [
  `filter[${key}][eq]`,
  value.type,
];

const handleSearchModel = (
  key: string,
  value: ExcludeBooleanFilterModelType,
  inOperatorMultifilter: any
) => {
  const fmodel = value;
  const mappedType = filterTypesMap[fmodel?.type as keyof typeof filterTypesMap];

  if (!mappedType) {
    return [];
  }

  if (mappedType === 'in' && fmodel && 'filter' in fmodel && typeof fmodel.filter === 'string') {
    const values = fmodel.filter.split(',');
    const additionalValues = values.splice(1);
    for (let x = 0; x < additionalValues.length; x++) {
      inOperatorMultifilter[`filter[${key}][${mappedType}][${x + 1}]`] = additionalValues[x].trim();
    }
    return [`filter[${key}][${mappedType}][0]`, values[0].trim()];
  }

  if (mappedType === 'contains') {
    return [`filter[${key}][${mappedType}][0]`, fmodel?.filter];
  }

  return [`filter[${key}][${mappedType}]`, fmodel?.filter];
};

export const filterParamsToQueryParams = (
  filterParams: Record<
    string,
    string | number | boolean | string[] | FilterModelType | SortModelType | undefined
  > = {}
) => {
  let inOperatorMultifilter: any = {};
  return {
    ...Object.fromEntries(
      Object.entries(filterParams).map(([key, value]) => {
        if (typeof value === 'string') {
          return handleStringType(key, value);
        }

        if (typeof value === 'number' || typeof value === 'boolean') {
          return handleNumberOrBooleanType(key, value);
        }
        if (value && typeof value === 'object' && 'sort' in value && 'colId' in value) {
          return handleSortModelType(key, value as SortModelItem); // Update the type of value to SortModelItem
        }
        if (Array.isArray(value)) {
          return handleArrayType(key, value as string[]); // Update the type of value to string[]
        }
        if (value && typeof value === 'object' && value.filterType === 'boolean') {
          return handleBooleanAdvancedFilterModel(key, value as BooleanAdvancedFilterModel);
        }
        // assumes that this is a search model
        if (value && typeof value === 'object' && 'filter' in value) {
          return handleSearchModel(
            key,
            value as ExcludeBooleanFilterModelType,
            inOperatorMultifilter
          );
        }

        return [`filter[${key}]`, value];
      })
    ),
    ...inOperatorMultifilter,
  };
};
