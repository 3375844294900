import { Event } from '@/api/events/types';

type TOrganizationLevelPermissionsComposerProps = {
  organizationLevelScopeRecords?: { organizationId: string; scopeId: string }[];
  organizationsMap: Record<string, string>;
  scopesMap: Record<string, string>;
};
type TOrganizationLevelScopesComposerProps = {
  organizationLevelScopeRecords?: { organizationId: string; scopeId: string }[];
  scopesMap: Record<string, string>;
};

type TEventLevelPermissionsComposerProps = {
  eventLevelScopeRecords?: { eventId: string; scopeId: string }[];
  eventsMap: Record<string, Event>;
  scopesMap: Record<string, string>;
  organizationsMap: Record<string, string>;
};
type TEventLevelScopesComposerProps = {
  eventLevelScopeRecords?: { eventId: string; scopeId: string }[];
  eventsMap: Record<string, Event>;
  scopesMap: Record<string, string>;
};

export type TNestedPermissionItem = Record<string, { label: string; value: string }[]>;
export type TOrganizationLevelScopes = { [eventId: string]: string[] };
type TEventPermissionsPerOrganization = { [eventId: string]: TNestedPermissionItem };

export type TEventLevelPermissions = {
  [eventIdNameKey: string]: TNestedPermissionItem;
};
export type TEventLevelScopes = {
  [organizationId: string]: TOrganizationLevelScopes;
};

/*
  format organizationLevelScopeRecords to:
      {
         organizationId#organizationName: [{label, value}, {label, value}],
      }
   */
export const composeOrganizationLevelPermissions = ({
  organizationLevelScopeRecords = [],
  organizationsMap,
  scopesMap,
}: TOrganizationLevelPermissionsComposerProps): TNestedPermissionItem => {
  const organizationPermissions = organizationLevelScopeRecords.reduce(
    (acc: Record<string, { label: string; value: string }[]>, organizationLevelScopeRecord) => {
      const { organizationId = '', scopeId } = organizationLevelScopeRecord;
      const organizationName = organizationsMap[organizationId] || '';
      const organizationIdNameKey = `${organizationId}#${organizationName}`;
      const scopeName = scopesMap[scopeId] || '';

      return {
        ...acc,
        [organizationIdNameKey]: [
          ...(acc[organizationIdNameKey] || []),
          { label: scopeName, value: scopeId },
        ],
      };
    },
    {}
  );

  return organizationPermissions;
};
/*
  format organizationLevelScopeRecords to:
      {
         organizationId: [scopeName, scopeName, scopeName],
      }
   */
export const composeOrganizationLevelScopes = ({
  organizationLevelScopeRecords = [],
  scopesMap,
}: TOrganizationLevelScopesComposerProps): TOrganizationLevelScopes => {
  const organizationLevelScopes = organizationLevelScopeRecords.reduce(
    (acc: Record<string, string[]>, organizationLevelScopeRecord) => {
      const { organizationId = '', scopeId } = organizationLevelScopeRecord;
      const scopeName = scopesMap[scopeId] || '';

      return {
        ...acc,
        [organizationId]: [...(acc[organizationId] || []), scopeName],
      };
    },
    {}
  );

  return organizationLevelScopes;
};

export const composeEventLevelPermissions = ({
  eventLevelScopeRecords = [],
  eventsMap,
  scopesMap,
  organizationsMap,
}: TEventLevelPermissionsComposerProps): TEventLevelPermissions => {
  const someOrgsSomeEventsPermissions = eventLevelScopeRecords.reduce(
    (acc: TEventPermissionsPerOrganization, eventLevelScopeRecord) => {
      const { eventId, scopeId } = eventLevelScopeRecord;
      const { organizationId = '', fullName: eventFullName = '' } = eventsMap[eventId] || {};
      const organizationName = organizationsMap[organizationId] || '';
      const organizationIdNameKey = `${organizationId}#${organizationName}`;
      const eventIdNameKey = `${eventId}#${eventFullName}`;
      const scopeName = scopesMap[scopeId] || '';

      return {
        ...acc,
        [organizationIdNameKey]: {
          ...(acc[organizationIdNameKey] || {}),
          [eventIdNameKey]: [
            ...(acc[organizationIdNameKey]?.[eventIdNameKey] || []),
            { label: scopeName, value: scopeId },
          ],
        },
      };
    },
    {}
  );
  return someOrgsSomeEventsPermissions;
};

/*
  format organizationLevelScopeRecords to:
      {
         organizationId1: {
           eventId1: [scopeName, scopeName, scopeName],
           eventId2: [scopeName, scopeName, scopeName],
         }, 
         organizationId2: {
           eventId3: [scopeName, scopeName, scopeName],
           eventId4: [scopeName, scopeName, scopeName],
         }, 
      }
   */
export const composeEventLevelScopes = ({
  eventLevelScopeRecords = [],
  eventsMap,
  scopesMap,
}: TEventLevelScopesComposerProps): TEventLevelScopes => {
  const eventLevelScopes = eventLevelScopeRecords.reduce(
    (acc: TEventLevelScopes, eventLevelScopeRecord) => {
      const { eventId, scopeId } = eventLevelScopeRecord;
      const { organizationId = '' } = eventsMap[eventId] || {};
      const scopeName = scopesMap[scopeId] || '';

      const a = {
        ...acc,
        [organizationId]: {
          ...(acc[organizationId] || {}),
          [eventId]: [...(acc[organizationId]?.[eventId] || []), scopeName],
        },
      };
      return a;
    },
    {}
  );
  return eventLevelScopes;
};
