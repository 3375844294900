import { MutationConfig, queryClient } from '@/lib/react-query';

import { Organization } from '@/api/organizations/types';
import { addNotification } from '@/lib/notifications';
import { axios } from '@/lib/axios';
import { useMutation } from 'react-query';

export const deleteOrganization = async (id: string): Promise<Organization> => {
  return axios.delete<any, Organization>(`/organizations/v2/${id}`);
};
export type UseDeleteOrganizationOptions = {
  config?: MutationConfig<typeof deleteOrganization>;
};

export const useDeleteOrganization = ({ config }: UseDeleteOrganizationOptions = {}) => {
  return useMutation({
    onMutate: async (deletedOrganizationId: string) => {
      await queryClient.cancelQueries(['organizations']);
      const previousOrganizations = queryClient.getQueryData<Organization[]>(['organizations']);
      queryClient.setQueryData(
        ['organizations'],
        previousOrganizations?.filter((organization) => organization.id !== deletedOrganizationId)
      );

      return { previousOrganizations };
    },
    onError: (error, __, context: any) => {
      if (context?.previousOrganizations) {
        queryClient.setQueryData(['organizations'], context.previousOrganizations);
      }
      addNotification({
        type: 'error',
        message: error.message,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['organizations']);
      addNotification({
        type: 'success',
        message: `You have successfully deleted the organization!`,
      });
    },
    ...config,
    mutationFn: deleteOrganization,
  });
};
