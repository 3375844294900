import {
  CreateOrganizationScopeUserPayload,
  OrganizationScopeUser,
} from '@/api/organizationScopeUser/types';
import { MutationConfig, queryClient } from '@/lib/react-query';

import { addNotification } from '@/lib/notifications';
import { axios } from '@/lib/axios';
import { useMutation } from 'react-query';

export const createOrganizationScopeUser = async (
  data: CreateOrganizationScopeUserPayload
): Promise<OrganizationScopeUser> => {
  return axios.post<any, OrganizationScopeUser>('/organization-scope-user/v2', data);
};

export type UseCreateOrganizationScopeUserOptions = {
  config?: MutationConfig<typeof createOrganizationScopeUser>;
  data?: CreateOrganizationScopeUserPayload;
};

export const useCreateOrganizationScopeUser = ({
  config,
}: UseCreateOrganizationScopeUserOptions = {}) => {
  return useMutation({
    onMutate: async (newOrganizationScopeUser) => {
      await queryClient.cancelQueries(['organization-scope-user']);
      const previousOrganizationScopeUser = queryClient.getQueryData<OrganizationScopeUser[]>([
        'organization-scope-user',
      ]);
      queryClient.setQueryData(
        ['organization-scope-user'],
        [...(previousOrganizationScopeUser || []), newOrganizationScopeUser]
      );
      return { previousOrganizationScopeUser };
    },
    onError: (error, __, context: any) => {
      if (context?.previousOrganizationScopeUser) {
        queryClient.setQueryData(
          ['organization-scope-user'],
          context.previousOrganizationScopeUser
        );
      }
      addNotification({
        type: 'error',
        message: error.message,
      });
    },
    onSuccess: async (data) => {
      queryClient.invalidateQueries(['organization-scope-user']);

      return data;
    },
    ...config,
    mutationFn: createOrganizationScopeUser,
  });
};
