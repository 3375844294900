import { EventApiServiceHooksType, EventApiServiceType } from '@/api/events/types';
import {
  allEvents,
  eventById,
  useAllEvents,
  useEventById,
  useSearchEvents,
  useSearchInfiniteEvents,
} from '@/api/events/crud/get-event.api';
import { createEvent, useCreateEvent } from '@/api/events/crud/create-event.api';
import { deleteEvent, useDeleteEvent } from '@/api/events/crud/delete-event.api';
import { updateEvent, useUpdateEvent } from '@/api/events/crud/update-event.api';

export const EventApiServiceHooks: EventApiServiceHooksType = {
  useAllEvents,
  useSearchInfiniteEvents,
  useEventById,
  useSearchEvents,
  useCreateEvent,
  useUpdateEvent,
  useDeleteEvent,
};

export const EventApiService: EventApiServiceType = {
  allEvents,
  eventById,
  createEvent,
  updateEvent,
  deleteEvent,
};
