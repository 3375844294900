import { Button } from '@/components/Button';
import { CurrentUserProvider } from '@/providers/CurrentUserProvider';
import { ErrorBoundary } from 'react-error-boundary';
import { GlobalStyles } from '@/styles/Global.style';
import { QueryClientProvider } from 'react-query';
import { ReactNode } from 'react';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Result } from 'antd';
import { ThemeProvider } from 'styled-components';
import { queryClient } from '@/lib/react-query';
import { theme } from '@/styles/theme';

const ErrorFallback: any = () => {
  const onClick = () => window.location.assign(window.location.origin);
  return (
    <Result
      status="500"
      title="Application Error"
      subTitle="Sorry, something went wrong."
      extra={<Button onClick={onClick}>Refresh</Button>}
    />
  );
};

export const AppProvider = ({ children }: { children: ReactNode }) => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      {/* This is a known error, here is an issue on GitHub https://github.com/bvaughn/react-error-boundary/issues/111
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore  */}
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <QueryClientProvider client={queryClient}>
          {process.env.NODE_ENV !== 'test' && <ReactQueryDevtools />}
          <CurrentUserProvider>{children}</CurrentUserProvider>
        </QueryClientProvider>
      </ErrorBoundary>
    </ThemeProvider>
  );
};
