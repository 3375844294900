import { UserApiServiceHooksType, UserApiServiceType } from '@/api/users/types';
import {
  allUsers,
  allUsersWithIntegrationName,
  useAllUsers,
  useSearchUsers,
  useUserById,
  userById,
} from '@/api/users/crud/get-user.api';
import { createUser, useCreateUser } from '@/api/users/crud/create-user.api';
import { deleteUser, useDeleteUser } from '@/api/users/crud/delete-user.api';
import { updateUser, useUpdateUser } from '@/api/users/crud/update-user.api';

export const UserApiServiceHooks: UserApiServiceHooksType = {
  useAllUsers,
  useUserById,
  useSearchUsers,
  useCreateUser,
  useDeleteUser,
  useUpdateUser,
};

export const UserApiService: UserApiServiceType = {
  allUsers,
  allUsersWithIntegrationName,
  userById,
  createUser,
  deleteUser,
  updateUser,
};
