import { BRAND_BLUE, WHITE } from '@/styles/theme';
import styled, { css } from 'styled-components';

const BaseIconStyles = css`
  display: inherit;
  cursor: pointer;
  svg {
    height: 20px;
  }
`;

export const StyledIcon = styled.div`
  ${BaseIconStyles}
`;

interface ISidebarIconStyle {
  selected?: boolean;
}

export const SidebarIconStyle = styled.span<ISidebarIconStyle>`
  ${BaseIconStyles}
  background: ${({ selected }) => (selected ? BRAND_BLUE : WHITE)};
  border-radius: 12px;
  padding: 7px 8.8px 8px 8px;
  margin: 0 10px 0 0;
`;
