import { MutationConfig, queryClient } from '@/lib/react-query';

import { Contenttopic } from '@/api/contenttopics/types';
import { addNotification } from '@/lib/notifications';
import { axios } from '@/lib/axios';
import { useMutation } from 'react-query';

export const deleteContenttopic = async (id: string): Promise<Contenttopic> => {
  return axios.delete<any, Contenttopic>(`/contenttopics/v2/${id}`);
};

export type UseDeleteContenttopicOptions = {
  config?: MutationConfig<typeof deleteContenttopic>;
};

export const useDeleteContenttopic = ({ config }: UseDeleteContenttopicOptions = {}) => {
  return useMutation({
    onMutate: async (deletedContenttopicId: string) => {
      await queryClient.cancelQueries(['contenttopics']);
      const previousContenttopics = queryClient.getQueryData<Contenttopic[]>(['contenttopics']);
      queryClient.setQueryData(
        ['contenttopics'],
        previousContenttopics?.filter((contenttopic) => contenttopic.id !== deletedContenttopicId)
      );

      return { previousContenttopics };
    },
    onError: (error, __, context: any) => {
      if (context?.previousContenttopics) {
        queryClient.setQueryData(['contenttopics'], context.previousContenttopics);
      }
      addNotification({
        type: 'error',
        message: error.message,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['contenttopics']);
      addNotification({
        type: 'success',
        message: `You have successfully deleted the contenttopic!`,
      });
    },
    ...config,
    mutationFn: deleteContenttopic,
  });
};
