import { WHITE } from '@/styles/theme';
import { css } from 'styled-components';

export const TypographyBase = css`
  color: ${WHITE};
`;
export const ButtonTypography = css`
  ${TypographyBase}
  font-weight: bold;
  text-align: center;
`;
