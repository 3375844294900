import { Card } from 'antd';
import { StyledCard } from '@/features/publicPages/HomepageCard.style';
const { Meta } = Card;

export const HomepageCard = ({ src, title }: { src: string; title: string }) => {
  return (
    <StyledCard cover={<img alt={title} src={src} />}>
      <Meta title={title} />
    </StyledCard>
  );
};
