import styled, { css } from 'styled-components';

import { Layout as AntLayout } from 'antd';
import { LIGHT_GRAY } from '@/styles/theme';

const { Sider: AntSider, Content: AntContent, Header: AntHeader, Footer: AntFooter } = AntLayout;

const footerHeight = 100;

export const BaseHeaderStyles = css`
  background-color: ${LIGHT_GRAY};
  height: 110px;
  display: flex;
  padding: 24px;
  line-height: 1.5;
  justify-content: space-between;
`;

export const Layout = styled(AntLayout)`
  min-height: calc(100vh - ${footerHeight}px);
  background-color: ${LIGHT_GRAY};
`;
export const Sider = styled(AntSider)`
  background-color: ${LIGHT_GRAY};
`;
export const Content = styled(AntContent)`
  background-color: ${LIGHT_GRAY};
  padding: 24px;
  margin: 0;
`;
export const Header = styled(AntHeader)`
  ${BaseHeaderStyles}
`;

export const Footer = styled(AntFooter)`
  background-color: ${LIGHT_GRAY};
  padding: 24px;
  margin: 0;
  min-height: ${footerHeight}px;
`;
