import { Contenttopic, UpdateContenttopicPayload } from '@/api/contenttopics/types';
import { MutationConfig, queryClient } from '@/lib/react-query';

import { addNotification } from '@/lib/notifications';
import { axios } from '@/lib/axios';
import { useMutation } from 'react-query';

export const updateContenttopic = async ({
  data,
  id,
}: UpdateContenttopicPayload): Promise<Contenttopic> =>
  axios.patch(`/contenttopics/v2/${id}`, data);

export type UseUpdateContenttopicOptions = {
  config?: MutationConfig<typeof updateContenttopic>;
  data?: UpdateContenttopicPayload;
};

export const useUpdateContenttopic = ({ config }: UseUpdateContenttopicOptions = {}) => {
  return useMutation({
    onMutate: async (updatingContenttopic) => {
      await queryClient.cancelQueries(['contenttopic', updatingContenttopic?.id]);
      const previousContenttopic = queryClient.getQueryData<Contenttopic>([
        'contenttopic',
        updatingContenttopic?.id,
      ]);
      queryClient.setQueryData(['contenttopic', updatingContenttopic?.id], {
        ...previousContenttopic,
        ...updatingContenttopic.data,
        id: updatingContenttopic.id,
      });

      return { previousContenttopic };
    },
    onError: (error, __, context: any) => {
      if (context?.previousContenttopic) {
        queryClient.setQueryData(
          ['contenttopic', context.previousContenttopic.id],
          context.previousContenttopic
        );
      }
      addNotification({
        type: 'error',
        message: error.message,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['contenttopics']);
      addNotification({
        type: 'success',
        message: 'You have successfully updated this contenttopic!',
      });
    },
    ...config,
    mutationFn: updateContenttopic,
  });
};
