import {
  BLACK,
  LIGHT_GRAY,
  MODAL_BG_GRAY,
  MODAL_BORDER_GRAY,
  PUBLIC_PAGE_ELEMENT_DARK_BLUE,
  PUBLIC_PAGE_ELEMENT_ORANGE,
  Theme,
  WHITE,
} from '@/styles/theme';
import styled, { css } from 'styled-components';

import { Button as AntButton } from 'antd';
import { ButtonTypography } from '@/styles/typography.style';
import { FunctionComponent } from 'react';
import { IButtonProps } from '@/components/Button';

type ButtonPropsWithTheme = IButtonProps & { theme: Theme };

export const BaseButtonStyles = css`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  text-transform: uppercase;
  min-height: 35px;
  min-width: 150px;
  border: none;
  ${ButtonTypography}

  span {
    flex-grow: 0;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: var(--white);
  }
`;

export const BaseButtonProps = css`
  ${(props: ButtonPropsWithTheme) =>
    props.type === 'primary' &&
    css`
      background-color: ${props.theme.colors.primary}!important;
      background: ${props.theme.colors.primary}!important;

      &:hover {
        color: ${props.theme.colors.primary};
        background-color: ${WHITE}!important;
        background: ${WHITE} !important;
        outline: 1px solid ${props.theme.colors.primary};
      }
    `}
  ${(props: ButtonPropsWithTheme) =>
    props.type === 'public-pages-orange' &&
    css`
      border-radius: 8px;
      background-color: ${PUBLIC_PAGE_ELEMENT_ORANGE}!important;
      background: ${PUBLIC_PAGE_ELEMENT_ORANGE}!important;
      text-transform: initial;

      span {
        font-weight: 400;
        font-size: 14px;
        color: ${PUBLIC_PAGE_ELEMENT_DARK_BLUE};
      }

      &:hover {
        color: ${PUBLIC_PAGE_ELEMENT_DARK_BLUE};
        background-color: ${WHITE}!important;
        background: ${WHITE} !important;
        outline: 1px solid ${PUBLIC_PAGE_ELEMENT_DARK_BLUE};
      }
    `}
  ${(props: ButtonPropsWithTheme) =>
    props.type === 'public-pages-light' &&
    css`
      border-radius: 8px;
      background-color: white !important;
      background: white !important;
      text-transform: initial;

      span {
        font-weight: 400;
        color: ${PUBLIC_PAGE_ELEMENT_DARK_BLUE};
      }

      &:hover {
        color: ${PUBLIC_PAGE_ELEMENT_ORANGE};
        background-color: ${WHITE}!important;
        background: ${WHITE} !important;
        outline: 1px solid ${PUBLIC_PAGE_ELEMENT_ORANGE};
      }
    `}

  ${(props: ButtonPropsWithTheme) =>
    props.type === 'secondary' &&
    css`
      background-color: ${props.theme.colors.secondary}!important;
      background: ${props.theme.colors.secondary}!important;
    `}

        
  ${(props: ButtonPropsWithTheme) =>
    props.type === 'danger' &&
    css`
      background-color: ${props.theme.colors.danger}!important;
      background: ${props.theme.colors.danger}!important;

      &:hover {
        color: ${props.theme.colors.danger};
        background-color: ${WHITE}!important;
        background: ${WHITE} !important;
        outline: 1px solid ${props.theme.colors.danger};
      }
    `}

    ${(props: ButtonPropsWithTheme) =>
    props.type === 'tab' &&
    css`
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      min-height: 50px;
      width: 100%;
      max-width: 325px;
      color: ${BLACK};
      background-color: ${WHITE}!important;
      background: ${WHITE} !important;
      outline: 1px solid ${props.theme.colors.secondary};

      span {
        font-size: 16px;
        font-weight: 500;
      }

      &:hover {
        color: ${BLACK};
        background-color: ${MODAL_BG_GRAY}!important;
        background: ${MODAL_BG_GRAY}!important;
      }
      &:focus {
        color: ${BLACK};
        background-color: ${WHITE}!important;
        background: ${WHITE} !important;
        outline: 1px solid ${props.theme.colors.secondary};
      }
    `}

    ${(props: ButtonPropsWithTheme) =>
    props.type === 'unstyled' &&
    css`
      border: none;
      background-color: transparent;
      height: 100%;

      &:hover {
        background-color: transparent;
      }
    `}

    ${(props: ButtonPropsWithTheme) =>
    props.type === 'outlined-animated' &&
    css`
      color: ${props.theme.colors.danger};
      outline: 1px solid ${MODAL_BORDER_GRAY};
      text-shadow: none;
      min-height: 40px;
      width: auto;
      min-width: 46px;
      max-width: 46px;
      overflow: hidden;
      width: auto;
      -webkit-transition: max-width 0.5s;
      transition: max-width 0.5s;
      justify-content: flex-start;
      text-transform: capitalize;
      gap: 8px;

      &:hover {
        color: ${props.theme.colors.danger};
        outline: 1px solid ${props.theme.colors.danger};
        max-width: 300px;
      }

      span:first-child {
        font-size: 16px;
      }
    `}
`;

export const StyledButton: FunctionComponent<IButtonProps> = styled(AntButton)`
  ${BaseButtonStyles}

  ${BaseButtonProps}

    ${(props: ButtonPropsWithTheme) =>
    props.type === 'editOrg' &&
    css`
      min-width: unset;
      max-width: 35px;
      background-color: ${LIGHT_GRAY};
      margin: 0 10px 0 0;
      display: contents;

      &:hover {
        background-color: ${LIGHT_GRAY};
      }
    `}

    ${(props: ButtonPropsWithTheme) =>
    props.type === 'link' &&
    css`
      color: ${props.theme.colors.primary};
    `}

    ${(props: ButtonPropsWithTheme) =>
    props.type === 'link-end' &&
    css`
      color: ${props.theme.colors.primary};
      justify-content: flex-end;
    `}
`;
