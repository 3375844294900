import { FilterModelType, filterParamsToQueryParams } from '@/api/helpers';
import { Integration, IntegrationWithMeta } from '@/api/integrations/types';
import { useInfiniteQuery, useQuery } from 'react-query';

import { ApiDataResponseAnatomy } from '@/api/api.types';
import { ExtractFnReturnType } from '@/lib/react-query';
import { axios } from '@/lib/axios';

export type FilterIntegrationsQueryParams = Partial<
  {
    [property in keyof Integration]: string;
  } & { page: number; filterModel: FilterModelType }
>;

export const allIntegrations = async (searchParams: FilterIntegrationsQueryParams = {}) => {
  const params = filterParamsToQueryParams(searchParams);

  const response = await axios.get<IntegrationWithMeta, ApiDataResponseAnatomy<Integration>>(
    `/integrations/v2`,
    {
      params,
    }
  );

  const { collection, _meta } = response;
  return { collection, meta: _meta };
};

export type QueryGetIntegrationsFnType = typeof allIntegrations;

export const integrationById = async (id: string) =>
  axios.get<Integration, Integration>(`/integrations/v2/${id}`);

type QueryGetIntegrationFnType = typeof integrationById;

export const useAllIntegrations = (config = {}) => {
  return useQuery<ExtractFnReturnType<QueryGetIntegrationsFnType>>({
    queryKey: ['integrations'],
    queryFn: () => allIntegrations(),
    ...config,
  });
};

export const useInfiniteIntegrations = (config = {}) => {
  return useInfiniteQuery<ExtractFnReturnType<QueryGetIntegrationsFnType>>({
    queryKey: ['integrations'],
    queryFn: ({ pageParam = 1 }) => allIntegrations({ page: pageParam }),
    getPreviousPageParam: (firstPageData, allPagesData) => {
      const lastPageData = allPagesData.at(-1);
      if (!lastPageData?.meta) {
        // this should never happen unless the API returns an error
        return undefined;
      }
      const previousPage = lastPageData.meta.currentPageNumber - 1;

      if (previousPage <= 1) {
        // no previous page
        return undefined;
      }
      return previousPage;
    },
    getNextPageParam: (lastPageData) => {
      if (!lastPageData?.meta) {
        // this should never happen unless the API returns an error
        return undefined;
      }
      const nextPage = lastPageData.meta.currentPageNumber + 1;
      if (nextPage > lastPageData.meta.totalNumberOfPages) {
        // no more pages
        return undefined;
      }
      return nextPage;
    },
    ...config,
  });
};

export const useSearchIntegrations = (filterParams: FilterIntegrationsQueryParams, config = {}) => {
  const queryParams = filterParamsToQueryParams(filterParams);
  return useQuery<ExtractFnReturnType<QueryGetIntegrationsFnType>>({
    queryKey: ['integrations', queryParams],
    queryFn: () => allIntegrations(queryParams),
    ...config,
  });
};

export const useIntegrationById = (id: string, config = {}) => {
  return useQuery<ExtractFnReturnType<QueryGetIntegrationFnType>>({
    queryKey: ['integrations', id],
    queryFn: () => integrationById(id),
    placeholderData: {} as Integration,
    ...config,
  });
};
