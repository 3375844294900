import { Button } from '@/components/Button';
import { Result } from 'antd';
import { useCurrentUser } from '@/providers/CurrentUserProvider';
import { useNavigate } from 'react-router-dom';

type ContentForbiddenProps = {
  title?: string;
  subTitle?: string;
  removeActions?: boolean;
};

export const ContentForbidden = ({
  title = 'Permission Denied',
  subTitle = 'Sorry, you are not authorized to access this page.',
  removeActions = false,
}: ContentForbiddenProps) => {
  const navigate = useNavigate();
  const { logout } = useCurrentUser();

  const navigateToHomepage = () => {
    navigate('/');
  };
  return (
    <Result
      status="403"
      title={title}
      subTitle={subTitle}
      extra={
        !removeActions && (
          <>
            <Button onClick={navigateToHomepage}>Go To Homepage</Button>
            <Button onClick={logout}>Logout</Button>
          </>
        )
      }
    />
  );
};
