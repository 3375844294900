import { StyledInput, StyledSearchInput } from './Form.style';

import { Icon } from '@/components/Icon';
import { InputProps } from 'antd';

export const QuickSearchInput = ({
  size = 'small',
  placeholder = 'Enter text',
  id = 'quicksearch-input',
  ...rest
}: InputProps) => {
  return (
    <StyledSearchInput
      id={id}
      allowClear
      size={size}
      placeholder={placeholder}
      prefix={<Icon name="search" />}
      {...rest}
    />
  );
};

export const TextInput = ({
  size = 'small',
  placeholder = 'Enter text',
  id = 'text-input',
  ...rest
}: InputProps) => {
  return <StyledInput id={id} allowClear size={size} placeholder={placeholder} {...rest} />;
};
