import { ActionConditions, Actions, ScopeLevel } from '@/components/Permissions/constants';

/*
   Pages that can be viewed without any scopes:
   - /auth/profile
*/
export const routeToRequiredScopeMap: Record<string, string> = {
  '/app/events': 'events:read',
  '/app/organizations': 'organizations:read',
  '/app/organizations/users': 'organizations:read',
  '/app/organizations/webhooks': 'webhooks:read',
  '/app/data_explorer': 'presentation-data:read',
  '/app/applications': 'applications:read',
  '/app/users': 'users:read',
  '/app/integrations': 'integrations:write',
  '/app/configurations': 'config-management:read',
  '/app/session_formats': 'config-management:read',
  '/app/subsession_formats': 'config-management:read',
  '/app/poster_formats': 'config-management:read',
  '/app/social_media_types': 'config-management:read',
  '/auth/create-application': 'applications:write',
  '/auth/create-user': 'users:write',
  '/auth/update-user': 'users:write',
  '/auth/update-application': 'applications:write',
  '/app/content_management': 'content-management:read',
  '/app/content_categories': 'content-management:read',
  '/app/content_topics': 'content-management:read',
  '/app/content_tags': 'content-management:read',
};

export const actionToScopeRequirementsMap: {
  [actionName: string]: {
    requiredScope: string;
    scopeLevel: ScopeLevel[];
    additionalConditions?: ActionConditions[];
  };
} = {
  [Actions.AddEvent]: {
    requiredScope: 'events:write',
    scopeLevel: [ScopeLevel.Top, ScopeLevel.AnyOrganization],
    additionalConditions: [ActionConditions.AllowedToReadAtLeastOneOrganization],
  },
  [Actions.ReadEvent]: {
    requiredScope: 'events:read',
    scopeLevel: [ScopeLevel.Top, ScopeLevel.AnyOrganization],
  },
  [Actions.WriteEvent]: {
    requiredScope: 'events:write',
    scopeLevel: [ScopeLevel.Top, ScopeLevel.AnyOrganization],
  },
  [Actions.UpdateEvent]: {
    requiredScope: 'events:write',
    scopeLevel: [ScopeLevel.Top, ScopeLevel.SingleOrganization, ScopeLevel.SingleEvent],
  },
  [Actions.DeleteEvent]: {
    requiredScope: 'events:delete',
    scopeLevel: [ScopeLevel.Top, ScopeLevel.SingleOrganization, ScopeLevel.SingleEvent],
  },
  [Actions.AddOrganization]: {
    requiredScope: 'organizations:write',
    scopeLevel: [ScopeLevel.Top],
  },
  [Actions.ReadSingleOrganization]: {
    requiredScope: 'organizations:read',
    scopeLevel: [ScopeLevel.Top, ScopeLevel.SingleOrganization],
  },
  [Actions.ReadOrganizations]: {
    requiredScope: 'organizations:read',
    scopeLevel: [ScopeLevel.Top, ScopeLevel.AnyOrganization],
  },
  [Actions.UpdateOrganization]: {
    requiredScope: 'organizations:write',
    scopeLevel: [ScopeLevel.Top, ScopeLevel.SingleOrganization],
  },
  [Actions.DeleteOrganization]: {
    requiredScope: 'organizations:delete',
    scopeLevel: [ScopeLevel.Top, ScopeLevel.SingleOrganization],
  },
  [Actions.ReadApplication]: {
    requiredScope: 'applications:read',
    scopeLevel: [ScopeLevel.Top],
  },
  [Actions.CreateApplication]: {
    requiredScope: 'applications:write',
    scopeLevel: [ScopeLevel.Top],
  },
  [Actions.UpdateApplication]: {
    requiredScope: 'applications:write',
    scopeLevel: [ScopeLevel.Top],
  },
  [Actions.DeleteApplication]: {
    requiredScope: 'applications:delete',
    scopeLevel: [ScopeLevel.Top],
  },
  [Actions.ReadUser]: {
    requiredScope: 'users:read',
    scopeLevel: [ScopeLevel.Top],
  },
  [Actions.CreateUser]: {
    requiredScope: 'users:write',
    scopeLevel: [ScopeLevel.Top],
  },
  [Actions.UpdateUser]: {
    requiredScope: 'users:write',
    scopeLevel: [ScopeLevel.Top],
    additionalConditions: [ActionConditions.CannotBePerformedOnCurrentUser],
  },
  [Actions.DeleteUser]: {
    requiredScope: 'users:delete',
    scopeLevel: [ScopeLevel.Top],
    additionalConditions: [ActionConditions.CannotBePerformedOnCurrentUser],
  },
  [Actions.ReadPresentationData]: {
    requiredScope: 'presentation-data:read',
    scopeLevel: [ScopeLevel.Top, ScopeLevel.SingleOrganization, ScopeLevel.SingleEvent],
  },
  [Actions.WritePresentationData]: {
    requiredScope: 'presentation-data:write',
    scopeLevel: [ScopeLevel.Top, ScopeLevel.SingleOrganization, ScopeLevel.SingleEvent],
  },
  [Actions.DeletePresentationData]: {
    requiredScope: 'presentation-data:delete',
    scopeLevel: [ScopeLevel.Top, ScopeLevel.SingleOrganization, ScopeLevel.SingleEvent],
  },
  [Actions.ReadRegistrationData]: {
    requiredScope: 'registration-data:read',
    scopeLevel: [ScopeLevel.Top, ScopeLevel.SingleOrganization, ScopeLevel.SingleEvent],
  },
  [Actions.WriteRegistrationData]: {
    requiredScope: 'registration-data:write',
    scopeLevel: [ScopeLevel.Top, ScopeLevel.SingleOrganization, ScopeLevel.SingleEvent],
  },
  [Actions.DeleteRegistrationData]: {
    requiredScope: 'registration-data:delete',
    scopeLevel: [ScopeLevel.Top, ScopeLevel.SingleOrganization, ScopeLevel.SingleEvent],
  },
  [Actions.AddWebhooksubscription]: {
    requiredScope: 'webhooks:write',
    scopeLevel: [ScopeLevel.Top, ScopeLevel.SingleOrganization, ScopeLevel.SingleEvent],
  },
  [Actions.UpdateWebhooksubscription]: {
    requiredScope: 'webhooks:write',
    scopeLevel: [ScopeLevel.Top, ScopeLevel.SingleOrganization, ScopeLevel.SingleEvent],
  },
  [Actions.DeleteWebhooksubscription]: {
    requiredScope: 'webhooks:delete',
    scopeLevel: [ScopeLevel.Top, ScopeLevel.SingleOrganization, ScopeLevel.SingleEvent],
  },
  [Actions.BackToWebhooksubscription]: {
    requiredScope: 'webhooks:read',
    scopeLevel: [ScopeLevel.Top, ScopeLevel.AnyOrganization],
  },
  [Actions.ReadWebhooks]: {
    requiredScope: 'webhooks:read',
    scopeLevel: [ScopeLevel.Top, ScopeLevel.SingleOrganization, ScopeLevel.SingleEvent],
  },
  [Actions.CreateSocialmediatype]: {
    requiredScope: 'config-management:write',
    scopeLevel: [ScopeLevel.Top, ScopeLevel.AnyOrganization],
  },
  [Actions.ReadSocialmediatype]: {
    requiredScope: 'config-management:read',
    scopeLevel: [ScopeLevel.Top, ScopeLevel.AnyOrganization],
  },
  [Actions.UpdateSocialmediatype]: {
    requiredScope: 'config-management:write',
    scopeLevel: [ScopeLevel.Top, ScopeLevel.AnyOrganization],
  },
  [Actions.DeleteSocialmediatype]: {
    requiredScope: 'config-management:delete',
    scopeLevel: [ScopeLevel.Top, ScopeLevel.AnyOrganization],
  },
  [Actions.ReadExhibitorData]: {
    requiredScope: 'exhibitor-data:read',
    scopeLevel: [ScopeLevel.Top, ScopeLevel.SingleOrganization, ScopeLevel.SingleEvent],
  },
  [Actions.WriteExhibitorData]: {
    requiredScope: 'exhibitor-data:write',
    scopeLevel: [ScopeLevel.Top, ScopeLevel.SingleOrganization, ScopeLevel.SingleEvent],
  },
  [Actions.DeleteExhibitorData]: {
    requiredScope: 'exhibitor-data:delete',
    scopeLevel: [ScopeLevel.Top, ScopeLevel.SingleOrganization, ScopeLevel.SingleEvent],
  },
  [Actions.ReadConfigManagement]: {
    requiredScope: 'config-management:read',
    scopeLevel: [ScopeLevel.Top, ScopeLevel.AnyOrganization],
  },
  [Actions.WriteConfigManagement]: {
    requiredScope: 'config-management:write',
    scopeLevel: [ScopeLevel.Top, ScopeLevel.AnyOrganization],
  },
  [Actions.DeleteConfigManagement]: {
    requiredScope: 'config-management:delete',
    scopeLevel: [ScopeLevel.Top, ScopeLevel.AnyOrganization],
  },
  [Actions.CreateSessionformat]: {
    requiredScope: 'config-management:write',
    scopeLevel: [ScopeLevel.Top, ScopeLevel.AnyOrganization],
  },
  [Actions.ReadSessionformat]: {
    requiredScope: 'config-management:read',
    scopeLevel: [ScopeLevel.Top, ScopeLevel.AnyOrganization],
  },
  [Actions.UpdateSessionformat]: {
    requiredScope: 'config-management:write',
    scopeLevel: [ScopeLevel.Top, ScopeLevel.AnyOrganization],
  },
  [Actions.DeleteSessionformat]: {
    requiredScope: 'config-management:delete',
    scopeLevel: [ScopeLevel.Top, ScopeLevel.AnyOrganization],
  },
  [Actions.CreateSubsessionformat]: {
    requiredScope: 'config-management:write',
    scopeLevel: [ScopeLevel.Top, ScopeLevel.AnyOrganization],
  },
  [Actions.ReadSubsessionformat]: {
    requiredScope: 'config-management:read',
    scopeLevel: [ScopeLevel.Top, ScopeLevel.AnyOrganization],
  },
  [Actions.UpdateSubsessionformat]: {
    requiredScope: 'config-management:write',
    scopeLevel: [ScopeLevel.Top, ScopeLevel.AnyOrganization],
  },
  [Actions.DeleteSubsessionformat]: {
    requiredScope: 'config-management:delete',
    scopeLevel: [ScopeLevel.Top, ScopeLevel.AnyOrganization],
  },
  [Actions.CreatePosterformat]: {
    requiredScope: 'config-management:write',
    scopeLevel: [ScopeLevel.Top, ScopeLevel.AnyOrganization],
  },
  [Actions.ReadPosterformat]: {
    requiredScope: 'config-management:read',
    scopeLevel: [ScopeLevel.Top, ScopeLevel.AnyOrganization],
  },
  [Actions.UpdatePosterformat]: {
    requiredScope: 'config-management:write',
    scopeLevel: [ScopeLevel.Top, ScopeLevel.AnyOrganization],
  },
  [Actions.DeletePosterformat]: {
    requiredScope: 'config-management:delete',
    scopeLevel: [ScopeLevel.Top, ScopeLevel.AnyOrganization],
  },
  [Actions.ReadIntegrations]: {
    requiredScope: 'integrations:read',
    scopeLevel: [ScopeLevel.Top],
  },
  [Actions.AddIntegration]: {
    requiredScope: 'integrations:write',
    scopeLevel: [ScopeLevel.Top],
  },
  [Actions.UpdateIntegration]: {
    requiredScope: 'integrations:write',
    scopeLevel: [ScopeLevel.Top],
  },
  [Actions.DeleteIntegration]: {
    requiredScope: 'integrations:delete',
    scopeLevel: [ScopeLevel.Top],
  },
  [Actions.CreateContentcategory]: {
    requiredScope: 'content-management:write',
    scopeLevel: [ScopeLevel.Top],
  },
  [Actions.UpdateContentcategory]: {
    requiredScope: 'content-management:write',
    scopeLevel: [ScopeLevel.Top],
  },
  [Actions.DeleteContentcategory]: {
    requiredScope: 'content-management:delete',
    scopeLevel: [ScopeLevel.Top],
  },
  [Actions.CreateContenttopic]: {
    requiredScope: 'content-management:write',
    scopeLevel: [ScopeLevel.Top],
  },
  [Actions.UpdateContenttopic]: {
    requiredScope: 'content-management:write',
    scopeLevel: [ScopeLevel.Top],
  },
  [Actions.DeleteContenttopic]: {
    requiredScope: 'content-management:delete',
    scopeLevel: [ScopeLevel.Top],
  },
  [Actions.CreateContenttag]: {
    requiredScope: 'content-management:write',
    scopeLevel: [ScopeLevel.Top],
  },
  [Actions.UpdateContenttag]: {
    requiredScope: 'content-management:write',
    scopeLevel: [ScopeLevel.Top],
  },
  [Actions.DeleteContenttag]: {
    requiredScope: 'content-management:delete',
    scopeLevel: [ScopeLevel.Top],
  },
  [Actions.AddContentManagement]: {
    requiredScope: 'content-management:write',
    scopeLevel: [ScopeLevel.Top],
  },
  [Actions.ReadContentManagement]: {
    requiredScope: 'content-management:read',
    scopeLevel: [ScopeLevel.Top],
  },
  [Actions.UpdateContentManagement]: {
    requiredScope: 'content-management:write',
    scopeLevel: [ScopeLevel.Top],
  },
  [Actions.DeleteContentManagement]: {
    requiredScope: 'content-management:delete',
    scopeLevel: [ScopeLevel.Top],
  },
};
