import { TGranularLevelActionItem } from '@/features/auth0/clientFlowState/types';

export const composeEntitiesMissingReadScope = (actions: TGranularLevelActionItem[]) => {
  const scopes = new Set(actions.map((action) => action.label));

  const entitiesMissingReadScope = actions.reduce<Set<string>>((entitiesSet, action) => {
    if (action.label.endsWith(':read')) {
      return entitiesSet;
    }

    const [entity] = action.label.split(':');
    if (scopes.has(`${entity}:read` as TGranularLevelActionItem['label'])) {
      return entitiesSet;
    }

    return entitiesSet.add(entity);
  }, new Set());

  return [...entitiesMissingReadScope];
};
