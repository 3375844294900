import { Event, UpdateEventPayload } from '@/api/events/types';
import { MutationConfig, queryClient } from '@/lib/react-query';

import { addNotification } from '@/lib/notifications';
import { axios } from '@/lib/axios';
import { useMutation } from 'react-query';

export const updateEvent = async ({ data, id }: UpdateEventPayload): Promise<Event> =>
  axios.patch(`/events/v2/${id}`, data);

export type UseUpdateEventOptions = {
  config?: MutationConfig<typeof updateEvent>;
  data?: UpdateEventPayload;
};

export const useUpdateEvent = ({ config }: UseUpdateEventOptions = {}) => {
  return useMutation({
    onMutate: async (updatingEvent) => {
      await queryClient.cancelQueries(['event', updatingEvent?.id]);
      const previousEvent = queryClient.getQueryData<Event>(['event', updatingEvent?.id]);
      queryClient.setQueryData(['event', updatingEvent?.id], {
        ...previousEvent,
        ...updatingEvent.data,
        id: updatingEvent.id,
      });

      return { previousEvent };
    },
    onError: (error, __, context: any) => {
      if (context?.previousEvent) {
        queryClient.setQueryData(['event', context.previousEvent.id], context.previousEvent);
      }
      addNotification({
        type: 'error',
        message: error.message,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['events']);
      addNotification({
        type: 'success',
        message: 'You have successfully updated this event!',
      });
    },
    ...config,
    mutationFn: updateEvent,
  });
};
