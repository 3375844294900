import { Contentcategory, UpdateContentcategoryPayload } from '@/api/contentcategories/types';
import { MutationConfig, queryClient } from '@/lib/react-query';

import { addNotification } from '@/lib/notifications';
import { axios } from '@/lib/axios';
import { useMutation } from 'react-query';

export const updateContentcategory = async ({
  data,
  id,
}: UpdateContentcategoryPayload): Promise<Contentcategory> =>
  axios.patch(`/contentcategories/v2/${id}`, data);

export type UseUpdateContentcategoryOptions = {
  config?: MutationConfig<typeof updateContentcategory>;
  data?: UpdateContentcategoryPayload;
};

export const useUpdateContentcategory = ({ config }: UseUpdateContentcategoryOptions = {}) => {
  return useMutation({
    onMutate: async (updatingContentcategory) => {
      await queryClient.cancelQueries(['contentcategory', updatingContentcategory?.id]);
      const previousContentcategory = queryClient.getQueryData<Contentcategory>([
        'contentcategory',
        updatingContentcategory?.id,
      ]);
      queryClient.setQueryData(['contentcategory', updatingContentcategory?.id], {
        ...previousContentcategory,
        ...updatingContentcategory.data,
        id: updatingContentcategory.id,
      });

      return { previousContentcategory };
    },
    onError: (error, __, context: any) => {
      if (context?.previousContentcategory) {
        queryClient.setQueryData(
          ['contentcategory', context.previousContentcategory.id],
          context.previousContentcategory
        );
      }
      addNotification({
        type: 'error',
        message: error.message,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['contentcategories']);
      addNotification({
        type: 'success',
        message: 'You have successfully updated this contentcategory!',
      });
    },
    ...config,
    mutationFn: updateContentcategory,
  });
};
