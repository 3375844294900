import { CreateUserPayload, User } from '../types';
import { MutationConfig, queryClient } from '@/lib/react-query';

import { addNotification } from '@/lib/notifications';
import { axios } from '@/lib/axios';
import { useMutation } from 'react-query';

export const createUser = async (data: CreateUserPayload): Promise<User> => {
  return axios.post<any, User>('/users/v2', data);
};

export type UseCreateUserOptions = {
  config?: MutationConfig<typeof createUser>;
  data?: CreateUserPayload;
};

export const useCreateUser = ({ config }: UseCreateUserOptions = {}) => {
  return useMutation({
    onMutate: async (newUser) => {
      await queryClient.cancelQueries(['users']);
      const previousUsers = queryClient.getQueryData<User[]>(['users']);
      queryClient.setQueryData(['users'], [...(previousUsers || []), newUser]);
      return { previousUsers };
    },
    onError: (error, __, context: any) => {
      if (context?.previousUsers) {
        queryClient.setQueryData(['users'], context.previousUsers);
      }
      addNotification({
        type: 'error',
        message: error.message,
      });
    },
    onSuccess: async (data) => {
      queryClient.invalidateQueries(['users']);
      addNotification({
        type: 'success',
        message: 'New user created successfully!',
      });
      return data;
    },
    ...config,
    mutationFn: createUser,
  });
};
