/**
 * Detects the browser currently in use and returns a browser slug.
 */
export function detectBrowser() {
  let browserDetected = 'other';

  if (navigator.userAgent.indexOf('Edg') !== -1) {
    browserDetected = 'edge';
  } else if (navigator.userAgent.indexOf('Chrome') !== -1) {
    browserDetected = 'chrome';
  } else if (navigator.userAgent.indexOf('Safari') !== -1) {
    browserDetected = 'safari';
  } else if (navigator.userAgent.indexOf('Firefox') !== -1) {
    browserDetected = 'firefox';
  } else if (navigator.userAgent.indexOf('MSIE') !== -1 || !!document.documentMode === true) {
    browserDetected = 'ie';
  }

  return browserDetected;
}
