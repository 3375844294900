import { Contenttopic } from '@/api/contenttopics/types';
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import gfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import rehypeSlug from 'rehype-slug';
import rehypeTruncate from 'rehype-truncate';
import remarkToc from 'remark-toc';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import {
  StyledCard,
  StyledDate,
  TagsWrapper,
  StyledTag,
  StyledTitle,
  StyledBody,
  CardShowButton,
} from '@/features/updates/UpdatePage.style';
import moment from 'moment';
import { useState } from 'react';

type ContentCardProps = {
  data: Contenttopic;
};

export const ContentCard = ({ data }: ContentCardProps) => {
  const [showMore, setShowMore] = useState<boolean>(false);
  const formatedDate = moment(data.publishedAt).format('MMMM D, YYYY');
  const { tagData } = data;

  const handleShowButton = () => {
    setShowMore(!showMore);
  };

  const transformLinks = (link: string) => {
    if (/(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)/.exec(link)) {
      return link;
    }
    return `https://${link}`;
  };

  const getCleanedBody = (body: string) => {
    let cleanedBody = new DOMParser().parseFromString(body, 'text/html');
    return cleanedBody.body.textContent ?? '';
  };

  const cleanBody = getCleanedBody(data.body);

  const showButton = (cleanBody && cleanBody?.length > 50) || data.body.includes('<img');

  return (
    <StyledCard>
      <StyledDate>{formatedDate}</StyledDate>
      <TagsWrapper>
        {tagData?.map((tag) => {
          return <StyledTag key={tag.name}>{tag.name}</StyledTag>;
        })}
      </TagsWrapper>
      <StyledTitle>{data.title}</StyledTitle>
      <StyledBody>
        <ReactMarkdown
          remarkPlugins={[gfm, remarkToc]}
          rehypePlugins={[
            rehypeRaw,
            rehypeSlug,
            [rehypeTruncate, { ellipses: '...', maxChars: 50, disable: showMore }],
          ]}
          transformLinkUri={transformLinks}
          linkTarget={(href) => {
            if (
              href?.startsWith('http://') ||
              href?.startsWith('https://') ||
              href.includes('.com')
            ) {
              return '_blank';
            }
            return '_self';
          }}
          components={{
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            code({ node, inline, className, children, style, ...props }) {
              const match = /language-(\w+)/.exec(className || '');
              return !inline && match ? (
                <SyntaxHighlighter style={vscDarkPlus} language={match[1]} PreTag="div" {...props}>
                  {String(children).replace(/\n$/, '')}
                </SyntaxHighlighter>
              ) : (
                <code className={className} {...props}>
                  {children}
                </code>
              );
            },
          }}
        >
          {data.body}
        </ReactMarkdown>
      </StyledBody>
      {showButton && (
        <CardShowButton onClick={handleShowButton}>
          {showMore ? 'Show Less' : 'Show More'}
        </CardShowButton>
      )}
    </StyledCard>
  );
};
