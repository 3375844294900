export const freemanInternalUserEmailDomains = {
  'freeman.com': true,
  'freemanco.com': true,
} as const;

export const isInternalFreemanEmail = (email = '') => {
  const emailDomain = email.split('@')[1];
  if (!emailDomain) {
    return false;
  }
  return Object.hasOwn(freemanInternalUserEmailDomains, emailDomain);
};

// internal user id format in Auth0 is: samlp|<connection name>|<email>
// example: samlp|okta-test|john.connor@freemanco.com
export const composeInternalUserAuth0Id = (email = '') => {
  const oktaConnectionNameInAuth0 = process.env.REACT_APP_AUTH0_OKTA_CONNECTION_NAME;
  return `samlp|${oktaConnectionNameInAuth0}|${email.toLowerCase()}`;
};
