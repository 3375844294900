import { Card, Row, Typography } from 'antd';
import { HEADER_HEIGHT, HEADER_HEIGHT_MOBILE } from '@/components/Layout/PublicLayout.style';

import { deviceSmallerThan } from '@/styles/mediaqueries';
import styled from 'styled-components';

const { Title } = Typography;

// banner height + footer links + copywrite section
export const FOOTER_SECTION_HEIGHT = 360 + 130 + 48;
const FOOTER_SECTION_HEIGHT_V2 = 360;

const PUBLIC_PAGE_CONTENT_MIN_HEIGHT = `calc(100vh - ${HEADER_HEIGHT}px - ${FOOTER_SECTION_HEIGHT}px)`;
const PUBLIC_PAGE_CONTENT_MIN_HEIGHT_MOBILE = `calc(100vh - ${HEADER_HEIGHT_MOBILE}px - ${FOOTER_SECTION_HEIGHT}px)`;

const PUBLIC_PAGE_CONTENT_MIN_HEIGHT_V2 = `calc(100vh - ${HEADER_HEIGHT}px - ${FOOTER_SECTION_HEIGHT_V2}px)`;

export const PublicPageContainer = styled.div`
  min-height: ${PUBLIC_PAGE_CONTENT_MIN_HEIGHT};
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;

  ${deviceSmallerThan.tabletS} {
    min-height: ${PUBLIC_PAGE_CONTENT_MIN_HEIGHT_MOBILE};
  }
`;

export const PublicPageContainerv2 = styled.div`
  min-height: ${PUBLIC_PAGE_CONTENT_MIN_HEIGHT_V2};
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;

  ${deviceSmallerThan.tabletS} {
    min-height: ${PUBLIC_PAGE_CONTENT_MIN_HEIGHT_V2};
  }
`;

export const PublicPageHeroRow = styled(Row)`
  width: 100%;
  position: relative;

  :after {
    content: '';
    position: absolute;
    bottom: -45px;
    left: 20%;
    width: 90px;
    height: 90px;
    background-image: url('/images/Chevron-right.png');
    background-size: cover;
    z-index: 50;
  }
`;

export const DocsPageHeroRow = styled(Row)`
  width: 100%;
  position: relative;
`;

export const PartnersCardContainer = styled.div`
  margin: 40px 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  ${deviceSmallerThan.tabletM} {
    justify-content: center;
  }
`;

export const PublicPageTitle = styled(Title)`
  && {
    color: white;
    font-size: 50px;
    letter-spacing: 2px;
    font-weight: 500;
    margin: 0;

    ${deviceSmallerThan.tabletS} {
      font-size: 32px;
    }
  }
`;

export const StyledPartnerCard = styled(Card)`
  width: 240px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 15px;
`;

export const PartnerCardTagsContainer = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  margin: 10px 0;
`;
