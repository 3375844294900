export const homepageBrands = [
  {
    src: '/homepage-brands/cds.png',
    alt: 'cds',
  },
  {
    src: '/homepage-brands/grip.png',
    alt: 'grip',
  },
  {
    src: '/homepage-brands/multilearning.png',
    alt: 'multilearning',
  },
  {
    src: '/homepage-brands/orchestrate.png',
    alt: 'orchestrate',
  },
  {
    src: '/homepage-brands/session-board.png',
    alt: 'session-board',
  },
  {
    src: '/homepage-brands/mys.svg',
    alt: 'map your show',
  },
  {
    src: '/homepage-brands/hubilo-1.png',
    alt: 'hubilo',
  },
  // for the ticker to run continuously and appear "infinite", we need to duplicate the items in the list
  {
    src: '/homepage-brands/cds.png',
    alt: 'cds',
  },
  {
    src: '/homepage-brands/grip.png',
    alt: 'grip',
  },
  {
    src: '/homepage-brands/multilearning.png',
    alt: 'multilearning',
  },
  {
    src: '/homepage-brands/orchestrate.png',
    alt: 'orchestrate',
  },
  {
    src: '/homepage-brands/session-board.png',
    alt: 'session-board',
  },
  {
    src: '/homepage-brands/mys.svg',
    alt: 'map your show',
  },
  {
    src: '/homepage-brands/hubilo-1.png',
    alt: 'hubilo',
  },
];

export const homepageCardItems = [
  {
    title: 'Mobile Applications',
    src: '/homepage-cards/card-image-1.jpg',
  },
  {
    title: 'Analytics Vizualization',
    src: '/homepage-cards/card-image-2.jpg',
  },
  {
    title: 'Registration',
    src: '/homepage-cards/card-image-3.jpg',
  },
  {
    title: 'Event Chatbots',
    src: '/homepage-cards/card-image-4.jpg',
  },
  {
    title: 'Exhibitor Floor Planning Software',
    src: '/homepage-cards/card-image-5.jpg',
  },
  {
    title: 'Virtual Event Platforms',
    src: '/homepage-cards/card-image-6.jpg',
  },
  {
    title: 'Event Content Management SaaS',
    src: '/homepage-cards/card-image-7.jpg',
  },
  {
    title: 'Polling Session Engagement',
    src: '/homepage-cards/card-image-8.jpg',
  },
];

export const homepageServiceItems = [
  {
    icon: '/homepage-services/service-fire.png',
    title: 'Service',
    description: 'Leverage our services, experience, and partners to simplify integrations.',
  },
  {
    icon: '/homepage-services/service-clock.png',
    title: 'Competitive Advantage',
    description:
      'Save time and increase productivity while improving data accuracy and reliability.',
  },
  {
    icon: '/homepage-services/service-repeat.png',
    title: 'Versatility',
    description: 'Connect new or existing providers to Freeman systems.',
  },
];
