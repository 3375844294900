import { ScopeUserApiServiceHooksType, ScopeUserApiServiceType } from '@/api/scopeUser/types';
import {
  allScopeUsers,
  scopeUserById,
  useAllScopeUsers,
  useScopeUserById,
  useSearchScopeUsers,
} from '@/api/scopeUser/crud/get-scope-user.api';
import { createScopeUser, useCreateScopeUser } from '@/api/scopeUser/crud/create-scope-user.api';
import { deleteScopeUser, useDeleteScopeUser } from '@/api/scopeUser/crud/delete-scope-user.api';

export const ScopeUserApiServiceHooks: ScopeUserApiServiceHooksType = {
  useAllScopeUsers,
  useScopeUserById,
  useSearchScopeUsers,
  useCreateScopeUser,
  useDeleteScopeUser,
};

export const ScopeUserApiService: ScopeUserApiServiceType = {
  allScopeUsers,
  scopeUserById,
  createScopeUser,
  deleteScopeUser,
};
