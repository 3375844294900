import { PUBLIC_PAGE_ELEMENT_DARK_BLUE } from '@/styles/theme';
import { Row } from 'antd';
import styled from 'styled-components';

export const FooterContainer = styled.div`
  background-color: ${PUBLIC_PAGE_ELEMENT_DARK_BLUE};
  min-height: 360px;
  display: flex;
  align-items: center;

  .ant-typography {
    color: white;
    font-size: 16px;
    font-weight: 200;
    margin: 20px 0 0 0;
  }
`;

export const FooterContent = styled(Row)`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0 50px;
  width: 100%;
`;

export const LinkContainer = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  margin: 20px 0 0 0;

  a {
    text-decoration: underline;
    font-size: 16px;
    color: white;
    font-weight: 200;
  }
`;

export const FooterCopyRight = styled.div`
  color: white;
  height: 48px;
  font-weight: 200;
  margin: 20px 0 0 0;
`;
