import { Auth0ContextInterface, useAuth0 } from '@auth0/auth0-react';
import { ReactNode, createContext, useContext, useMemo } from 'react';

import { User } from '@/api/users/types';
import { UserApiServiceHooks } from '@/api/users';
import { useUsersPermissions } from '@/hooks/useUsersPermissions';

const { useSearchUsers } = UserApiServiceHooks;

type Auth0User = Auth0ContextInterface['user'];

type TUsersPermissions = ReturnType<typeof useUsersPermissions>;

type CurrentUserContextType = {
  isAuthenticated: boolean;
  isLoading: boolean;
  hasAnyLevelPermissions: boolean;
  auth0User: Auth0User;
  error?: Error;
  user: User;
  loginWithRedirect: Auth0ContextInterface['loginWithRedirect'];
  logout: () => void;
} & TUsersPermissions;

const CurrentUserContext = createContext<CurrentUserContextType | undefined>(undefined);

export const useCurrentUser = () => {
  const context = useContext(CurrentUserContext);
  if (context === undefined) {
    throw new Error('useCurrentUser must be used within a CurrentUserProvider');
  }
  return context;
};

export const CurrentUserProvider = ({ children }: { children: ReactNode }) => {
  const {
    error,
    isAuthenticated,
    user: auth0User,
    loginWithRedirect,
    logout: logoutAuth0,
    isLoading: isLoadingAuth0,
  } = useAuth0();
  const { data: fuzionUsers, isLoading: isLoadingFuzionUser } = useSearchUsers(
    {
      auth0UserIdentifier: auth0User?.sub?.toLowerCase(),
    },
    { enabled: !!auth0User?.sub }
  );

  const {
    systemLevelPermissions,
    organizationLevelPermissions,
    eventLevelPermissions,
    scopeIdToScopeUsersMap,
    scopeIdToOrganizationScopeUsersArrayMap,
    scopeIdToEventScopeUsersArrayMap,
    user, // this is Fuzion User Record
    integration,
    isLoading: isLoadingUsersPermissions,
    hasAnyLevelPermissions,
    allUsedScopesNameMap,
    topLevelScopes,
    organizationLevelScopes,
    eventLevelScopes,
    isSuccess,
  } = useUsersPermissions(fuzionUsers?.collection[0]);

  const currentUserContextValue = useMemo(() => {
    const logout = () => {
      logoutAuth0({ returnTo: window.location.origin });
    };

    return {
      error,
      isAuthenticated,
      isLoading: isLoadingAuth0 || isLoadingFuzionUser || isLoadingUsersPermissions,
      hasAnyLevelPermissions,
      systemLevelPermissions,
      organizationLevelPermissions,
      eventLevelPermissions,
      scopeIdToScopeUsersMap,
      allUsedScopesNameMap,
      scopeIdToOrganizationScopeUsersArrayMap,
      scopeIdToEventScopeUsersArrayMap,
      topLevelScopes,
      organizationLevelScopes,
      eventLevelScopes,
      auth0User,
      isSuccess,
      user: user || ({} as User),
      integration,
      loginWithRedirect,
      logout,
    };
  }, [
    error,
    eventLevelPermissions,
    integration,
    isAuthenticated,
    isLoadingAuth0,
    isLoadingUsersPermissions,
    isLoadingFuzionUser,
    hasAnyLevelPermissions,
    organizationLevelPermissions,
    scopeIdToEventScopeUsersArrayMap,
    scopeIdToOrganizationScopeUsersArrayMap,
    scopeIdToScopeUsersMap,
    allUsedScopesNameMap,
    systemLevelPermissions,
    eventLevelScopes,
    user,
    auth0User,
    topLevelScopes,
    organizationLevelScopes,
    isSuccess,
    loginWithRedirect,
    logoutAuth0,
  ]);

  return (
    <CurrentUserContext.Provider value={currentUserContextValue}>
      {children}
    </CurrentUserContext.Provider>
  );
};
