import {
  EventScopeUserApiServiceHooksType,
  EventScopeUserApiServiceType,
} from '@/api/eventScopeUser/types';
import {
  allEventScopeUsers,
  eventScopeUserById,
  useAllEventScopeUsers,
  useEventScopeUserById,
  useSearchEventScopeUsers,
} from '@/api/eventScopeUser/crud/get-event-scope-user.api';
import {
  createEventScopeUser,
  useCreateEventScopeUser,
} from '@/api/eventScopeUser/crud/create-event-scope-user.api';
import {
  deleteEventScopeUser,
  useDeleteEventScopeUser,
} from '@/api/eventScopeUser/crud/delete-event-scope-user.api';

export const EventScopeUserApiServiceHooks: EventScopeUserApiServiceHooksType = {
  useAllEventScopeUsers,
  useEventScopeUserById,
  useSearchEventScopeUsers,
  useCreateEventScopeUser,
  useDeleteEventScopeUser,
};

export const EventScopeUserApiService: EventScopeUserApiServiceType = {
  allEventScopeUsers,
  eventScopeUserById,
  createEventScopeUser,
  deleteEventScopeUser,
};
