import { ReactElement, useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { ContentForbidden } from '@/components/ContentForbidden';
import { LoadingContentSkeleton } from '@/components/Layout/LoadingContentSkeleton';
import { hasScopeToAllowAccessToRoute } from '@/components/Permissions/permissionHelpers';
import { useCurrentUser } from '@/providers/CurrentUserProvider';

type RoutePermissionCheckerProps = {
  children: ReactElement;
};

export const RoutePermissionChecker = ({ children }: RoutePermissionCheckerProps) => {
  const location = useLocation();
  const params = useParams();
  const { isLoading, allUsedScopesNameMap } = useCurrentUser();

  // Here we will use user permissions to determine if the user is allowed to access a particular route
  // This will cover the case when a user followed on a link (or typed url manually) to a page that they are not allowed to access
  const hasScopeToAllowAccess = useMemo(
    () =>
      hasScopeToAllowAccessToRoute({
        isSingleEntityRoute: !!Object.values(params)[0],
        pathname: location.pathname,
        allUsedScopesNameMap,
      }),
    [location.pathname, params, allUsedScopesNameMap]
  );

  if (isLoading) {
    return <LoadingContentSkeleton />;
  }

  if (hasScopeToAllowAccess) {
    return children;
  }

  return (
    <ContentForbidden subTitle="Your account has insufficient permissions to access this content" />
  );
};
