import {
  OrganizationScopeUser,
  OrganizationScopeUserWithMeta,
} from '@/api/organizationScopeUser/types';

import { ApiDataResponseAnatomy } from '@/api/api.types';
import { ExtractFnReturnType } from '@/lib/react-query';
import { axios } from '@/lib/axios';
import { filterParamsToQueryParams } from '@/api/helpers';
import { useQuery } from 'react-query';

export type FilterOrganizationScopeUserQueryParams = Partial<
  {
    [property in keyof OrganizationScopeUser]: string;
  } & { page: number }
>;

export const allOrganizationScopeUsers = async (
  searchParams: FilterOrganizationScopeUserQueryParams = {}
) => {
  const params = filterParamsToQueryParams(searchParams);

  const response = await axios.get<
    OrganizationScopeUserWithMeta,
    ApiDataResponseAnatomy<OrganizationScopeUser>
  >(`/organization-scope-user/v2`, {
    params,
  });

  const { collection, _meta } = response;
  return { collection, meta: _meta };
};

type QueryGetOrganizationScopeUsersFnType = typeof allOrganizationScopeUsers;

export const organizationScopeUserById = async (id: string) =>
  axios.get<OrganizationScopeUser, OrganizationScopeUser>(`/organization-scope-user/v2/${id}`);

type QueryGetOrganizationScopeUserFnType = typeof organizationScopeUserById;

export const useAllOrganizationScopeUsers = (config = {}) => {
  return useQuery<ExtractFnReturnType<QueryGetOrganizationScopeUsersFnType>>({
    queryKey: ['organization-scope-user'],
    queryFn: () => allOrganizationScopeUsers(),
    ...config,
  });
};

export const useSearchOrganizationScopeUsers = (
  filterParams: FilterOrganizationScopeUserQueryParams,
  config = {}
) => {
  return useQuery<ExtractFnReturnType<QueryGetOrganizationScopeUsersFnType>>({
    queryKey: ['organization-scope-user', filterParams],
    queryFn: () => allOrganizationScopeUsers(filterParams),
    ...config,
  });
};

export const useOrganizationScopeUserById = (id: string, config = {}) => {
  return useQuery<ExtractFnReturnType<QueryGetOrganizationScopeUserFnType>>({
    queryKey: ['organization-scope-user', id],
    queryFn: () => organizationScopeUserById(id),
    ...config,
  });
};
