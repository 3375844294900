import {
  FooterContent,
  FooterCopyRight,
  FooterLogo,
  FreemanBannerContainer,
  LinkContainer,
} from '@/components/Footer/Footer.style';

import { Link } from 'react-router-dom';

export const PublicPageFooter = () => {
  return (
    <div>
      <FreemanBannerContainer>
        <img src="/images/Spotlight-Banner.jpg" alt="Spotlight Banner" />
      </FreemanBannerContainer>
      <FooterContent>
        <LinkContainer>
          <Link to="/help">Help</Link>
          <Link to="/technical-support">Technical Support</Link>
        </LinkContainer>
        <FooterLogo>
          <img src="/images/freeman-logo.png" alt="Freeman Logo" />
        </FooterLogo>
      </FooterContent>
      <FooterCopyRight>
        <span>2023 Freeman. All rights reserved.</span>
      </FooterCopyRight>
    </div>
  );
};
