import { Menu as AntMenu, MenuItemProps } from 'antd';
import { BRAND_BLUE, DARK_GRAY, LIGHT_GRAY, MENU_ITEM_GRAY, WHITE } from '@/styles/theme';
import styled, { css } from 'styled-components';

import { TypographyBase } from '@/styles/typography.style';

interface IMenuItemProps extends MenuItemProps {
  selected: boolean;
}

const BaseMenuStyles = css`
  background: ${LIGHT_GRAY};
  li {
    color: ${DARK_GRAY};
  }
`;

export const StyledMenuItem = styled(AntMenu.Item)<IMenuItemProps>`
  ${BaseMenuStyles}
  width: 80%;
  background-color: ${LIGHT_GRAY};

  .ant-menu-title-content {
    ${TypographyBase}

    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: ${MENU_ITEM_GRAY};
  }

  &.ant-menu-item.ant-menu-item-selected::after {
    border-right: none;
  }

  ${(props) => {
    return props.selected
      ? css`
          background-color: ${WHITE};
          border-radius: 10px;

          &.ant-menu-item.ant-menu-item-selected {
            background-color: ${WHITE};
            border-radius: 10px;

            span {
              color: ${BRAND_BLUE};
            }
          }

          &.ant-menu-item.ant-menu-item-selected::after {
            border-right: none;
          }
        `
      : css`
          &.ant-menu-item.ant-menu-item-selected {
            background-color: ${LIGHT_GRAY};
          }
        `;
  }}
`;
