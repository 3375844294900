import styled from 'styled-components';

export const ServiceItem = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 20px;
  justify-content: space-between;
  align-items: flex-start;
  width: 240px;

  img {
    height: 50px;
    width: 50px;
  }
  .ant-card-meta-title {
    font-size: 20px;
  }
  .ant-card-meta-description {
    font-size: 16px;
  }
`;
