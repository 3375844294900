import { BLACK, DARK_GRAY, RED } from '@/styles/theme';
import { DatePicker, Form, Input, InputNumber, Select } from 'antd';
import ReactQuill from 'react-quill';

import styled, { css } from 'styled-components';

import { TypographyBase } from '@/styles/typography.style';

const { RangePicker } = DatePicker;

export const StyledForm: any = styled(Form)``;

export const StyledFormItem: any = styled(Form.Item)`
  .ant-form-item-required {
    ${TypographyBase}
    font-size: 10px;
    line-height: 14px;
    color: ${DARK_GRAY};
    padding: 0 0 0 10px;
  }

  .ant-form-item-label {
    padding: 0;
  }
`;

export const StyledSearchInput: any = styled(Input)`
  min-width: 100px;
  max-width: 300px;
  height: 40px;
  border-radius: 20px;
  border: solid 1px #e2e8f0;
  padding: 4px 12px;

  &.ant-input-affix-wrapper {
    min-width: 100px;
    border: solid 1px #e2e8f0;
    padding: 4px 12px;
    border-radius: 20px;
  }
`;

export const StyledInput: any = styled(Input)`
  min-width: 100px;
  height: 35px;
  border-radius: 15px;
  border: solid 1px #e2e8f0;
  padding: 4px 7px 4px 15px;

  &.ant-input-affix-wrapper {
    min-width: 100px;
    border: solid 1px #e2e8f0;
    padding: 4px 7px 4px 15px;
    border-radius: 20px;
  }
  ${(props: any) =>
    props.size === 'large' &&
    css`
      color: red;
      &.ant-input-affix-wrapper {
        height: 75px;
        width: 600px;
      }
      .ant-input-lg {
        font-size: 24px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.3;
        letter-spacing: normal;
        text-align: left;
        color: ${BLACK};
      }
    `}
`;

export const StyledTextArea: any = styled(Input.TextArea)`
  border-radius: 15px;

  &.ant-input {
    border-radius: 15px;
  }
`;

export const StyledRichTextArea: any = styled(ReactQuill)`
  border-radius: 15px;

  .ql-toolbar.ql-snow {
    border-radius: 15px 15px 0 0;
    background: #fff;
    border-color: #e2e8f0;
  }
  .ql-container.ql-snow {
    border-radius: 0 0 15px 15px;
    background: #fff;
    border-color: #e2e8f0;
    min-height: 200px;
  }
  .ql-snow .ql-tooltip {
    position: unset;
  }
`;

export const StyledSelect = styled(Select)`
  min-width: 100px;
  max-width: 100%;

  .ant-select-selector {
    padding: 0 15px !important;
    border-radius: 15px !important;
    border: solid 1px #e2e8f0 !important;
  }
  .ant-select-dropdown {
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }
  .ant-select-item-option-content {
    overflow: auto;
    white-space: normal;
  }
`;

export const StyledDateRangePicker = styled(RangePicker)`
  border-radius: 15px;
  width: 100%;

  &.ant-picker {
    border-radius: 15px;
  }

  .ant-picker-cell .ant-picker-cell-inner {
    border-radius: 50%;
    min-width: 26px;
    height: 26px;
    line-height: 26px;
  }
  .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border-radius: 50%;
  }
  &&&& .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner {
    border-radius: 50%;
  }
  &&&& .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    border-radius: 50%;
  }

  .ant-picker-panel-container {
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }
`;

export const StyledDatePicker = styled(DatePicker)`
  border-radius: 15px;
  width: 100%;

  &.ant-picker {
    border-radius: 15px;
  }

  .ant-picker-cell .ant-picker-cell-inner {
    border-radius: 50%;
    min-width: 26px;
    height: 26px;
    line-height: 26px;
  }
  .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border-radius: 50%;
  }
  &&&& .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner {
    border-radius: 50%;
  }
  &&&& .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    border-radius: 50%;
  }

  .ant-picker-panel-container {
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }
`;

export const StyledInputNumber = styled(InputNumber)`
  border-radius: 15px;

  &.ant-input-number {
    border-radius: 15px;
    width: 100px;
  }

  .ant-input-number-handler-wrap {
    margin-right: 3px;
    border-bottom-right-radius: 15px;
    border-top-right-radius: 15px;
  }
`;

export const RequiredFieldLabelSpan = styled.span`
  color: ${RED};
  font-size: 12px;
`;
