import { CreateIntegrationPayload, Integration } from '@/api/integrations/types';
import { MutationConfig, queryClient } from '@/lib/react-query';

import { addNotification } from '@/lib/notifications';
import { axios } from '@/lib/axios';
import { useMutation } from 'react-query';

export const createIntegration = async (data: CreateIntegrationPayload): Promise<Integration> => {
  return axios.post<any, Integration>('/integrations/v2', data);
};

export type UseCreateIntegrationOptions = {
  config?: MutationConfig<typeof createIntegration>;
  data?: CreateIntegrationPayload;
};

export const useCreateIntegration = ({ config }: UseCreateIntegrationOptions = {}) => {
  return useMutation({
    onMutate: async () => {
      await queryClient.cancelQueries(['integrations']);
    },
    onError: (error) => {
      addNotification({
        type: 'error',
        message: error.message,
      });
    },
    onSuccess: (newlyCreatedIntegration) => {
      queryClient.invalidateQueries(['integrations']);
      queryClient.setQueryData(
        ['integration', newlyCreatedIntegration.id],
        newlyCreatedIntegration
      );
      const previousIntegrations = queryClient.getQueryData<Integration[]>(['integrations']);
      if (Array.isArray(previousIntegrations)) {
        queryClient.setQueryData(
          ['integrations'],
          [...previousIntegrations, newlyCreatedIntegration]
        );
      } else {
        queryClient.setQueryData(['integrations'], [newlyCreatedIntegration]);
      }
      addNotification({
        type: 'success',
        message: 'New integration created successfully!',
      });
    },
    ...config,
    mutationFn: createIntegration,
  });
};
