import { StyledExternalLink, StyledLink } from '@/components/Link/ButtonlikeLink.style';

type ButtonlikeLinkProps = {
  type?: 'primary' | 'secondary' | 'danger' | 'public-pages-light' | 'public-pages-dark';
  label?: string;
  to?: string;
};

export const ButtonlikeLink = ({ type = 'primary', label = '', to = '' }: ButtonlikeLinkProps) => {
  return (
    <StyledLink type={type} to={to}>
      {label}
    </StyledLink>
  );
};

export const ButtonlikeExternalLink = ({
  type = 'primary',
  label = '',
  to = '',
}: ButtonlikeLinkProps) => {
  return (
    <StyledExternalLink type={type} href={to} target="_blank" rel="noopener noreferrer">
      {label}
    </StyledExternalLink>
  );
};
