import { ScopeApiServiceHooksType, ScopeApiServiceType } from '@/api/scopes/types';
import {
  allScopes,
  scopeById,
  useAllScopes,
  useScopeById,
  useSearchScopes,
} from '@/api/scopes/crud/get-scope.api';

export const ScopeApiServiceHooks: ScopeApiServiceHooksType = {
  useAllScopes,
  useScopeById,
  useSearchScopes,
};

export const ScopeApiService: ScopeApiServiceType = {
  allScopes,
  scopeById,
};
