import { Scope, ScopeWithMeta } from '@/api/scopes/types';

import { ApiDataResponseAnatomy } from '@/api/api.types';
import { ExtractFnReturnType } from '@/lib/react-query';
import { axios } from '@/lib/axios';
import { filterParamsToQueryParams } from '@/api/helpers';
import { useQuery } from 'react-query';

export type FilterScopesQueryParams = Partial<{
  [property in keyof Scope]: string | string[];
}>;

export const allScopes = async (searchParams: FilterScopesQueryParams = {}) => {
  const params = filterParamsToQueryParams(searchParams);

  const response = await axios.get<ScopeWithMeta, ApiDataResponseAnatomy<Scope>>(`/scopes/v2`, {
    params,
  });

  const { collection, _meta } = response;
  return { collection, meta: _meta };
};

type QueryGetScopesFnType = typeof allScopes;

export const scopeById = async (id: string) => axios.get<Scope, Scope>(`/scopes/v2/${id}`);

type QueryGetScopeFnType = typeof scopeById;

export const useAllScopes = (config = {}) => {
  return useQuery<ExtractFnReturnType<QueryGetScopesFnType>>({
    queryKey: ['scopes'],
    queryFn: () => allScopes(),
    ...config,
  });
};

export const useSearchScopes = (filterParams: FilterScopesQueryParams, config = {}) => {
  return useQuery<ExtractFnReturnType<QueryGetScopesFnType>>({
    queryKey: ['scopes', filterParams],
    queryFn: () => allScopes(filterParams),
    ...config,
  });
};

export const useScopeById = (id: string, config = {}) => {
  return useQuery<ExtractFnReturnType<QueryGetScopeFnType>>({
    queryKey: ['scopes', id],
    queryFn: () => scopeById(id),
    ...config,
  });
};
