import { CreateEventScopeUserPayload, EventScopeUser } from '@/api/eventScopeUser/types';
import { MutationConfig, queryClient } from '@/lib/react-query';

import { addNotification } from '@/lib/notifications';
import { axios } from '@/lib/axios';
import { useMutation } from 'react-query';

export const createEventScopeUser = async (
  data: CreateEventScopeUserPayload
): Promise<EventScopeUser> => {
  return axios.post<any, EventScopeUser>('/event-scope-user/v2', data);
};

export type UseCreateEventScopeUserOptions = {
  config?: MutationConfig<typeof createEventScopeUser>;
  data?: CreateEventScopeUserPayload;
};

export const useCreateEventScopeUser = ({ config }: UseCreateEventScopeUserOptions = {}) => {
  return useMutation({
    onMutate: async (newEventScopeUser) => {
      await queryClient.cancelQueries(['event-scope-user']);
      const previousEventScopeUser = queryClient.getQueryData<EventScopeUser[]>([
        'event-scope-user',
      ]);
      queryClient.setQueryData(
        ['event-scope-user'],
        [...(previousEventScopeUser || []), newEventScopeUser]
      );
      return { previousEventScopeUser };
    },
    onError: (error, __, context: any) => {
      if (context?.previousEventScopeUser) {
        queryClient.setQueryData(['event-scope-user'], context.previousEventScopeUser);
      }
      addNotification({
        type: 'error',
        message: error.message,
      });
    },
    onSuccess: async (data) => {
      queryClient.invalidateQueries(['event-scope-user']);

      return data;
    },
    ...config,
    mutationFn: createEventScopeUser,
  });
};
