import {
  HeaderNavLeft,
  HeaderNavRight,
  HeaderNavigationContainer,
} from '@/components/Navigation/HeaderNavigation/HeaderNavigation.style';

import { Header } from '@/components/Layout/MainLayout.style';
import { StyledSkeletonInput } from '@/components/Layout/LoadingLayout.style';

export const LoadingHeaderNavigation = () => {
  return (
    <Header>
      <HeaderNavLeft>
        <StyledSkeletonInput active size="small" />
      </HeaderNavLeft>
      <HeaderNavRight>
        <HeaderNavigationContainer>
          <StyledSkeletonInput active size="small" />
        </HeaderNavigationContainer>
      </HeaderNavRight>
    </Header>
  );
};
