import isEmpty from 'lodash/isEmpty';

type TGenericObject = Record<string, string>;

const formatIntegration = (integration: TGenericObject) => {
  if (isEmpty(integration) || !integration) {
    return;
  }
  return formatLabelAndValue(integration);
};

const formatLabelAndValue = (permission: TGenericObject) => {
  const { label, value } = permission;
  return { id: value, label };
};

const processPermissionsObject = (permissionsInput: Record<string, TGenericObject[]>) =>
  Object.fromEntries(
    Object.entries<TGenericObject[]>(permissionsInput).map(([entityIdNameKey, permissions]) => {
      const updatedPermissions = permissions.map(formatLabelAndValue);
      const [entityId] = entityIdNameKey.split('#');
      return [entityId, updatedPermissions];
    })
  );

export const formatPermissionsPayload = (formData: Record<string, any>) => {
  const {
    name, // exists for application only
    logoUrl, // exists for application only
    description, // exists for application only
    fullName, // exists for user only
    email, // exists for user only
    integration,
    systemLevelPermissions = [],
    permissionsSomeOrganizationsAllEvents = {},
    permissionsSomeOrganizationsSomeEvents = {},
  } = formData;

  return {
    name, // exists for application only
    logoUrl, // exists for application only
    description, // exists for application only
    fullName, // exists for user only
    email, // exists for user only
    integration: formatIntegration(integration),
    systemLevelPermissions: systemLevelPermissions.map(formatLabelAndValue),
    permissionsSomeOrganizationsAllEvents: processPermissionsObject(
      permissionsSomeOrganizationsAllEvents
    ),
    permissionsSomeOrganizationsSomeEvents: Object.fromEntries(
      Object.entries<Record<string, TGenericObject[]>>(permissionsSomeOrganizationsSomeEvents).map(
        ([orgIdNameKey, eventPermissions]) => {
          const updatedEventPermissions = processPermissionsObject(eventPermissions);
          const [organizationId] = orgIdNameKey.split('#');
          return [organizationId, updatedEventPermissions];
        }
      )
    ),
  };
};
