import { PublicHeroSection, PublicHeroText } from '@/features/documentation/Documentation.style';
import {
  PublicPageContainer,
  PublicPageHeroRow,
  PublicPageTitle,
} from '@/features/publicPages/PublicPage.style';

import { Col } from 'antd';
import { PublicPageFooter } from '@/components/Footer/PublicPageFooter';

export const Help = () => {
  return (
    <>
      <PublicPageContainer>
        <PublicPageHeroRow>
          <Col span={24}>
            <PublicHeroSection>
              <PublicHeroText>
                <PublicPageTitle>Help</PublicPageTitle>
              </PublicHeroText>
            </PublicHeroSection>
          </Col>
        </PublicPageHeroRow>
      </PublicPageContainer>
      <PublicPageFooter />
    </>
  );
};
