import { HEADER_HEIGHT, HEADER_HEIGHT_MOBILE } from '@/components/Layout/PublicLayout.style';
import { TechSupportFormWrapperProps } from '@/features/technicalSupport/TechnicalSupport';

import { deviceSmallerThan } from '@/styles/mediaqueries';
import styled, { css } from 'styled-components';

// banner height + footer links + copywrite section
export const FOOTER_SECTION_HEIGHT = 360 + 130 + 48;
export const PUBLIC_PAGE_CONTENT_MIN_HEIGHT = `calc(100vh - ${HEADER_HEIGHT}px - ${FOOTER_SECTION_HEIGHT}px)`;
export const PUBLIC_PAGE_CONTENT_MIN_HEIGHT_MOBILE = `calc(100vh - ${HEADER_HEIGHT_MOBILE}px - ${FOOTER_SECTION_HEIGHT}px)`;

export const PublicPageContainer = styled.div`
  min-height: ${PUBLIC_PAGE_CONTENT_MIN_HEIGHT};
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;

  ${deviceSmallerThan.tabletS} {
    min-height: ${PUBLIC_PAGE_CONTENT_MIN_HEIGHT_MOBILE};
  }
`;

export const TechSupportFormWrapper = styled.div<TechSupportFormWrapperProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  > form {
    width: 80%;
    margin: 80px auto 60px auto;
  }
  ${(props) =>
    props.isPortalPage === true &&
    css`
      align-items: flex-start;
      > form {
        width: 80%;
        margin: 0 40px 0 60px;
      }
    `}
`;

export const GeneralErrorAlert = styled.div`
  margin: 10px 0 25px 0;
  padding: 1rem 1rem;
  border-radius: 0.25rem;
  color: #dc395f;
  background-color: rgba(255, 0, 0, 0.1);
  border: 1px solid #dc395f;
`;

export const SuccesAlert = styled.div`
  margin: 10px 0;
  color: #008000;
  background-color: rgba(0, 128, 0, 0.1);
  border: 1px solid #008000;
  padding: 1rem 1rem;
  border-radius: 0.25rem;
`;
