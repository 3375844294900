import { useEffect, useState } from 'react';
import { List } from 'antd';
import { ContentCard } from '@/features/updates/ContentCard';
import { Contenttopic, tagData } from '@/api/contenttopics/types';
import _ from 'lodash';
import { TagFilters } from '@/features/updates/TagFilters';
import moment from 'moment';
import {
  ContentWrapper,
  MonthButton,
  MonthFilterWrapper,
  MonthHeader,
} from '@/features/updates/UpdatePage.style';

type topicDataTagData = {
  topicData: Contenttopic[];
  tagData: tagData[];
  isTopicDataLoading?: boolean;
  isTagDataLoaded?: boolean;
};
type UpdatesContentProps = {
  data: topicDataTagData;
};

export const UpdatesContent = ({ data }: UpdatesContentProps) => {
  const { topicData, tagData, isTopicDataLoading } = data;
  const [filteredTopics, setFilteredTopics] = useState<Contenttopic[]>([]);
  const [monthFilterList, setMonthFilterList] = useState<string[]>([]);
  const [activeTagFilters, setActiveTagFilters] = useState<string[]>([]);
  const [monthFilter, setMonthFilter] = useState<string>('');

  useEffect(() => {
    if (topicData.length > 0 && monthFilterList.length < 1) {
      const sortedDates = topicData
        ?.map((topic) => {
          return moment(topic.publishedAt);
        })
        ?.sort((a, b) => b.diff(a))
        ?.map((date) => {
          return date.format('MMMM YYYY');
        });
      setMonthFilterList(_.uniq(sortedDates));
    }
  }, [topicData, monthFilter, monthFilterList.length]);

  useEffect(() => {
    if (!monthFilter && monthFilterList.length > 0) {
      setMonthFilter(monthFilterList[0]);
    }

    if (monthFilter && topicData.length > 0) {
      const topicsByMonth = topicData.filter((topic) => {
        return moment(topic.publishedAt).format('MMMM YYYY') === monthFilter;
      });

      if (activeTagFilters.length > 0) {
        setFilteredTopics(
          topicsByMonth?.filter((topic) => {
            return topic?.tags?.some((tagId) => activeTagFilters.includes(tagId));
          })
        );
      } else {
        setFilteredTopics(topicsByMonth);
      }
    }
  }, [monthFilterList, monthFilter, topicData, activeTagFilters]);

  const handleMonthFilter = (month: string) => {
    setMonthFilter(month);
  };

  const handleTagFilterChange = (tagIdFilters: string[]) => {
    setActiveTagFilters(tagIdFilters);
  };

  return (
    <ContentWrapper>
      <MonthFilterWrapper>
        <MonthHeader>Jump to Month</MonthHeader>
        <List
          dataSource={monthFilterList}
          loading={isTopicDataLoading}
          split={false}
          itemLayout={'vertical'}
          renderItem={(item) => (
            <MonthButton isActive={item === monthFilter} onClick={() => handleMonthFilter(item)}>
              {item}
            </MonthButton>
          )}
        />
      </MonthFilterWrapper>
      <List
        dataSource={filteredTopics}
        loading={isTopicDataLoading}
        renderItem={(item) => (
          <List.Item>
            <ContentCard data={item} />
          </List.Item>
        )}
      />
      <TagFilters data={tagData} handleTagChange={handleTagFilterChange} />
    </ContentWrapper>
  );
};
