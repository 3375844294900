import { CreateContenttopicPayload, Contenttopic } from '../types';
import { MutationConfig, queryClient } from '@/lib/react-query';

import { addNotification } from '@/lib/notifications';
import { axios } from '@/lib/axios';
import { useMutation } from 'react-query';

export const createContenttopic = async (data: CreateContenttopicPayload): Promise<Contenttopic> =>
  axios.post<any, Contenttopic>('/contenttopics/v2', data);

export type UseCreateContenttopicOptions = {
  config?: MutationConfig<typeof createContenttopic>;
  data?: CreateContenttopicPayload;
};

export const useCreateContenttopic = ({ config }: UseCreateContenttopicOptions = {}) => {
  return useMutation({
    onMutate: async (newContenttopic) => {
      await queryClient.cancelQueries(['contenttopics']);
      const previousContenttopics = queryClient.getQueryData<Contenttopic[]>(['contenttopics']);
      queryClient.setQueryData(
        ['contenttopics'],
        [...(previousContenttopics || []), newContenttopic]
      );
      return { previousContenttopics };
    },
    onError: (error, __, context: any) => {
      if (context?.previousContenttopics) {
        queryClient.setQueryData(['contenttopics'], context.previousContenttopics);
      }
      addNotification({
        type: 'error',
        message: error.message,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['contenttopics']);
      addNotification({
        type: 'success',
        message: 'New contenttopic created successfully!',
      });
    },
    ...config,
    mutationFn: createContenttopic,
  });
};
