import {
  ContenttagContenttopic,
  ContenttagContenttopicWithMeta,
} from '@/api/contenttagContenttopic/types';

import { ApiDataResponseAnatomy } from '@/api/api.types';
import { ExtractFnReturnType } from '@/lib/react-query';
import { axios } from '@/lib/axios';
import { filterParamsToQueryParams } from '@/api/helpers';
import { useInfiniteQuery, useQuery } from 'react-query';

export type FilterContenttagContenttopicQueryParams = Partial<
  {
    [property in keyof ContenttagContenttopic]: string;
  } & { page: number }
>;

export const allContenttagContenttopics = async (
  searchParams: FilterContenttagContenttopicQueryParams = {}
) => {
  const params = filterParamsToQueryParams(searchParams);

  const response = await axios.get<
    ContenttagContenttopicWithMeta,
    ApiDataResponseAnatomy<ContenttagContenttopic>
  >(`/contenttag-contenttopic/v2`, {
    params,
  });

  const { collection, _meta } = response;
  return { collection, meta: _meta };
};

type QueryGetContenttagContenttopicsFnType = typeof allContenttagContenttopics;

export const contenttagContenttopicById = async (id: string) =>
  axios.get<ContenttagContenttopic, ContenttagContenttopic>(`/contenttag-contenttopic/v2/${id}`);

type QueryGetContenttagContenttopicFnType = typeof contenttagContenttopicById;

export const useAllContenttagContenttopics = (config = {}) => {
  return useQuery<ExtractFnReturnType<QueryGetContenttagContenttopicsFnType>>({
    queryKey: ['contenttag-contenttopic'],
    queryFn: () => allContenttagContenttopics(),
    ...config,
  });
};

export const useSearchInfiniteContenttagContenttopics = (
  filterParams: FilterContenttagContenttopicQueryParams = {},
  config = {}
) => {
  return useInfiniteQuery<ExtractFnReturnType<QueryGetContenttagContenttopicsFnType>>({
    queryKey: ['contenttag-contenttopic', filterParams],
    queryFn: ({ pageParam = 1 }) =>
      allContenttagContenttopics({ ...filterParams, page: pageParam }),
    getPreviousPageParam: (firstPageData, allPagesData) => {
      const lastPageData = allPagesData.at(-1);
      if (!lastPageData?.meta) {
        // this should never happen unless the API returns an error
        return undefined;
      }
      const previousPage = lastPageData.meta.currentPageNumber - 1;

      if (previousPage <= 1) {
        // no previous page
        return undefined;
      }
      return previousPage;
    },
    getNextPageParam: (lastPageData) => {
      if (!lastPageData?.meta) {
        // this should never happen unless the API returns an error
        return undefined;
      }
      const nextPage = lastPageData.meta.currentPageNumber + 1;
      if (nextPage > lastPageData.meta.totalNumberOfPages) {
        // no more pages
        return undefined;
      }
      return nextPage;
    },
    ...config,
  });
};

export const useSearchContenttagContenttopics = (
  filterParams: FilterContenttagContenttopicQueryParams,
  config = {}
) => {
  return useQuery<ExtractFnReturnType<QueryGetContenttagContenttopicsFnType>>({
    queryKey: ['contenttag-contenttopic', filterParams],
    queryFn: () => allContenttagContenttopics(filterParams),
    ...config,
  });
};

export const useContenttagContenttopicById = (id: string, config = {}) => {
  return useQuery<ExtractFnReturnType<QueryGetContenttagContenttopicFnType>>({
    queryKey: ['contenttag-contenttopic', id],
    queryFn: () => contenttagContenttopicById(id),
    ...config,
  });
};
