import { FunctionComponent } from 'react';
import { RequiredFieldLabelSpan } from '@/components/Form/Form.style';

interface RequiredFieldLabelProps {
  label: string;
}

export const RequiredFieldLabel: FunctionComponent<RequiredFieldLabelProps> = ({ label }) => {
  return (
    <span>
      {label}
      <RequiredFieldLabelSpan>*</RequiredFieldLabelSpan>
    </span>
  );
};
