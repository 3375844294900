import { Nullable } from '@/api/typeHelpers';
import { User } from '@/api/users/types';

type TComposeUpdateduserDataProps = {
  addedPermissions: any;
  removedPermissions: any;
  user: User;
};

type TUpdatedUserData =
  | {
      hasChangesInUserData: false;
      updatedUserData: Record<string, never>;
    }
  | {
      hasChangesInUserData: true;
      updatedUserData: {
        fullName: string;
        email: string;
        integrationId?: Nullable<string>;
      };
    };

export const composeUpdatedUserData = ({
  addedPermissions,
  removedPermissions,
  user,
}: TComposeUpdateduserDataProps): TUpdatedUserData => {
  const hasChangesInUserData =
    Object.hasOwn(addedPermissions, 'fullName') ||
    Object.hasOwn(addedPermissions, 'email') ||
    Object.hasOwn(addedPermissions, 'integration') ||
    Object.hasOwn(removedPermissions, 'integration');

  if (!hasChangesInUserData) {
    return { hasChangesInUserData: false, updatedUserData: {} };
  }

  const updatedUserData: {
    fullName: string;
    email: string;
    integrationId?: Nullable<string>;
  } = {
    fullName: addedPermissions.fullName || user.fullName,
    email: addedPermissions.email || user.email,
  };

  if (
    Object.hasOwn(removedPermissions, 'integration') &&
    !Object.hasOwn(addedPermissions, 'integration')
  ) {
    // if existing integration has been removed but new integration has not been added
    updatedUserData.integrationId = null;
  }
  if (Object.hasOwn(addedPermissions, 'integration')) {
    updatedUserData.integrationId = addedPermissions.integration.id;
  }

  return {
    hasChangesInUserData,
    updatedUserData,
  };
};
