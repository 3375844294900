import {
  ContenttopicApiServiceHooksType,
  ContenttopicApiServiceType,
} from '@/api/contenttopics/types';
import {
  allContenttopics,
  contenttopicById,
  useAllContenttopics,
  useContenttopicById,
  useSearchContenttopics,
  useSearchInfiniteContenttopics,
} from '@/api/contenttopics/crud/get-contenttopic.api';
import {
  createContenttopic,
  useCreateContenttopic,
} from '@/api/contenttopics/crud/create-contenttopic.api';
import {
  deleteContenttopic,
  useDeleteContenttopic,
} from '@/api/contenttopics/crud/delete-contenttopic.api';
import {
  updateContenttopic,
  useUpdateContenttopic,
} from '@/api/contenttopics/crud/update-contenttopic.api';

export const ContenttopicApiServiceHooks: ContenttopicApiServiceHooksType = {
  useAllContenttopics,
  useSearchInfiniteContenttopics,
  useContenttopicById,
  useSearchContenttopics,
  useCreateContenttopic,
  useUpdateContenttopic,
  useDeleteContenttopic,
};

export const ContenttopicApiService: ContenttopicApiServiceType = {
  allContenttopics,
  contenttopicById,
  createContenttopic,
  updateContenttopic,
  deleteContenttopic,
};
