import { Card } from 'antd';
import styled from 'styled-components';

const profileCardWidth = 400;

export const ProfileCard = styled(Card)`
  max-width: ${profileCardWidth}px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justifycontent: space-between;

  .ant-card-body {
    flex: 1;
  }
`;

export const ProfileContainer = styled.div`
  min-height: 80vh;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
`;

export const InfoSection = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
  justify-content: flex-start;
`;
