import { IconNames } from '@/components/Icon/IconsDict';
import { SidebarIcon } from '@/components/Icon/SidebarIcon';
import { StyledSubMenu } from '@/components/Navigation/SideNavigation/SubMenu.style';
import { SubMenuProps } from 'antd';

export interface ISubMenuProps extends SubMenuProps {
  selected: boolean;
  iconName?: IconNames | null;
  id: string;
}

export const SubMenu = ({
  title,
  selected,
  iconName,
  onTitleClick,
  children,
  id,
  ...rest
}: ISubMenuProps) => {
  return (
    <StyledSubMenu
      id={id}
      title={title}
      selected={selected}
      icon={iconName && <SidebarIcon name={iconName} selected={selected} />}
      onTitleClick={onTitleClick}
      {...rest}
    >
      {children}
    </StyledSubMenu>
  );
};
