import './index.css';

import * as React from 'react';

import { Auth0Context, Auth0Provider } from '@auth0/auth0-react';

import App from './App';
import { LicenseManager } from '@ag-grid-enterprise/core';
import { createRoot } from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_LICENSE_KEY as string);

const deferred = (() => {
  const props = {} as {
    resolve: (value: any) => void;
    promise: Promise<any>;
  };
  props.promise = new Promise((resolve) => (props.resolve = resolve));
  return props;
})();

export const getAccessToken = async () => {
  const getToken = await deferred.promise;
  return getToken();
};

const container = document.getElementById('root');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript

root.render(
  <React.StrictMode>
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN as string}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID as string}
      redirectUri={process.env.REACT_APP_AUTH0_REDIRECT_URI}
      audience={process.env.REACT_APP_AUTH0_AUDIENCE}
    >
      <Auth0Context.Consumer>
        {({ getAccessTokenSilently }: any) => {
          deferred.resolve(getAccessTokenSilently);
          return <App />;
        }}
      </Auth0Context.Consumer>
    </Auth0Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
