import { BLACK, BRAND_BLUE, RED } from '@/styles/theme';

import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
body {
  font-family: "NotoSans";
}

/**
 * until https://github.com/facebook/create-react-app/issues/11771
 * gets fixed, let's hide the problem and not address it
 * Workaround from here https://github.com/facebook/create-react-app/issues/11771#issuecomment-1017125835
 */
body > iframe[style*="2147483647"]:not([id="webpack-dev-server-client-overlay"]) {
    display: none;
}

.generic-notification-custom-class.ant-notification-notice {  
  border-left: 15px solid white;
  border-radius: 15px;
  width: 275px;
  padding: 20px;

  &.success {
    border-left-color: ${BRAND_BLUE};
  }
  
  &.info {
    border-left-color: green;
  }
  &.warning {
    border-left-color: yellow;
  }
  &.error {
    border-left-color: ${RED};
  }

  .ant-notification-notice-close {
    top: 10px;
    right: 10px;
  }

  .ant-notification-notice-message {
    font-size: 14px;
  }
  .ant-notification-notice-message {
      font-family: "NotoSans";
      font-size: 12px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.4;
      letter-spacing: normal;
      text-align: left;
      color: ${BLACK};
      text-align: center;

  }
}

.ant-spin-dot-item {
  height: 25px;
  width: 25px;
}

.ant-spin-dot {
  height: 2.5em;
  width: 2.5em;
}
`;
