import * as React from 'react';

import { StyledForm, StyledFormItem } from '@/components/Form/Form.style';

import { FormProps as AntFormProps } from 'antd';

interface FormWithChildrenProps extends AntFormProps {
  children?: any;
}

type FormProps = React.FunctionComponent<FormWithChildrenProps> & { useForm?: any; Item?: any };

export const Form: FormProps = ({ children, ...rest }) => {
  return <StyledForm {...rest}>{children}</StyledForm>;
};

Form.Item = StyledFormItem;
Form.useForm = StyledForm.useForm;
