import { Col, Row, Typography } from 'antd';
import {
  FooterContainer,
  FooterContent,
  FooterCopyRight,
  LinkContainer,
} from '@/components/Footer/Footerv2.style';

import { Link } from 'react-router-dom';
import { LogoImg } from '@/components/Navigation/SideNavigation/LogoImg';

const { Text } = Typography;

export const PublicPageFooterv2 = () => {
  return (
    <FooterContainer>
      <FooterContent>
        <Col xs={{ span: 20 }} xl={{ span: 10 }}>
          <Row>
            <LogoImg type="freeman-light" />
          </Row>
          <Row>
            <Text>
              For our customers, a proprietary integration engine, for connecting Freeman’s digital
              offerings with a growing ecosystem of partners.
            </Text>
          </Row>
        </Col>
        <Col>
          <Row>
            <LinkContainer>
              <Link to="/technical-support">Technical Support</Link>
            </LinkContainer>
          </Row>
          <Row>
            <FooterCopyRight>
              <span>© 2023 Freeman. All rights reserved.</span>
            </FooterCopyRight>
          </Row>
        </Col>
      </FooterContent>
    </FooterContainer>
  );
};
