import { BRAND_BLUE, DARK_GRAY, GRAY, LIGHT_GRAY, MENU_ITEM_GRAY, WHITE } from '@/styles/theme';
import styled, { css } from 'styled-components';

import { Menu as AntMenu } from 'antd';
import { ISubMenuProps } from '@/components/Navigation/SideNavigation/SubMenu';
import { TypographyBase } from '@/styles/typography.style';

const BaseMenuStyles = css`
  background: ${LIGHT_GRAY};
  li {
    color: ${DARK_GRAY};
  }
`;

export const StyledSubMenu = styled(AntMenu.SubMenu)<ISubMenuProps>`
  ${BaseMenuStyles}
  width: 100%;

  min-width: 260px;
  background-color: ${LIGHT_GRAY};
  border-radius: 10px;
  margin: 0 0 5px 0;

  .ant-menu-title-content {
    ${TypographyBase}

    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: ${MENU_ITEM_GRAY};
  }

  .ant-menu-submenu-title {
    background-color: ${LIGHT_GRAY};
    border-radius: 10px;

    .ant-menu-title-content {
      color: ${(props) => (props.selected ? BRAND_BLUE : MENU_ITEM_GRAY)};
    }
  }

  .ant-menu-sub {
    width: 90%;
    margin-left: 25px;
  }

  .ant-menu-item {
    background-color: ${LIGHT_GRAY};
    border-radius: 0;
    border-bottom: 1px solid ${GRAY};
    width: auto;
    padding: 0 0 0 11px !important;
    margin-left: 50px;
    .ant-menu-title-content {
      font-size: 12px;
    }
  }

  ${(props: any) => {
    return (
      props.selected &&
      css`
        .ant-menu-submenu-title {
          background-color: ${WHITE};
        }
      `
    );
  }}
`;
