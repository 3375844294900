import { Content, Layout } from './MainLayout.style';
import { Outlet, useNavigate } from 'react-router-dom';
import React, { useEffect } from 'react';

import { ContentForbidden } from '@/components/ContentForbidden';
import { Footer } from '@/components/Footer/Footer';
import { HeaderNavigation } from '@/components/Navigation/HeaderNavigation/HeaderNavigation';
import { LoadingContentSkeleton } from '@/components/Layout/LoadingContentSkeleton';
import { RoutePermissionChecker } from '@/components/Permissions/RoutePermissionChecker';
import { SideNavigation } from '@/components/Navigation/SideNavigation';
import { useCurrentUser } from '@/providers/CurrentUserProvider';

export const MainLayout = () => {
  const { isLoading, error, hasAnyLevelPermissions } = useCurrentUser();
  const navigate = useNavigate();
  const hasEmptyPermissions = !isLoading && !error && !hasAnyLevelPermissions;

  useEffect(() => {
    window.onbeforeunload = function () {
      sessionStorage.setItem('pathname-before-reload', window.location.pathname);
    };

    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  useEffect(() => {
    const pathnameBeforeReload = sessionStorage.getItem('pathname-before-reload');
    if (pathnameBeforeReload) {
      sessionStorage.removeItem('pathname-before-reload');
      navigate(pathnameBeforeReload);
    }
  }, [navigate]);

  return (
    <Layout>
      <Layout>
        <SideNavigation />
        <Layout>
          <HeaderNavigation />
          {hasEmptyPermissions ? (
            <ContentForbidden
              removeActions
              subTitle="Sorry, your account has not been fully set up yet and has no permissions associated with it. Please contact your administrator."
            />
          ) : (
            <RoutePermissionChecker>
              <Content>
                <React.Suspense fallback={<LoadingContentSkeleton />}>
                  <Outlet />
                </React.Suspense>
              </Content>
            </RoutePermissionChecker>
          )}
        </Layout>
      </Layout>
      <Footer />
    </Layout>
  );
};
