import * as React from 'react';

import {
  HeaderNavLeft,
  HeaderNavRight,
  HeaderNavigationContainer,
  HoverableSpan,
  StyledDropdown,
} from '@/components/Navigation/HeaderNavigation/HeaderNavigation.style';

import { Header } from '@/components/Layout/MainLayout.style';
import { Menu } from 'antd';
import { useCurrentUser } from '@/providers/CurrentUserProvider';
import useGetPageByPathname from '@/hooks/useGetPageSettings';
import { useNavigate } from 'react-router-dom';

export type HeaderNavigationProps = {
  displayMessage?: boolean;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const HeaderNavigation: React.FunctionComponent<HeaderNavigationProps> = () => {
  const pageSettings = useGetPageByPathname();
  const navigate = useNavigate();
  const { user, logout } = useCurrentUser();

  const getDropdownContainer = (triggerNode: HTMLElement) => triggerNode;

  const handleMenuClick = ({ key }: { key: string }) => {
    if (key === 'logout') {
      return logout();
    }

    navigate(`/${key}`);
  };

  const menuItems = [
    {
      label: 'Profile',
      key: 'auth/profile',
    },
    {
      label: 'Logout',
      key: 'logout',
    },
  ];

  return (
    <Header>
      <HeaderNavLeft>
        <h1>{pageSettings.pageTitle}</h1>
      </HeaderNavLeft>

      <HeaderNavRight>
        <HeaderNavigationContainer>
          <div>
            <StyledDropdown
              overlay={<Menu onClick={handleMenuClick} items={menuItems} />}
              getPopupContainer={getDropdownContainer}
            >
              <HoverableSpan>{user?.email}</HoverableSpan>
            </StyledDropdown>
          </div>
        </HeaderNavigationContainer>
      </HeaderNavRight>
    </Header>
  );
};
