import type { BaseOptionType, DefaultOptionType } from 'rc-select/lib/Select';

import { FC } from 'react';
import { Icon } from '@/components/Icon';
import { SelectProps } from 'antd';
import { StyledSelect } from './Form.style';

type SelectOptionType = {
  value: string | number;
  children?: Omit<DefaultOptionType, 'children'>[];
  label: string;
};

const { Option } = StyledSelect;

export const SelectInput = <
  TValue = string | number,
  TOption extends BaseOptionType = SelectOptionType,
>(
  props: SelectProps<TValue, TOption> & {
    sortBy?: 'label' | 'value';
    showSearch?: boolean;
    skipSort?: boolean;
  }
) => {
  const {
    placeholder,
    options,
    sortBy = 'label',
    showSearch = true,
    skipSort = false,
    ...rest
  } = props;

  const getPopupContainer = (el: HTMLElement) => el;

  return (
    <StyledSelect<FC<SelectProps<TValue, TOption>>>
      showSearch={showSearch}
      getPopupContainer={getPopupContainer}
      suffixIcon={<Icon name="dropdown" />}
      placeholder={placeholder}
      optionFilterProp="label"
      filterOption={(input: string, option?: TOption) => {
        if (!option) {
          return false;
        }
        return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
      }}
      filterSort={(optionA: TOption, optionB: TOption) => {
        if (skipSort) {
          return 0;
        }
        const itemA = optionA[sortBy];
        const itemB = optionB[sortBy];
        if (typeof itemA === 'string' && typeof itemB === 'string') {
          return itemA.toLowerCase().localeCompare(itemB.toLowerCase());
        }
        if (typeof itemA === 'number' && typeof itemB === 'number') {
          if (itemA < itemB) {
            return -1;
          }
          if (itemA > itemB) {
            return 1;
          }
          return 0;
        }
        return -1;
      }}
      {...rest}
    >
      {options?.map((option) => (
        <Option key={`${option.value}`} value={option.value} label={option.label}>
          {option.label}
        </Option>
      ))}
    </StyledSelect>
  );
};
