import { MutationConfig, queryClient } from '@/lib/react-query';

import { Contentcategory } from '@/api/contentcategories/types';
import { addNotification } from '@/lib/notifications';
import { axios } from '@/lib/axios';
import { useMutation } from 'react-query';

export const deleteContentcategory = async (id: string): Promise<Contentcategory> => {
  return axios.delete<any, Contentcategory>(`/contentcategories/v2/${id}`);
};

export type UseDeleteContentcategoryOptions = {
  config?: MutationConfig<typeof deleteContentcategory>;
};

export const useDeleteContentcategory = ({ config }: UseDeleteContentcategoryOptions = {}) => {
  return useMutation({
    onMutate: async (deletedContentcategoryId: string) => {
      await queryClient.cancelQueries(['contentcategories']);
      const previousContentcategories = queryClient.getQueryData<Contentcategory[]>([
        'contentcategories',
      ]);
      queryClient.setQueryData(
        ['contentcategories'],
        previousContentcategories?.filter(
          (contentcategory) => contentcategory.id !== deletedContentcategoryId
        )
      );

      return { previousContentcategories };
    },
    onError: (error, __, context: any) => {
      if (context?.previousContentcategories) {
        queryClient.setQueryData(['contentcategories'], context.previousContentcategories);
      }
      addNotification({
        type: 'error',
        message: error.message,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['contentcategories']);
      addNotification({
        type: 'success',
        message: `You have successfully deleted the contentcategory!`,
      });
    },
    ...config,
    mutationFn: deleteContentcategory,
  });
};
