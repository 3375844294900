import { PublicHeroSection, PublicHeroText } from '@/features/documentation/Documentation.style';
import {
  PublicPageContainerv2,
  PublicPageHeroRow,
  PublicPageTitle,
} from '@/features/publicPages/PublicPage.style';

import { Col } from 'antd';
import { PublicPageFooterv2 } from '@/components/Footer/PublicPageFooterv2';
import { TechSupportFormWrapper } from '@/features/technicalSupport/TechnicalSupport.style';
import TechnicalSupportForm from '@/features/technicalSupport/TechnicalSupportForm';

export const PublicTechnicalSupport = () => {
  return (
    <>
      <PublicPageContainerv2>
        <PublicPageHeroRow>
          <Col span={24}>
            <PublicHeroSection>
              <PublicHeroText>
                <PublicPageTitle>Tech Support</PublicPageTitle>
              </PublicHeroText>
            </PublicHeroSection>
          </Col>
        </PublicPageHeroRow>
        <TechSupportFormWrapper>
          <TechnicalSupportForm />
        </TechSupportFormWrapper>
      </PublicPageContainerv2>
      <PublicPageFooterv2 />
    </>
  );
};
