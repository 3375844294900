import { MutationConfig, queryClient } from '@/lib/react-query';

import { Integration } from '@/api/integrations/types';
import { addNotification } from '@/lib/notifications';
import { axios } from '@/lib/axios';
import { useMutation } from 'react-query';

export const deleteIntegration = async (id: string): Promise<Integration> => {
  return axios.delete<any, Integration>(`/integrations/v2/${id}`);
};

export type UseDeleteIntegrationOptions = {
  config?: MutationConfig<typeof deleteIntegration>;
};

export const useDeleteIntegration = ({ config }: UseDeleteIntegrationOptions = {}) => {
  return useMutation({
    onMutate: async (deletedIntegrationId: string) => {
      await queryClient.cancelQueries(['integrations']);
      const previousIntegrations = queryClient.getQueryData<Integration[]>(['integrations']);

      if (Array.isArray(previousIntegrations)) {
        queryClient.setQueryData(
          ['integrations'],
          previousIntegrations.filter((integration) => integration.id !== deletedIntegrationId)
        );
      } else {
        queryClient.setQueryData(['integrations'], []);
      }

      return { previousIntegrations };
    },
    onError: (error, __, context: any) => {
      if (context?.previousIntegrations) {
        queryClient.setQueryData(['integrations'], context.previousIntegrations);
      }
      addNotification({
        type: 'error',
        message: error.message,
      });
    },
    onSuccess: (deletedIntegration) => {
      queryClient.invalidateQueries(['integrations']);
      queryClient.removeQueries(['integration', deletedIntegration.id]);
      addNotification({
        type: 'success',
        message: `You have successfully deleted the integration!`,
      });
    },
    ...config,
    mutationFn: deleteIntegration,
  });
};
