import {
  TGranularLevelAction,
  granularLevelActionsToPermissionsMap,
  granularLevelActionsToDescriptionMap,
} from '@/features/auth0/helpers/granularLevelActions';
import {
  TSystemLevelAction,
  systemLevelActionsPermissionsMap,
  systemLevelActionsToDescriptionMap,
} from '@/features/auth0/helpers/systemLevelActions';
import { formatPermissionsPayload } from '@/features/auth0/helpers/formatPermissionsPayload';
import {
  composeOrganizationLevelPermissions,
  composeOrganizationLevelScopes,
  composeEventLevelPermissions,
  composeEventLevelScopes,
  TEventLevelPermissions,
  TNestedPermissionItem,
  TOrganizationLevelScopes,
  TEventLevelScopes,
} from '@/features/auth0/helpers/composePermissionsHelpers';
import { TLabelWithIdValue } from '@/features/auth0/clientFlowSteps/types';
import { difference, removeEmpty } from '@/features/auth0/helpers/composeDifference';
import {
  composeUpdatedAppData,
  composeUniqueSystemLevelScopeIds,
} from '@/features/auth0/helpers/updateClient';
import { composeUpdatedUserData } from '@/features/auth0/helpers/updateUser';
import { composeApplicationScopeMapObjects } from '@/features/auth0/helpers/applicationPermissionsProviderHelpers';
import { composeUserScopeMapObjects } from '@/features/auth0/helpers/userPermissionsProviderHelpers';
import {
  freemanInternalUserEmailDomains,
  isInternalFreemanEmail,
  composeInternalUserAuth0Id,
} from '@/features/auth0/helpers/userFlowHelpers';
import { sortByLabel } from '@/features/auth0/helpers/sort';
import { composeEntitiesMissingReadScope } from '@/features/auth0/helpers/evaluationHelpers';
import { Actions } from '@/components/Permissions/constants';
import {
  sortScopeNamesByCustomOrder,
  sortScopeItemsWithLabelByCustomOrder,
} from '@/features/auth0/helpers/scopesSort';

const allActionsToDescriptionMap = {
  ...granularLevelActionsToDescriptionMap,
  ...systemLevelActionsToDescriptionMap,
};

const allActionsForPermissionsMap = {
  ...systemLevelActionsPermissionsMap,
  ...granularLevelActionsToPermissionsMap,
} as Record<TScopeName, keyof typeof Actions>;

type TScopeName = TSystemLevelAction | TGranularLevelAction;
type TPermissionItem = TLabelWithIdValue<TScopeName>;

export {
  formatPermissionsPayload,
  composeOrganizationLevelPermissions,
  composeEventLevelPermissions,
  difference,
  removeEmpty,
  composeUpdatedAppData,
  composeUniqueSystemLevelScopeIds,
  composeApplicationScopeMapObjects,
  composeUserScopeMapObjects,
  isInternalFreemanEmail,
  composeInternalUserAuth0Id,
  composeUpdatedUserData,
  composeOrganizationLevelScopes,
  composeEventLevelScopes,
  sortByLabel,
  composeEntitiesMissingReadScope,
  sortScopeNamesByCustomOrder,
  sortScopeItemsWithLabelByCustomOrder,
  freemanInternalUserEmailDomains,
  allActionsToDescriptionMap,
  allActionsForPermissionsMap,
  type TSystemLevelAction,
  type TGranularLevelAction,
  type TEventLevelPermissions,
  type TNestedPermissionItem,
  type TScopeName,
  type TOrganizationLevelScopes,
  type TEventLevelScopes,
  type TPermissionItem,
};
