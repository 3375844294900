import { Card } from 'antd';
import { ServiceItem } from '@/features/publicPages/HomepageServiceCard.style';

const { Meta } = Card;

export const HomepageServiceCard = ({
  icon,
  title,
  description,
}: {
  icon: string;
  title: string;
  description: string;
}) => {
  return (
    <ServiceItem>
      <img alt={title} src={icon} />
      <Meta title={title} description={description} />
    </ServiceItem>
  );
};
