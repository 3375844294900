import * as React from 'react';

import { BRAND_BLUE, WHITE } from '@/styles/theme';
import { IconNames, IconsDict } from './IconsDict';

import { SidebarIconStyle } from './Icon.style';

type IconProps = {
  name: IconNames;
  fillColor?: string;
  selected?: boolean;
};

export const SidebarIcon: React.FC<IconProps> & React.RefAttributes<HTMLSpanElement> = ({
  name,
  fillColor,
  selected = false,
}) => {
  const selectedFillColor = (() => {
    if (fillColor) {
      return fillColor;
    }
    return selected ? WHITE : BRAND_BLUE;
  })();

  return (
    <SidebarIconStyle selected={selected}>{IconsDict[name](selectedFillColor)}</SidebarIconStyle>
  );
};
