import { MutationConfig, queryClient } from '@/lib/react-query';
import { Organization, UpdateOrganizationPayload } from '@/api/organizations/types';

import { addNotification } from '@/lib/notifications';
import { axios } from '@/lib/axios';
import { useMutation } from 'react-query';

export const updateOrganization = async ({
  data,
  id,
}: UpdateOrganizationPayload): Promise<Organization> => {
  const organization: Organization = await axios.patch(`/organizations/v2/${id}`, data);

  return organization;
};

export type UseUpdateOrganizationOptions = {
  config?: MutationConfig<typeof updateOrganization>;
  data?: UpdateOrganizationPayload;
};

export const useUpdateOrganization = ({ config }: UseUpdateOrganizationOptions = {}) => {
  return useMutation({
    onMutate: async (updatingOrganization: any) => {
      await queryClient.cancelQueries(['organization', updatingOrganization?.id]);
      const previousOrganization = queryClient.getQueryData<Organization>([
        'organization',
        updatingOrganization?.id,
      ]);
      queryClient.setQueryData(['organization', updatingOrganization?.id], {
        ...previousOrganization,
        ...updatingOrganization.data,
        id: updatingOrganization.id,
      });

      return { previousOrganization };
    },
    onError: (error, __, context: any) => {
      if (context?.previousOrganization) {
        queryClient.setQueryData(
          ['organization', context.previousOrganization.id],
          context.previousOrganization
        );
      }
      addNotification({
        type: 'error',
        message: error.message,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['organizations']);
      addNotification({
        type: 'success',
        message: 'You have successfully updated this organization!',
      });
    },
    ...config,
    mutationFn: updateOrganization,
  });
};
