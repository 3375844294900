import {
  BrandsContainer,
  CTAContainer,
  CTASection,
  CTATextRow,
  CardsContainer,
  HeroImageRow,
  HighlightedText,
  HomeHeroText,
  HomepageSubTitle,
  HomepageTitle,
  InfoSection,
  ServicesContainer,
  ServicesSection,
  TextRow,
  Ticker,
  TickerContent,
  TickerItemContainer,
} from '@/features/publicPages/Homepage.style';
import { Col, Row, Typography } from 'antd';
import {
  homepageBrands,
  homepageCardItems,
  homepageServiceItems,
} from '@/features/publicPages/homepageConfig';

import { ButtonlikeExternalLink } from '@/components/Link/ButtonlikeLink';
import { HomepageCard } from '@/features/publicPages/HomepageCard';
import { HomepageServiceCard } from '@/features/publicPages/HomepageServiceCard';
import { ProfileContainer } from '@/features/auth0/styles/auth0.style';
import { PublicPageFooterv2 } from '@/components/Footer/PublicPageFooterv2';

const { Title, Text } = Typography;

export const Home = () => {
  return (
    <>
      <ProfileContainer>
        <HeroImageRow align="middle">
          <Col span={12} offset={3}>
            <HomeHeroText>
              <HomepageTitle>Fuzion</HomepageTitle>
              <HomepageSubTitle level={3}>
                The integration ecosystem for event data & content
              </HomepageSubTitle>
            </HomeHeroText>
            <ButtonlikeExternalLink
              to="https://www.freeman.com/contact/"
              label="Contact Us"
              type="public-pages-light"
            />
          </Col>
        </HeroImageRow>
        <BrandsContainer>
          <TickerContent>
            <Ticker>
              {homepageBrands.map(({ src, alt }) => (
                <TickerItemContainer key={`${alt}-logo}`}>
                  <img src={src} alt={alt} />
                </TickerItemContainer>
              ))}
            </Ticker>
          </TickerContent>
        </BrandsContainer>
        <InfoSection justify="center">
          <Col span={22}>
            <Row justify="center">
              <Title level={2}>
                Share data across <br /> Freeman and more.
              </Title>
            </Row>
            <TextRow justify="center">
              <Text>
                Fuzion makes it easier to plan, manage, and engage by allowing you to <br />
                connect your event data to tools beyond just the Freeman suite.
              </Text>
            </TextRow>
            <CardsContainer>
              <Row justify="center" wrap gutter={[30, 30]}>
                {homepageCardItems.map(({ title, src }) => (
                  <Col key={title}>
                    <HomepageCard title={title} src={src} />
                  </Col>
                ))}
              </Row>
            </CardsContainer>
          </Col>
        </InfoSection>
        <ServicesSection justify="center" align="middle">
          <Col xs={{ span: 22, offset: 0 }} xl={{ span: 10, offset: 2 }} xxl={{ span: 9 }}>
            <Row justify="center">
              <Title level={2}>
                All the <HighlightedText>benefits</HighlightedText> that <br /> you are looking for!
              </Title>
            </Row>
            <TextRow justify="center">
              <Text>
                Seamlessly connecting Freeman digital products and <br />
                partners to create a cohesive, memorable experience for your audience.
              </Text>
            </TextRow>
          </Col>
          <Col xs={{ span: 22, offset: 0 }} xl={{ span: 10, offset: 0 }} xxl={{ span: 9 }}>
            <ServicesContainer>
              {homepageServiceItems.map(({ title, icon, description }) => (
                <HomepageServiceCard
                  key={title}
                  title={title}
                  icon={icon}
                  description={description}
                />
              ))}
            </ServicesContainer>
          </Col>
        </ServicesSection>
        <CTASection justify="center" align="middle">
          <Col>
            <CTAContainer>
              <Row justify="center">
                <Title level={2}>Let&apos;s get started!</Title>
              </Row>
              <CTATextRow justify="center">
                <Text>Reach out and request a consultation.</Text>
              </CTATextRow>
              <Row justify="center">
                <ButtonlikeExternalLink
                  to="https://www.freeman.com/contact/"
                  label="Contact Us"
                  type="public-pages-dark"
                />
              </Row>
            </CTAContainer>
          </Col>
        </CTASection>
      </ProfileContainer>
      <PublicPageFooterv2 />
    </>
  );
};
