import Axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

import { API_URL } from '@/config';
import { getAccessToken } from '@/index';
import jwt from 'jsonwebtoken';

async function authRequestInterceptor(config: AxiosRequestConfig) {
  let accessToken = null;
  try {
    accessToken = await getAccessToken();
  } catch (err) {
    const { REACT_APP_ALTERNATIVELY_AUTHENTICATED_JWT_SECRET, REACT_APP_AUTH0_AUDIENCE } =
      process.env;
    accessToken =
      REACT_APP_AUTH0_AUDIENCE && config.url
        ? jwt.sign(
            {
              method: config.method,
              isAlternativeAuthentication: true,
            },
            REACT_APP_ALTERNATIVELY_AUTHENTICATED_JWT_SECRET as string,
            {
              expiresIn: '1m',
              algorithm: 'HS256',
              audience: REACT_APP_AUTH0_AUDIENCE + config.url,
              issuer: 'Fuzion',
            }
          )
        : null;
  }

  if (accessToken) {
    config.headers.authorization = `Bearer ${accessToken}`;
  }
  config.headers.Accept = 'application/json';
  return config;
}

export const axios = Axios.create({
  baseURL: API_URL,
});

type AxiosApiResponseData = Pick<AxiosResponse, 'data'>;

axios.interceptors.request.use(authRequestInterceptor);
axios.interceptors.response.use(
  (response: AxiosResponse): AxiosApiResponseData => {
    const { data }: AxiosApiResponseData = response;

    return data;
  },
  (error) => {
    const message = error.response?.data?.message || error.message;

    return Promise.reject({ ...error, message });
  }
);
